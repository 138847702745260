import React, { FC, SyntheticEvent } from 'react';
import { StyleProps, Styles, m } from 'styles';
import { ContactType, isContactType } from 'core/api';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

interface Props extends StyleProps {
  label?: string;
  required?: boolean;
  error?: boolean;
  value?: string;
  helperText?: string;
  onChange?: (v: ContactType) => void;
}

export const FormContactsTypeField: FC<Props> = ({ style, label, required, error, value, helperText, onChange }) => {
  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value: newVal } = e.currentTarget;
    if (isContactType(newVal) && onChange) {
      onChange(newVal);
    }
  };
  return (
    <FormControl style={m(styles.container, style)} component="fieldset" required={required} error={error}>
      {!!label && <FormLabel>{label}</FormLabel>}
      <RadioGroup row value={value} onChange={handleChange}>
        <FormControlLabel value="EMAIL" control={<Radio color="primary" />} label="email" />
        <FormControlLabel value="MOBILE" control={<Radio color="primary" />} label="mobile" />
      </RadioGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const styles: Styles = {
  container: {},
};

export type FormContactsTypeFieldProps = Props;
export default FormContactsTypeField;
