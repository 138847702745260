import addDays from 'date-fns/addDays';
import { isNil } from 'lodash';
import format from 'date-fns/format';
import Dialog from '@material-ui/core/Dialog';
import React, { FC, MutableRefObject, useImperativeHandle, useRef, useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DialogContent } from '@material-ui/core';
import { Resource } from 'core/api';

export interface ScheduleDatePickerRef {
  show: (initialDate: Date, resource: Resource) => void;
  close: () => void;
}

interface InjectedProps {
  pickerRef: MutableRefObject<ScheduleDatePickerRef | undefined>;
  onPublishClick: (date: Date, resource: Resource) => void;
}

const useStyles = makeStyles(() => ({
  container: {
    padding: '30px 45px',
  },
  pickerContainer: {
    marginTop: 30,
  },
  title: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 19,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 300,
    marginBottom: 0,
  },
  meta: {
    fontSize: 14,
    fontWeight: 300,
    textAlign: 'center',
    marginBottom: 0,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
}));

const ScheduleDatePicker: FC<InjectedProps> = ({ pickerRef, onPublishClick }) => {
  const currentDate = useRef<Date>(new Date()).current;
  const classes = useStyles();
  const currentResource = useRef<Resource | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());
  const handleClose = () => {
    setOpen(false);
    setDate(new Date());
    currentResource.current = null;
  };
  const handlePublishClick = () => {
    if (isNil(currentResource.current)) return;

    onPublishClick(date, currentResource.current);
    handleClose();
  };

  useImperativeHandle(pickerRef, () => ({
    show: (initialDate: Date, resource: Resource) => {
      setOpen(true);
      setDate(initialDate);
      currentResource.current = resource;
    },
    close: handleClose,
  }));

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent className={classes.container}>
        <h3 className={classes.title}>Select Publish Date</h3>
        <p className={classes.subtitle}>All scheduled resources publish <br/> at 10pm PT on selected date</p>

        <div className={classes.pickerContainer}>
          <DatePicker
            ToolbarComponent={() => null}
            minDate={addDays(currentDate, 1)}
            autoOk
            variant="static"
            value={date}
            onChange={m => setDate(m?.toDate() || new Date())}
          />
        </div>

        <p className={classes.meta}>
          Publish on <b>{format(date, 'EEEE, MMMM dd')}</b>
        </p>

        <div className={classes.actions}>
          <Button size="large" style={{ marginRight: 12 }} onClick={handleClose} variant="outlined" disableElevation>
            Cancel
          </Button>

          <Button
            size="large"
            style={{ marginRight: 12 }}
            onClick={handlePublishClick}
            variant="contained"
            color="primary"
            disableElevation
          >
            Publish
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ScheduleDatePicker;
