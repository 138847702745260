import React, { FC } from 'react';
import { StyleProps } from 'styles';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { colors } from 'styles';
import { makeStyles } from '@material-ui/core/styles';
import { NavBreadcumbs } from 'components/Nav';
import AccountButton from 'components/AccountButton';

type Props = StyleProps & StylesOpt;

interface StylesOpt {
  path: string;
}

export const Header: FC<Props> = ({ path }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.toolbar} />

      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <NavBreadcumbs path={path} />
        </Toolbar>

        <AccountButton />
      </AppBar>
    </>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    paddingRight: 20,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: `2px solid ${colors.borderGrey}`,
    minHeight: 74,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

export type HeaderProps = Props;
export default Header;
