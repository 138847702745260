import { gql } from '@apollo/client';
import { fields } from 'core/api';

export const GET_NEED_TYPES = gql`
    query needTypes {
        needTypes {
            ${fields.needType}
        }
    }
`;
