import './index.css';

import { ApolloProvider } from '@apollo/client';
import MomentUtils from '@date-io/moment';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'components/Common';
import { api } from 'core/api';
import * as serviceWorker from 'core/serviceWorker';
import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Screens } from 'screens';
import { muiTheme } from 'styles';
import AuthProvider from './context/AuthProvider';

const ApolloCachePersistGate: FC<{ children: JSX.Element }> = ({ children }) => {
  const [cacheLoaded, setCacheLoaded] = useState<boolean>(false);

  useEffect(() => {
    setCacheLoaded(true);
  }, []);

  return cacheLoaded ? children : null;
};

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <ApolloProvider client={api}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <SnackbarProvider>
          <ApolloCachePersistGate>
            <Router>
              <AuthProvider>
                <Screens />
              </AuthProvider>
            </Router>
          </ApolloCachePersistGate>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
