import { Button, Paper } from '@material-ui/core';
import { Text, View } from 'components/Common';
import { DataTable } from 'components/Tables';
import { CareTeam, User, CareTeamRole, CareTeamMember, useGetEventsQuery, Event, Attachment } from 'core/api';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { m, StyleProps, Styles } from 'styles';
import { Log } from 'utils';
import { isNil } from 'lodash';
import recurringIcon from 'assets/refresh.png';

const log = Log('screen.DashboardCompaniesEdit.scenes.CompanyDetail');

interface Props extends StyleProps {
  publicTeam: CareTeam;
  onGoBack: () => void;
}

export const DashboardPublicTeamScene: FC<Props> = ({ style, publicTeam, onGoBack }) => {
  // Deps

  const location = useLocation();
  const history = useHistory();
  const { teamMembers, name, id } = publicTeam;

  const { data: events } = useGetEventsQuery({ publicTeamId: id });

  // Data

  const filterItemsWithSearch = (search: string, items: CareTeamMember[]) => {
    const searchMod = search.toLowerCase().trim();
    return items.filter(itm => {
      const { user } = itm;
      const nameMod = user?.firstName ? user?.firstName.toLowerCase().trim() : '';
      return nameMod.indexOf(searchMod) >= 0;
    });
  };

  const filterEventItemsWithSearch = (search: string, items: Event[]) => {
    const searchMod = search.toLowerCase().trim();
    return items.filter(itm => {
      const { title } = itm;
      const nameMod = title ? title.toLowerCase().trim() : '';
      return nameMod.indexOf(searchMod) >= 0;
    });
  };

  // Handlers

  const handleCancelPress = () => {
    log.debug('handle cancel press');
    onGoBack();
  };

  const handleCreatEventPress = () => {
    log.debug('handle create event press');
    history.push({
      pathname: `${location.pathname}/create-event`,
      state: {
        publicTeamId: id,
      },
    });
  };

  const handleEventItemPress = (item: Event) => {
    log.debug('handle event item press');
    history.push({
      pathname: `${location.pathname}/${item.id}`,
      state: {
        data: item,
        publicTeamId: id,
      },
    });
  };

  const handleImportPress = () => {
    log.debug('handle import events press');
    history.push({
      pathname: `${location.pathname}/import`,
      state: {
        publicTeamId: id,
      },
    });
  };

  const handleUserItemPress = (item: CareTeamMember) => {
    log.debug('handle user item press');
    history.push({
      pathname: `${location.pathname}/user/${item.id}`,
      state: {
        data: item,
        publicTeam,
      },
    });
  };

  // Render

  const renderUserName = (user: User | undefined): string => {
    if (!user) {
      return '';
    }
    return `${user.firstName} ${user.lastName}`;
  };

  const renderRole = (role: CareTeamRole[] | undefined): string => {
    if (!role) {
      return '';
    }
    return role[0]?.name;
  };

  const renderDate = (date: Date | undefined): string => {
    if (!date) {
      return '';
    }
    return moment(date).local().format('ddd, MMM DD YYYY, h:mmA');
  };

  const renderName = (val: string, item: Event) => {
    const isRecurring = !isNil(item.recurrence);

    if (isRecurring) {
      return (
        <div style={styles.titleContainer}>
          <img width={15} height={15} src={recurringIcon} />
          <span style={styles.title}>{val}</span>
        </div>
      );
    }

    return val;
  };

  const renderImage = (value: Attachment | null) => {
    if (isNil(value?.url)) return null;

    return <img style={{ width: 60, height: 45 }} src={value?.url} />;
  };

  return (
    <>
      <Paper style={m(styles.container, style)}>
        <View style={styles.actions} row={true} justifyContent="space-between">
          <Text size={21}>Public Team Detail</Text>
          <View row={true} justifyContent="flex-end">
            <Button style={styles.actionBtn} variant="contained" color="default" onClick={handleCancelPress}>
              Back
            </Button>
          </View>
        </View>
        <View style={styles.row}>
          Name
          <View>{name}</View>
        </View>
        <View style={styles.row}>
          Public Team ID
          <View>{`#${id}`}</View>
        </View>
        <View style={styles.row}>
          Number of Team Members
          <View>{teamMembers && teamMembers.length}</View>
        </View>
      </Paper>
      <Paper style={m(styles.list, style)}>
        <View>
          <Text size={21}>Activities</Text>
          <View row={true} justifyContent="flex-end">
            <Button style={styles.actionBtn} variant="contained" color="primary" onClick={handleImportPress}>
              Import
            </Button>
            <Button style={styles.actionBtn} variant="contained" color="primary" onClick={handleCreatEventPress}>
              Create
            </Button>
          </View>
        </View>
        <DataTable
          title="events"
          items={events?.data || []}
          bottomBorder="line"
          searchPlaceholder="Search event..."
          filterWithSearch={filterEventItemsWithSearch}
          columns={{
            attachment: {
              title: 'Image',
              valExtractor: renderImage,
            },
            id: {
              title: 'Event ID#',
              component: 'th',
              scope: 'row',
              valExtractor: val => `${val}`,
            },
            title: {
              title: 'Title',
              valExtractor: renderName,
            },
            description: {
              title: 'Details',
              valExtractor: val => (val ? `${val}` : `-`),
            },
            startDate: {
              title: 'Start Date',
              valExtractor: renderDate,
            },
            endDate: {
              title: 'End Date',
              valExtractor: renderDate,
            },
            location: {
              title: 'Location',
              valExtractor: val => (val ? `${val?.address}` : '-'),
            },
            visibility: {
              title: 'Visibility',
              valExtractor: val => `${val}`,
            },
          }}
          onItemPress={handleEventItemPress}
        />
      </Paper>
      <Paper style={m(styles.list, style)}>
        <View>
          <Text size={21}>Team Members</Text>
        </View>
        <DataTable
          title="careTeamMembers"
          items={teamMembers || []}
          bottomBorder="line"
          searchPlaceholder="Search name..."
          filterWithSearch={filterItemsWithSearch}
          columns={{
            id: {
              title: 'Team Member ID#',
              component: 'th',
              scope: 'row',
              valExtractor: val => `${val}`,
            },
            user: {
              title: 'Name',
              valExtractor: renderUserName,
            },
            careTeamRoles: {
              title: 'Care Team Role',
              valExtractor: renderRole,
            },
          }}
          onItemPress={handleUserItemPress}
        />
      </Paper>
    </>
  );
};

const styles: Styles = {
  container: {
    padding: 20,
  },
  list: {
    padding: 20,
    marginTop: 20,
  },
  row: {
    marginTop: 6,
    marginBottom: 10,
  },
  actions: {
    marginTop: 20,
  },
  actionBtn: {
    marginLeft: 6,
    width: 100,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'block',
    marginLeft: 5,
  },
};

export type DashboardPublicTeamSceneProps = Props;
export default DashboardPublicTeamScene;
