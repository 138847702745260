import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import placeholder from '../../../assets/placeholder.png';
import { Header } from 'components/Common';
import { GridRowModel, GridRowParams } from '@material-ui/data-grid';
import { StyleProps } from 'styles/types';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { GET_ADMIN_USER_FEED } from 'core/apollo/queries/users';
import { AdminUserFeedResponse } from 'core/api';
import { isNil } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'screens/consts';
import { Button } from '@material-ui/core';
import { useAuth } from '../../../hooks/useAuth';
import { isSuperAdmin } from 'utils/permissions';
import SectionTitle from 'components/SectionTitle';
import { useWindowResize } from '../../../hooks/useWindowResize';
import Tag from 'components/Tag';
import DataTable from 'components/Common/DataTable';
import { makeStyles } from '@material-ui/core/styles';
import MenuButton, { MenuButtonOption } from 'components/MenuButton';
import { SuperUserActionMenuType } from 'screens/Resources/List';
import Modal, { ConfirmationModalRef } from 'components/Modal';
import { RESEND_ADMIN_USER_INVITE } from 'core/apollo/mutation/users';

const useStyles = makeStyles(() => ({
  rowImage: {
    width: 60,
    height: 60,
    objectFit: 'cover',
    borderRadius: 4,
  },
}));

const columns = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'iconUrl',
    headerName: 'Image',
    // eslint-disable-next-line react/display-name
    renderCell: ({ value }) => {
      const classes = useStyles();

      return <img className={classes.rowImage} src={value || placeholder} />;
    },
  },
  { field: 'firstName', headerName: 'First Name', flex: 1 },
  { field: 'lastName', headerName: 'Last Name', flex: 1 },
  { field: 'email', headerName: 'Email', flex: 1 },
  {
    field: 'active',
    headerName: 'Active',
    flex: 1,
    // eslint-disable-next-line react/display-name
    renderCell: ({ value }) => {
      if (isNil(value)) return <Tag backgroundColor="gray" title="N/A" />;
      return value ? <Tag backgroundColor="green" title="Yes" /> : <Tag backgroundColor="orange" title="No" />;
    },
  },
];

const AdminListScreen: FC<StyleProps> = () => {
  const modalRef = useRef<ConfirmationModalRef>();
  const container = useRef<HTMLDivElement>(null);
  const header = useRef<HTMLDivElement>(null);
  const sectionHeader = useRef<HTMLDivElement>(null);
  const { height } = useWindowResize();
  const [headerHeight, setHeaderHeight] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();

  const { data, loading, error, networkStatus, refetch, fetchMore, variables } = useQuery<AdminUserFeedResponse>(
    GET_ADMIN_USER_FEED,
    {
      variables: {
        limit: 25,
        offset: 0,
      },
    },
  );

  const [resendAdminInvite] = useMutation(RESEND_ADMIN_USER_INVITE, {
    ignoreResults: true,
  });

  useLayoutEffect(() => {
    if (container.current && header.current && sectionHeader.current) {
      setHeaderHeight(
        container.current.clientHeight - header.current.clientHeight - sectionHeader.current?.clientHeight - 24,
      );
    }
  }, [height]);

  const getRowMenuOptions = (row: GridRowModel) => {
    if (isSuperAdmin(user))
      return [
        { type: SuperUserActionMenuType.EDIT, label: 'Edit' },
        ...(!row.active ? [{ type: SuperUserActionMenuType.RESEND_INVITE, label: 'Resend Invite' }] : []),
        { type: SuperUserActionMenuType.VIEW, label: 'View' },
      ];

    return [];
  };

  const handleSearchQueryChange = (searchQuery: string) => {
    refetch({
      searchQuery,
    });
  };

  const onItemPress = ({ row: { id } }: GridRowParams) => {
    if (isSuperAdmin(user)) return history.push(`${routes.admins.profile}/${id}`);
  };
  const handlePageChange = (offset: number) => {
    return fetchMore({
      variables: {
        ...variables,
        offset,
      },
    });
  };
  const handlePageSizeChange = (limit: number) => {
    refetch({
      offset: 0,
      limit,
    });
  };
  const handleRowActionClick = async (option: MenuButtonOption, row: GridRowModel) => {
    switch (option.type as SuperUserActionMenuType) {
      case SuperUserActionMenuType.EDIT:
        return history.push(`${routes.admins.edit}/${row?.id}`);
      case SuperUserActionMenuType.RESEND_INVITE: {
        await resendAdminInvite({
          variables: { id: row.id },
        });
        return modalRef.current?.show({
          type: 'notification',
          title: 'Invite Resent!',
          content: 'The email invitation has been resent.',
          confirmText: 'Close',
          onConfirm: () => null,
        });
      }
      case SuperUserActionMenuType.VIEW: {
        return history.push(`${routes.admins.profile}/${row?.id}`);
      }
    }
  };

  return (
    <div style={{ height: '100%' }} ref={container}>
      <div ref={header}>
        <Header path={location.pathname} />
        <SectionTitle title="Admin Page Users" />
      </div>

      <DataTable
        sectionHeaderRef={sectionHeader}
        columns={[
          ...columns,
          {
            field: 'actions',
            headerName: 'Actions',
            // eslint-disable-next-line react/display-name
            renderCell: ({ row }) => {
              return <MenuButton onClick={o => handleRowActionClick(o, row)} options={getRowMenuOptions(row)} />;
            },
          },
        ]}
        rows={data?.adminUserFeed?.data || []}
        rowCount={data?.adminUserFeed?.total || 0}
        onItemPress={onItemPress}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSearchQueryChange={handleSearchQueryChange}
        error={!!error}
        loading={loading || networkStatus === NetworkStatus.setVariables}
        height={headerHeight}
        dataGridProps={{
          rowHeight: 70,
        }}
      >
        {isSuperAdmin(user) && (
          <Button
            onClick={() => history.push(routes.admins.create)}
            variant="contained"
            color="primary"
            disableElevation
          >
            Create User
          </Button>
        )}
      </DataTable>
      <Modal modalRef={modalRef} />
    </div>
  );
};

export default AdminListScreen;
