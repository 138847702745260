import { omit, isEmpty } from 'lodash';
import { ResourceFormRecords } from 'components/Resources/ResourceForm';
import { MerchantStatus, ResourceStatus } from 'core/apollo/consts';
import {ResourceInput} from 'core/api';
import {ApolloClient} from '@apollo/client';
import {SELECT_ALL_ITEM} from 'components/Form/RHSelect';
import {GET_CONDITIONS} from 'core/apollo/queries/conditions';
import {GET_NEED_TYPES} from 'core/apollo/queries/needTypes';
import {GET_APPOINTMENT_TYPES} from 'core/apollo/queries/appointmentTypes';

export const normalizeFormValues = (records: ResourceFormRecords, client: ApolloClient<any>): ResourceInput => {
  const webAttachment = omit(records.webImage, '__typename');
  const appAttachment = omit(records.appImage, '__typename');

  const payload = transformAllOptions(records, client);

  return {
    ...omit(payload, [
      'appointmentTypes',
      'needTypes',
      'company',
      'conditions',
      'megaCategories',
      'subCategories',
      'topics',
      'merchantProgram',
      'metaTags',
      'appImage',
      'webImage',
    ]),
    appAttachment: isEmpty(appAttachment) ? null : appAttachment,
    webAttachment: isEmpty(webAttachment) ? null : webAttachment,
    appointmentTypeIds: payload?.appointmentTypes?.map(a => a.id),
    needTypeIds: payload?.needTypes?.map(n => n.id),
    companyId: payload?.company?.id || -1,
    conditionIds: payload?.conditions.map(c => c.id),
    megaCategoryIds: payload?.megaCategories.map(c => c.id),
    subCategoryIds: payload?.subCategories.map(c => c.id),
    topicIds: payload?.topics.map(t => t.id),
    merchantProgramId: payload?.merchantProgram?.id || '-1',
    merchantStatus: ((payload?.merchantStatus as unknown) as { [id: string]: MerchantStatus }).id,
    status: ((payload?.status as unknown) as { [id: string]: ResourceStatus }).id,
    metaTagIds: payload?.metaTags?.map(m => m.id),
  };
};

export const transformAllOptions = (payload: ResourceFormRecords, client: ApolloClient<any>) => {
  const allConditionsSelected = payload.conditions.some(c => c.id === SELECT_ALL_ITEM);
  const allTasksSelected = payload.needTypes.some(c => c.id === SELECT_ALL_ITEM);
  const allAppointmentsSelected = payload.appointmentTypes.some(c => c.id === SELECT_ALL_ITEM);

  const { careConditions: conditions } = client.readQuery({ query: GET_CONDITIONS });
  const { needTypes } = client.readQuery({ query: GET_NEED_TYPES });
  const { appointmentTypes } = client.readQuery({ query: GET_APPOINTMENT_TYPES });

  return ({
    ...payload,
    conditions: allConditionsSelected ? conditions : payload.conditions,
    needTypes: allTasksSelected ? needTypes : payload.needTypes,
    appointmentTypes: allAppointmentsSelected ? appointmentTypes : payload.appointmentTypes,
  })
};
