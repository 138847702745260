import { View } from 'components/Common';
import React, { FC } from 'react';
import { StyleProps, Styles } from 'styles';
import { CircularProgress } from '@material-ui/core';

type Props = StyleProps;

export const CentredProgressIndicator: FC<Props> = ({ style }) => (
  <View style={[styles.container, style]}>
    <CircularProgress />
  </View>
);

const styles: Styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,
  },
};

export type CentredProgressIndicatorProps = Props;
export default CentredProgressIndicator;
