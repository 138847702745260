import NotificationsIcon from '@material-ui/icons/Notifications';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';

export const AnnouncementIcon = NotificationsIcon;
export const CompaniesIcon = BusinessIcon;
export const ResourcesIcon = SettingsIcon;
export const LogoutIcon = PowerSettingsNewIcon;
export const UserIcon = PersonIcon;
export const UsersIcon = PeopleIcon;
