import { gql, useQuery, useMutation } from '@apollo/client';
import { errToStr } from 'utils';
import { EmployeeBenefit } from './types';
import { fields } from './fields';
import { Invitation, InvitationInput } from './types';
import { graphQlErrorsToStr } from './utils';

export const useGetTeamsQuery = () => {
  const { data, loading, error } = useQuery<{ careTeams: EmployeeBenefit[] }>(
    gql`
      query careTeams {
        careTeams {
          id
          name
          relation
        }
      }
    `,
    { fetchPolicy: 'cache-and-network' },
  );
  return { data: data ? data.careTeams : undefined, loading, error: error ? errToStr(error) : undefined };
};

export const useInviteContactMutation = () => {
  const [mutation] = useMutation<{ inviteContact: Invitation[] }, { input: InvitationInput }>(
    gql`mutation inviteContact($input: InvitationInput){
      inviteContact(input: $input) {
        ${fields.invitation}
      }
    }`,
  );
  return async (input: InvitationInput): Promise<Invitation[]> => {
    const { data, errors } = await mutation({
      variables: { input },
    });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    }
    if (data && data.inviteContact) {
      return data.inviteContact;
    } else {
      throw new Error('Response is empty');
    }
  };
};
