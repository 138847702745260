import React, { FC } from 'react';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import RHInput from 'components/Form/RHInput';
import { makeStyles } from '@material-ui/core/styles';
import RHDropzone from 'components/Form/RHDropzone';
import {URL_REGEX} from 'utils/str';
import {isLinkRequired} from 'utils/resources';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Roboto',
    margin: 0,
    color: '#15374E',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  box: {},
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxesLabel: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#15374E',
  },
}));

interface InjectedProps {
  form: any;
  disabled?: boolean;
}

const AppSpecificResourceForm: FC<InjectedProps> = ({ form: { control, watch }, disabled }) => {
  const classes = useStyles();

  const isApp = watch('isApp');
  const status = watch('status');

  const linkRequired = isApp && isLinkRequired(get(status, 'id', status));

  return (
    <Grid spacing={3} container className={classes.content}>
      <Grid item className={classes.box} xs={12} md={6}>
        <RHInput
          disabled={disabled}
          control={control}
          placeholder="Title/Merchant Name"
          name="appMerchantName"
          label="Title/Merchant Name"
          rules={{ required: isApp }}
        />

        <RHInput
          disabled={disabled}
          control={control}
          helperText="Short description of the resource. (180-250 characters)"
          topIndent
          multiline
          rows={4}
          placeholder="Write a description..."
          name="appDescription"
          label="Description"
        />

        <Grid spacing={3} container>
          <Grid item xs={12} md={6}>
            <RHInput
              control={control}
              disabled
              defaultValue="Learn More"
              topIndent
              helperText="This text is not editable."
              placeholder="Link to Merchant’s PPC Keyword Rules"
              name="appLinkText"
              label="Link Text"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHInput
              disabled={disabled}
              control={control}
              rules={{ required: linkRequired, pattern: { message: 'Invalid url', value: URL_REGEX } }}
              topIndent
              placeholder="https://"
              name="appLink"
              label="Link"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.box} xs={12} md={6}>
        <RHDropzone
          dimensions={{
            width: 240,
            height: 270,
          }}
          disabled={disabled}
          control={control}
          rules={{ required: false }}
          label="Card Image"
          name="appImage"
          dropzoneText="This image will appear on the resource card"
          helperText={
            <span>
              Drag ‘n’ drop image here, or click to select.
              <br />
              Image specs: 240x270 px.
            </span>
          }
        />
      </Grid>
    </Grid>
  );
};

export default AppSpecificResourceForm;
