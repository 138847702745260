import { Button, Paper } from '@material-ui/core';
import {
  AnnsEditForm,
  AnnsEditFormData,
  AnnsEditFormErrors,
  annsToEditFormData,
  getAnnsEditFormErrors,
  getDefAnnsEditFormData,
  polishAnnsEditFormData,
} from 'components/Anns';
import { ButtonProgress } from 'components/Buttons';
import { CentredProgressIndicator, useSnackbar, View } from 'components/Common';
import { AnnouncementUpdateInput, Company, useAnnQuery, useRemoveAnnsMutation, useUpdateAnnsMutation } from 'core/api';
import React, { FC, useEffect, useState } from 'react';
import { colors, m, StyleProps, Styles } from 'styles';
import { errToStr, Log } from 'utils';

const log = Log('screen.DashboardAnnsEdit.scenes.Edit');

interface Props extends StyleProps {
  id: number;
  companies: Company[];
  onGoBack: () => void;
}

export const DashboardAnnsEditScene: FC<Props> = ({ style, id, companies, onGoBack }) => {
  // UI

  const snackbar = useSnackbar();

  // Form

  const [data, setData] = useState<AnnsEditFormData>({ type: 'ICON' });
  const [errs, setErrs] = useState<AnnsEditFormErrors | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);

  const { data: exists, loading, error } = useAnnQuery({ id });

  useEffect(() => {
    if (exists) {
      log.debug('item loading done, data=', exists);
      setData(annsToEditFormData(exists));
    }
  }, [exists]);

  useEffect(() => {
    if (error) {
      snackbar.err(error);
    }
  }, [error]);

  const updateAnns = useUpdateAnnsMutation();
  const removeAnns = useRemoveAnnsMutation();

  // UI

  // Handlers

  const handleDataChange = (value: AnnsEditFormData) => {
    setErrs(undefined);
    const modVal = polishAnnsEditFormData(value);
    setData(modVal);
    log.debug('data changed, value=', modVal);
  };

  const handleSubmitPress = async () => {
    log.debug('handle submit press, data=', data);
    const curErrs = getAnnsEditFormErrors(data);
    if (curErrs) {
      log.debug('form errors found, errs=', curErrs);
      return setErrs(curErrs);
    }
    const {
      type,
      title,
      description,
      additionalDescription,
      cta,
      publishStartDate,
      publishEndDate,
      expirationDate,
      link,
      publishTypes,
      thumbnail,
      image,
      accessKeyIds,
      timezone,
    } = data;
    if (!type || !title || !description || !publishStartDate || !publishTypes || !accessKeyIds || !timezone) {
      return;
    }
    const input: AnnouncementUpdateInput = {
      id,
      type,
      title,
      description,
      additionalDescription,
      cta,
      publishStartDate,
      publishEndDate,
      expirationDate,
      link,
      publishTypes,
      accessKeyIds,
      thumbnail,
      image,
      timezone
    };
    try {
      log.info('updating announcement, input=', input);
      setProcessing(true);
      await updateAnns(input);
      setProcessing(false);
      log.info('updating announcement done, input=', input);
      snackbar.success('The announcement updated successfully');
      onGoBack();
    } catch (err) {
      setProcessing(false);
      snackbar.err(errToStr(err) || 'Unknown error');
      log.err(err);
    }
  };

  const handleCancelPress = () => {
    log.debug('handle cancel press');
    onGoBack();
  };

  const handleResetPress = () => {
    log.debug('handle reset press');
    if (confirm('Are you sure you want to reset this form?')) {
      handleDataChange(getDefAnnsEditFormData());
    }
  };

  const handleRemovePress = async () => {
    log.debug('handlre remove press');
    if (!confirm('Are you sure you want to remove this announcement?')) {
      return;
    }
    try {
      log.debug('removing anns, id=', id);
      await removeAnns(id);
      log.debug('removing anns done, id=', id);
      onGoBack();
    } catch (err) {
      log.err('removing anns err=', err);
      alert(`Removing error: ${errToStr(err)}`);
    }
  };

  return loading ? (
    <CentredProgressIndicator />
  ) : (
    <Paper style={m(styles.container, style)}>
      <AnnsEditForm data={data} errors={errs} companies={companies} onChange={handleDataChange} />
      <View style={styles.actions} row={true} justifyContent="space-between">
        <Button
          style={styles.actionBtn}
          disabled={processing}
          variant="text"
          color="default"
          onClick={handleResetPress}
        >
          Reset
        </Button>
        <View row={true} justifyContent="flex-end">
          <Button
            style={{ ...styles.actionBtn, ...styles.label }}
            disabled={processing}
            variant="contained"
            color="secondary"
            onClick={handleRemovePress}
          >
            Remove
          </Button>
          <Button
            style={styles.actionBtn}
            disabled={processing}
            variant="contained"
            color="default"
            onClick={handleCancelPress}
          >
            Cancel
          </Button>
          <Button
            style={styles.actionBtn}
            variant="contained"
            color="primary"
            disabled={processing}
            onClick={handleSubmitPress}
          >
            {!processing ? `Save` : <ButtonProgress />}
          </Button>
        </View>
      </View>
    </Paper>
  );
};

const styles: Styles = {
  container: {
    padding: 20,
  },
  row: {
    marginTop: 6,
    marginBottom: 6,
  },
  actions: {
    marginTop: 20,
  },
  actionBtn: {
    marginLeft: 6,
    width: 100,
  },
  label: {
    color: colors.white,
  },
};

export type DashboardAnnsEditSceneProps = Props;
export default DashboardAnnsEditScene;
