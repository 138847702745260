import { Button } from '@material-ui/core';
import { CentredProgressIndicator, PageTitle, useSnackbar, View, Header } from 'components/Common';
import { DataTable } from 'components/Tables';
import { Announcement } from 'core/api';
import { sortAnnsByStartDateFn, useAnnsQuery } from 'core/api/anns';
import { sortBy } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'screens/consts';
import { StyleProps, Styles } from 'styles';
import { dateToStr, Log } from 'utils';

const log = Log('screen.DashboardAnnsList');

type Props = StyleProps;

export const DashboardAnnsListScreen: FC<Props> = ({ style }) => {
  // Deps

  const history = useHistory();
  const location = useLocation();
  const snackbar = useSnackbar();
  const { data = [], loading, error } = useAnnsQuery({ expireAt: null });

  useEffect(() => (error ? snackbar.err(error) : undefined), [error]);

  // Data

  const filterItemsWithSearch = (search: string, items: Announcement[]): Announcement[] => {
    if (!search) {
      return items;
    }
    const searchMod = search.toLowerCase().trim();
    return items.filter(itm => {
      const { title, description } = itm;
      const titleMod = title ? title.toLowerCase().trim() : '';
      const descrMod = description ? description.toLowerCase().trim() : '';
      return titleMod.indexOf(searchMod) >= 0 || descrMod.indexOf(searchMod) >= 0;
    });
  };

  // Handlers

  const handleItemPress = (item: Announcement) => {
    log.debug('item click, item=', item.id);
    history.push(`${routes.announcements.default}/${item.id}`);
  };

  const handleCreatePress = () => {
    log.debug('create press');
    history.push(`${routes.announcements.default}/new`);
  };

  // Render

  if (loading) {
    return <CentredProgressIndicator />;
  }

  return (
    <>
      <PageTitle title="Announcements" />
      <Header path={location.pathname} />
      <View style={[styles.container, style]}>
        <DataTable
          title="Announcements List"
          items={sortBy(data, sortAnnsByStartDateFn)}
          searchPlaceholder="Title or description"
          filterWithSearch={filterItemsWithSearch}
          renderRighAction={() => (
            <Button variant="contained" color="primary" onClick={handleCreatePress}>
              Create
            </Button>
          )}
          columns={{
            id: {
              title: 'ID#',
              component: 'th',
              scope: 'row',
              valExtractor: val => `${val}`,
            },
            title: {
              title: 'Title',
              valExtractor: val => val,
            },
            description: {
              title: 'Link',
              valExtractor: val => val,
            },
            cta: {
              title: 'CTA',
              valExtractor: val => val,
            },
            publishStartDate: {
              title: 'Start Date',
              valExtractor: val => (val ? dateToStr(val) : ''),
            },
            publishEndDate: {
              title: 'End Date',
              valExtractor: val => (val ? dateToStr(val) : ''),
            },
            expirationDate: {
              title: 'Expiration Date',
              valExtractor: val => (val ? dateToStr(val) : ''),
            },
          }}
          onItemPress={handleItemPress}
        />
      </View>
    </>
  );
};

const styles: Styles = {
  container: {},
};

export type DashboardAnnsListScreenProps = Props;
export default DashboardAnnsListScreen;
