import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import placeholder from '../../../assets/placeholder.png';
import { Header } from 'components/Common';
import { GridRowParams } from '@material-ui/data-grid';
import { StyleProps } from 'styles/types';
import { NetworkStatus, useQuery } from '@apollo/client';
import { GET_USER_FEED } from 'core/apollo/queries/users';
import { UserFeedResponse } from 'core/api';
import { isNil } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'screens/consts';
import { useAuth } from '../../../hooks/useAuth';
import { isSuperAdmin } from 'utils/permissions';
import SectionTitle from 'components/SectionTitle';
import { useWindowResize } from '../../../hooks/useWindowResize';
import Tag from 'components/Tag';
import DataTable from 'components/Common/DataTable';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rowImage: {
    width: 60,
    height: 60,
    objectFit: 'cover',
    borderRadius: 4,
  },
}));

const columns = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'iconUrl',
    headerName: 'Image',
    // eslint-disable-next-line react/display-name
    renderCell: ({ value }) => {
      const classes = useStyles();

      return <img className={classes.rowImage} src={value || placeholder} />;
    },
  },
  { field: 'firstName', headerName: 'First Name', flex: 1 },
  { field: 'lastName', headerName: 'Last Name', flex: 1 },
  { field: 'email', headerName: 'Email', flex: 1 },
  { field: 'mobileNo', headerName: 'Phone', flex: 1 },
  {
    field: 'isAppInstalled',
    headerName: 'App Installed',
    flex: 1,
    // eslint-disable-next-line react/display-name
    renderCell: ({ value }) => {
      if (isNil(value)) return <Tag backgroundColor="gray" title="N/A" />;
      return value ? <Tag backgroundColor="green" title="Yes" /> : <Tag backgroundColor="orange" title="No" />;
    },
  },
];

const UsersListScreen: FC<StyleProps> = () => {
  const container = useRef<HTMLDivElement>(null);
  const header = useRef<HTMLDivElement>(null);
  const sectionHeader = useRef<HTMLDivElement>(null);
  const { height } = useWindowResize();
  const [headerHeight, setHeaderHeight] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();

  const { data, loading, error, networkStatus, refetch, fetchMore, variables } = useQuery<UserFeedResponse>(
    GET_USER_FEED,
    {
      fetchPolicy: 'network-only',
      variables: {
        limit: 25,
        offset: 0,
      },
    },
  );

  useLayoutEffect(() => {
    if (container.current && header.current && sectionHeader.current) {
      setHeaderHeight(
        container.current.clientHeight - header.current.clientHeight - sectionHeader.current?.clientHeight - 24,
      );
    }
  }, [height]);

  const handleSearchQueryChange = (searchQuery: string) => {
    refetch({
      searchQuery,
    });
  };

  const onItemPress = ({ row: { id } }: GridRowParams) => {
    if (isSuperAdmin(user)) return history.push(`${routes.users.profile}/${id}`);
  };

  const handlePageChange = (offset: number) => {
    return fetchMore({
      variables: {
        ...variables,
        offset,
      },
    });
  };
  const handlePageSizeChange = (limit: number) => {
    refetch({
      offset: 0,
      limit,
    });
  };

  return (
    <div style={{ height: '100%' }} ref={container}>
      <div ref={header}>
        <Header path={location.pathname} />
        <SectionTitle title="Users" />
      </div>

      <DataTable
        sectionHeaderRef={sectionHeader}
        columns={columns}
        rows={data?.userFeed?.data || []}
        rowCount={data?.userFeed?.total || 0}
        onItemPress={onItemPress}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSearchQueryChange={handleSearchQueryChange}
        error={!!error}
        loading={loading || networkStatus === NetworkStatus.setVariables}
        height={headerHeight}
        dataGridProps={{
          rowHeight: 70,
        }}
      />
    </div>
  );
};

export default UsersListScreen;
