import { gql } from '@apollo/client';
import { fields } from 'core/api';

export const GET_META_TAGS = gql`
    query metaTags {
        metaTags {
            ${fields.metaTag}
        }
    }
`;

export const GET_RESOURCE_CATEGORIES = gql`  
    query resourceCategories($level: Int, $categoryIds: [Int!]) {
        resourceCategories(level: $level, categoryIds: $categoryIds) {
            ${fields.resourceCategory}
        } 
    }
`;

export const GRAB_WEBSITE_METADATA = gql`
  query grabWebsiteMetadata($websiteUrl: String!) {
    grabWebsiteMetadata(websiteUrl: $websiteUrl)
  }
`;
