import React, { FC, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { get } from 'lodash';
import RHInput from 'components/Form/RHInput';
import { makeStyles } from '@material-ui/core/styles';
import RHSelect from 'components/Form/RHSelect';
import { Button } from '@material-ui/core';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { GET_CONDITIONS } from 'core/apollo/queries/conditions';
import { CareCondition, MetaTag, NeedType, ResourceCategory } from 'core/api';
import { GET_NEED_TYPES } from 'core/apollo/queries/needTypes';
import { GET_APPOINTMENT_TYPES } from 'core/apollo/queries/appointmentTypes';
import { v4 as uuid } from 'uuid';
import { CREATE_META_TAG } from 'core/apollo/mutation/internal';
import { metaTagCreatedResolver } from 'core/apollo/mutation/resolvers';
import { GET_META_TAGS, GET_RESOURCE_CATEGORIES, GRAB_WEBSITE_METADATA } from 'core/apollo/queries/internal';
import { UseFormReturn } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isSuperAdmin } from 'utils/permissions';
import { useAuth } from '../../../../../hooks/useAuth';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Roboto',
    margin: 0,
    color: '#15374E',
    marginBottom: 25,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  box: {},
  button: {
    minHeight: 48,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

interface InjectedProps {
  form: UseFormReturn<any>;
  disabled?: boolean;
}

interface ResourceCategoriesResponse {
  resourceCategories: ResourceCategory[];
}

const ResourceInternalUseForm: FC<InjectedProps> = ({
  form: { control, setError, setValue, getValues, resetField },
  disabled,
}) => {
  const classes = useStyles();
  const { user } = useAuth();

  const client = useApolloClient();

  const [merchantHomeMetadataLoading, setMerchantHomeMetadataLoading] = useState<boolean>(false);
  const [merchantTrackingMetadataLoading, setMerchantTrackingMetadataLoading] = useState<boolean>(false);

  const { loading: careConditionsLoading, data: conditions } = useQuery<{ careConditions: CareCondition[] }>(
    GET_CONDITIONS,
  );
  const { loading: needTypesLoading, data: tasks } = useQuery<{ needTypes: NeedType[] }>(GET_NEED_TYPES);
  const { loading: aTypesLoading, data: aTypes } = useQuery<{ appointmentTypes: NeedType[] }>(GET_APPOINTMENT_TYPES);
  const { loading: mTagsLoading, data: mTags } = useQuery<{ metaTags: MetaTag[] }>(GET_META_TAGS);

  const { data: mcResponse, loading: mcLoading } = useQuery<ResourceCategoriesResponse>(GET_RESOURCE_CATEGORIES, {
    variables: {
      level: 0,
    },
  });
  const { data: scResponse, loading: scLoading, refetch: refetchSubCategories } = useQuery<ResourceCategoriesResponse>(
    GET_RESOURCE_CATEGORIES,
    {
      variables: {
        level: 1,
      },
    },
  );
  const { data: tResponse, loading: tLoading, refetch: refetchTopics } = useQuery<ResourceCategoriesResponse>(
    GET_RESOURCE_CATEGORIES,
    {
      variables: {
        level: 2,
      },
    },
  );

  const [createMetaTag] = useMutation(CREATE_META_TAG, {
    update: metaTagCreatedResolver,
    ignoreResults: true,
  });

  const handleMetaTagCreate = async (name: string) => {
    const { metaTags } = getValues();
    try {
      const response = await createMetaTag({
        variables: {
          input: {
            id: uuid,
            name,
          },
        },
      });
      setValue('metaTags', [...metaTags, get(response, ['data', 'createMetaTag'])]);
    } catch (e) {
      setError('metaTags', e.message);
    }
  };
  const handleMainCategorySelect = (categories: ResourceCategory[]) => {
    resetField('subCategories', { defaultValue: [] });
    resetField('topics', { defaultValue: [] });
    return refetchSubCategories({
      categoryIds: categories.map(c => c.id),
    });
  };
  const handleSubCategorySelect = (categories: ResourceCategory[]) => {
    resetField('topics', { defaultValue: [] });
    return refetchTopics({
      categoryIds: categories.map(c => c.id),
    });
  };

  const handleMerchantHomeMetadataLoad = async () => {
    const { merchantHomePageUrl } = getValues();
    resetField('merchantPageMetadata');
    try {
      setMerchantHomeMetadataLoading(true);
      const response = await client.query({
        query: GRAB_WEBSITE_METADATA,
        fetchPolicy: 'network-only',
        variables: {
          websiteUrl: merchantHomePageUrl,
        },
      });

      setValue('merchantPageMetadata', get(response, ['data', 'grabWebsiteMetadata']));
      setMerchantHomeMetadataLoading(false);
    } catch (e) {
      setError('merchantPageMetadata', e.message);
      setValue('merchantPageMetadata', '');
      setMerchantHomeMetadataLoading(false);
    }
  };
  const handleMerchantTrackingMetadataLoad = async () => {
    const { merchantTrackingUrl } = getValues();
    resetField('merchantTrackingMetadata');

    try {
      setMerchantTrackingMetadataLoading(true);
      const response = await client.query({
        fetchPolicy: 'network-only',
        query: GRAB_WEBSITE_METADATA,
        variables: {
          websiteUrl: merchantTrackingUrl,
        },
      });

      setValue('merchantTrackingMetadata', get(response, ['data', 'grabWebsiteMetadata']));
      setMerchantTrackingMetadataLoading(false);
    } catch (e) {
      setError('merchantTrackingMetadata', e.message);
      setValue('merchantTrackingMetadata', '');
      setMerchantTrackingMetadataLoading(false);
    }
  };

  const renderCategoryLabel = (o: any) => {
    const option = o as unknown as ResourceCategory;

    return `[${option.id}] ${option.name}`;
  }

  return (
    <div>
      <Grid spacing={3} container className={classes.content}>
        <Grid item className={classes.box} xs={12} md={6}>
          <h3 className={classes.title}>Categorization</h3>

          <RHSelect
            renderLabel={renderCategoryLabel}
            hidden={!isSuperAdmin(user)}
            disabled={disabled}
            control={control}
            label="Mega Category"
            options={mcResponse?.resourceCategories}
            name="megaCategories"
            rules={{ required: isSuperAdmin(user) }}
            selectProps={{
              isLoading: mcLoading,
              onSelect: handleMainCategorySelect,
              isMulti: true,
            }}
          />

          <RHSelect
            renderLabel={renderCategoryLabel}
            hidden={!isSuperAdmin(user)}
            disabled={disabled}
            control={control}
            topIndent
            placeholder="Sub Categories"
            label="Sub Category"
            options={scResponse?.resourceCategories}
            name="subCategories"
            rules={{ required: isSuperAdmin(user) }}
            selectProps={{
              isMulti: true,
              isLoading: scLoading,
              onSelect: handleSubCategorySelect,
            }}
          />

          <RHSelect
            renderLabel={renderCategoryLabel}
            hidden={!isSuperAdmin(user)}
            disabled={disabled}
            control={control}
            topIndent
            placeholder="Topics"
            label="Topic"
            options={tResponse?.resourceCategories}
            name="topics"
            rules={{ required: isSuperAdmin(user) }}
            selectProps={{
              isMulti: true,
              isLoading: tLoading,
            }}
          />

          <RHSelect
            showAllOption
            disabled={disabled}
            control={control}
            selectProps={{
              isMulti: true,
              isLoading: careConditionsLoading,
            }}
            topIndent
            placeholder="Conditions"
            label="Condition"
            options={conditions?.careConditions}
            name="conditions"
          />

          <RHSelect
            showAllOption
            disabled={disabled}
            control={control}
            topIndent
            placeholder="Tasks"
            label="Task"
            selectProps={{
              isMulti: true,
              isLoading: needTypesLoading,
            }}
            options={tasks?.needTypes}
            name="needTypes"
          />

          <RHSelect
            showAllOption
            disabled={disabled}
            control={control}
            topIndent
            placeholder="Appointments"
            label="Appointment"
            selectProps={{
              isMulti: true,
              isLoading: aTypesLoading,
            }}
            options={aTypes?.appointmentTypes}
            name="appointmentTypes"
          />
        </Grid>
        <Grid item className={classes.box} xs={12} md={6}>
          <h3 className={classes.title}>Meta Data</h3>

          <RHInput
            disabled={disabled}
            control={control}
            placeholder="https://"
            name="merchantHomePageUrl"
            label="Link to Merchant Home Page"
            button={
              <Button
                disabled={disabled || merchantHomeMetadataLoading}
                onClick={handleMerchantHomeMetadataLoad}
                className={classes.button}
                disableElevation
                size="large"
                color="primary"
                endIcon={merchantHomeMetadataLoading ? <CircularProgress size={14} color="inherit" /> : null}
                variant="contained"
              >
                Grab Meta Data from Site
              </Button>
            }
          />

          <RHInput
            disabled={disabled}
            control={control}
            topIndent
            inputProps={{
              readOnly: true,
            }}
            placeholder="Merchant home page meta data return"
            name="merchantPageMetadata"
            label="Meta Data Return"
          />

          <RHInput
            control={control}
            topIndent
            disabled={disabled}
            placeholder="https://"
            name="merchantTrackingUrl"
            label="Link to Merchant Tracking"
            button={
              <Button
                disabled={disabled || merchantTrackingMetadataLoading}
                onClick={handleMerchantTrackingMetadataLoad}
                className={classes.button}
                disableElevation
                size="large"
                color="primary"
                endIcon={merchantTrackingMetadataLoading ? <CircularProgress size={14} color="inherit" /> : null}
                variant="contained"
              >
                Grab Meta Data from Site
              </Button>
            }
          />

          <RHInput
            disabled={disabled}
            control={control}
            topIndent
            inputProps={{
              readOnly: true,
            }}
            placeholder="Merchant home page meta data return"
            name="merchantTrackingMetadata"
            label="Meta Data Return"
          />

          <RHSelect
            selectProps={{
              onCreateOption: handleMetaTagCreate,
              isCreatable: true,
              isMulti: true,
              isLoading: mTagsLoading,
            }}
            disabled={disabled}
            control={control}
            topIndent
            placeholder="Type here|"
            label="CircleOf Meta-Tags"
            options={mTags?.metaTags}
            name="metaTags"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ResourceInternalUseForm;
