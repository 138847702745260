import { Button, Paper } from '@material-ui/core';
import { Image, Text, View, PageTitle, useSnackbar } from 'components/Common';
import { forgotPassword } from 'core/api';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { colors, px, StyleProps, Styles } from 'styles';
import { errToStr, Log } from 'utils';
import { FormTextField } from 'components/Form';
import { isEmailValid } from '../../../core/api/utils';

import logoImg from '../../../assets/logo.png';
import { appConfigs } from 'core/configs';

const log = Log('screen.AuthEntry.ForgotPasswordScreen');

const texts = {
  initial:
    'We can fix that! Enter the email address you used to create your account and we will send you a password reset link.',
  emailNotValid: 'Error: Email address is invalid',
};

type Props = StyleProps;

export const ForgotPasswordScreen: FC<Props> = () => {
  const [err, setErr] = useState<string | undefined>();
  const [email, setEmail] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const resetPassword = forgotPassword();

  const history = useHistory();

  // UI

  const snackbar = useSnackbar();

  const onSubmitPress = async () => {
    log.info('on submit press');
    if (!isEmailValid(email)) {
      return setErr(texts.emailNotValid);
    }
    setErr(undefined);

    try {
      log.info('reset password req');
      setProcessing(true);
      const resetpasswordData = await resetPassword({ email });
      setProcessing(false);
      log.info('reset password req done');
      log.debug('resetpassword=', resetpasswordData);
      snackbar.success('Link to reset password sent!');
      history.push('/auth/entry');
    } catch (err) {
      setProcessing(false);
      log.err(errToStr(err));
      setErr(errToStr(err));
    }
  };

  return (
    <>
      <PageTitle title="Reset Password" />
      <View style={styles.container}>
        <Paper style={styles.content} elevation={2}>
          <View row={true} justifyContent="center" alignItems="center">
            <Image style={styles.logo} source={logoImg} />
          </View>
          <View justifyContent="center" alignItems="center">
            <Text size={22.5}>Trouble Signing In?</Text>
            <Text size={14} style={styles.description}>
              {texts.initial}
            </Text>
          </View>
          <View style={styles.errWrap} justifyContent="center" alignItems="center">
            {!!err && (
              <Text style={styles.err}>
                <b>Error:</b> {err}
              </Text>
            )}
          </View>
          <View>
            <FormTextField
              style={styles.row1}
              value={email}
              label="Email"
              type="email"
              disabled={processing}
              error={!!err}
              onChange={e => setEmail(e.target.value)}
            />
            <Button variant="contained" color="primary" disabled={processing} onClick={onSubmitPress}>
              Reset Password
            </Button>

            <Button variant="text" style={{ marginTop: 20 }} disableElevation onClick={() => history.goBack()}>
              Cancel
            </Button>
          </View>
          <Text style={styles.version} block={true}>{`v${appConfigs.version}`}</Text>
        </Paper>
      </View>
    </>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: '50px 30px',
    width: 413,
    position: 'relative',
  },
  row1: {
    marginTop: 10,
    marginBottom: 34,
  },
  logo: {
    width: 160,
    height: 32,
    marginBottom: 20,
    marginLeft: -18,
  },
  errWrap: {
    height: 20,
  },
  err: {
    color: colors.error,
    fontSize: px(14),
    textAlign: 'center',
  },
  version: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 4,
    fontSize: '10px',
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    margin: '22px 20px',
  },
};

export type ForgotPasswordScreenProps = Props;
export default ForgotPasswordScreen;
