import { version } from '../../package.json';

type AppConfigsEnv = 'prd' | 'dev' | 'qa';

interface AppConfigsApi {
  uri: string;
  wss: string;
  baseUrl: string;
}

const getEnvConfig = (): AppConfigsEnv => {
  if (!ENV) {
    return 'prd';
  }
  const str = ENV.toLocaleLowerCase().trim();
  switch (str) {
    case 'prd':
      return 'prd';
    case 'dev':
      return 'dev';
    case 'qa':
      return 'qa';
    default:
      return 'prd';
  }
};

const getApiConfig = (): AppConfigsApi => {
  const env = getEnvConfig();
  const dev: AppConfigsApi = {
    uri: 'https://api.dev.circleof.com/graphql',
    baseUrl: 'https://api.dev.circleof.com',
    wss: 'wss://api.dev.circleof.com:4000/subscriptions',
  };
  const qa: AppConfigsApi = {
    uri: 'https://api.qa.circleof.com/graphql',
    baseUrl: 'https://api.qa.circleof.com',
    wss: 'wss://api.qa.circleof.com:4000/subscriptions',
  };
  const prd: AppConfigsApi = {
    uri: 'https://api.circleof.com/graphql',
    baseUrl: 'https://api.circleof.com',
    wss: 'wss://api.circleof.com:4000/subscriptions',
  };
  // const local: AppConfigsApi = {
  //   uri: 'http://192.168.0.104:4000/graphql',
  //   baseUrl: 'http://192.168.0.104:4000',
  //   wss: 'ws://192.168.0.104:4000/subscriptions',
  // };
  switch (env) {
    case 'prd':
      return prd;
    case 'dev':
      return dev;
    case 'qa':
      return qa;
    default:
      return prd;
  }
};

export const appConfigs = {
  version,
  env: getEnvConfig(),
  api: getApiConfig(),
};
