import React, { FC, MutableRefObject, useCallback } from 'react';
import { DataGrid, GridColumns, GridPageChangeParams, GridRowData, GridRowParams } from '@material-ui/data-grid';
import { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import ResourceSearchInput from 'screens/Resources/List/components/SearchInput';

const ColumnResizeIcon = () => null;

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flex: 1,
  },
  sectionHeader: {
    marginTop: 16,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  root: {
    cursor: 'pointer',
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),
    borderRadius: 0,
  },
  tableContainer: {
    backgroundColor: '#fff',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

interface InjectedProps {
  columns: GridColumns;
  rows: GridRowData[];
  rowCount: number;
  onItemPress?: (row: GridRowParams) => void;
  onPageChange?: (offset: number) => void;
  onPageSizeChange?: (limit: number) => void;
  onSearchQueryChange?: (query: string) => void;
  error?: boolean;
  loading?: boolean;
  height?: number;
  sectionHeaderRef?: MutableRefObject<HTMLDivElement | null>;
  dataGridProps?: any;
}

const DataTable: FC<InjectedProps> = ({
  loading,
  error,
  onSearchQueryChange = () => {
    return;
  },
  onPageChange,
  children,
  columns,
  rows,
  rowCount,
  onPageSizeChange,
  onItemPress,
  height,
  sectionHeaderRef,
  dataGridProps,
}) => {
  const classes = useStyles({});

  const handleSearchQueryChange = useCallback(debounce(onSearchQueryChange, 250), []);

  const handlePageChange = (page: GridPageChangeParams) => {
    onPageChange && onPageChange(page.page * page.pageSize);
  };
  const handlePageSizeChange = (page: GridPageChangeParams) => {
    onPageSizeChange && onPageSizeChange(page.pageSize);
  };

  return (
    <div className={classes.content}>
      <div ref={sectionHeaderRef} className={classes.sectionHeader}>
        <ResourceSearchInput onChange={handleSearchQueryChange} />

        {children}
      </div>
      <div style={{ height }} className={classes.tableContainer}>
        <DataGrid
          disableSelectionOnClick
          onRowClick={onItemPress}
          error={error || undefined}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          loading={loading}
          className={classes.root}
          rows={rows}
          columns={columns}
          rowCount={rowCount}
          rowHeight={60}
          pageSize={25}
          disableColumnFilter
          disableColumnMenu
          disableColumnResize
          components={{
            ColumnResizeIcon,
          }}
          {...dataGridProps}
        />
      </div>
    </div>
  );
};

export default DataTable;
