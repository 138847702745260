import { isString } from 'lodash';

export const getStorageVal = <T = unknown>(key: string) => {
  const get = (): T | undefined => {
    const valStr = localStorage.getItem(key);
    if (!isString(valStr)) {
      return undefined;
    }
    return (JSON.parse(valStr) as unknown) as T;
  };

  const set = (val: T) => {
    const valStr = JSON.stringify(val);
    localStorage.setItem(key, valStr);
  };

  const remove = () => {
    localStorage.removeItem(key);
  };

  return { get, set, remove };
};

export const accessTokenStorage = getStorageVal<string>('token');
