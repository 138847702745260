import React, { FC, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import RHInput from 'components/Form/RHInput';
import { makeStyles } from '@material-ui/core/styles';
import RHDropzone from 'components/Form/RHDropzone';
import RHCheckbox from 'components/Form/RHCheckbox';
import { UseFormReturn } from 'react-hook-form';
import {URL_REGEX} from 'utils/str';
import {isLinkRequired} from 'utils/resources';
import {get} from 'lodash';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Roboto',
    margin: 0,
    color: '#15374E',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  box: {},
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxesLabel: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#15374E',
  },
}));

interface InjectedProps {
  form: UseFormReturn<any>;
  disabled?: boolean;
}

const WebSpecificResourceForm: FC<InjectedProps> = ({
  form: { control, setValue, watch, reset, getValues },
  disabled,
}) => {
  const classes = useStyles();

  const getNameField = (name: string) => {
    switch (name) {
      case 'appMerchantName':
        return 'webMerchantName';
      case 'appDescription':
        return 'webDescription';
      case 'appLink':
        return 'webLink';
      case 'appImage':
        return 'webImage';
    }
  };

  useEffect(() => {
    const values = getValues();
    let watchSubscription = { unsubscribe: () => null } as any;

    if (values.webAutofill) {
      watchSubscription = watch((values, { name }) => {
        if (name) {
          if (['appMerchantName', 'appDescription', 'appLink', 'appImage'].includes(name)) {
            setValue(getNameField(name) || '', values[name]);
          }
        }
      });

      // Update on the fly web form
      reset({
        ...values,
        webMerchantName: values.appMerchantName,
        webDescription: values.appDescription,
        webLink: values.appLink,
        webImage: values.appImage,
      });
    }

    return () => {
      watchSubscription.unsubscribe();
    };
  }, [watch('webAutofill')]);

  const isWeb = watch('isWeb');
  const status = watch('status');

  const linkRequired = isWeb && isLinkRequired(get(status, 'id', status));

  return (
    <Grid spacing={3} container className={classes.content}>
      <Grid item xs={12}>
        <RHCheckbox
          disabled={disabled}
          control={control}
          name="webAutofill"
          defaultValue={false}
          label="Autofill info same as for App."
        />
      </Grid>
      <Grid item className={classes.box} xs={12} md={6}>
        <RHInput
          disabled={disabled}
          control={control}
          placeholder="Title/Merchant Name"
          name="webMerchantName"
          label="Title/Merchant Name"
          rules={{ required: isWeb }}
        />

        <RHInput
          disabled={disabled}
          control={control}
          helperText="Short description of the resource. (280-315 characters)"
          topIndent
          multiline
          rows={4}
          placeholder="Write a description..."
          name="webDescription"
          label="Description"
        />

        <Grid spacing={3} container>
          <Grid item xs={12} md={6}>
            <RHInput
              control={control}
              disabled
              defaultValue="Visit Website"
              topIndent
              helperText="This text is not editable."
              placeholder="Link to Merchant’s PPC Keyword Rules"
              name="webLinkText"
              label="Link Text"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHInput
              disabled={disabled}
              control={control}
              rules={{ required: linkRequired, pattern: { message: 'Invalid url', value: URL_REGEX } }}
              topIndent
              placeholder="https://"
              name="webLink"
              label="Link"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.box} xs={12} md={6}>
        <RHDropzone
          dimensions={{
            width: 350,
            height: 210,
          }}
          disabled={disabled}
          control={control}
          rules={{ required: false }}
          helperText={
            <span>
              Drag ‘n’ drop image here, or click to select.
              <br />
              Image specs: 350x210 px.
            </span>
          }
          dropzoneText="This image will appear on the resource card"
          label="Card Image"
          name="webImage"
        />
      </Grid>
    </Grid>
  );
};

export default WebSpecificResourceForm;
