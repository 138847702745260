import { gql } from '@apollo/client';
import { fields } from 'core/api';

export const GET_COMPANIES = gql`
    query companies {
        companies {
            ${fields.company}
        }
    }
`;

export const GET_COMPANIES_BASIC = gql`
    query companies {
        companies {
            ${fields.companyBasic}
        }
    }
`;
