import { Button, Paper } from '@material-ui/core';
import { ButtonProgress } from 'components/Buttons';
import { useSnackbar, View, Text } from 'components/Common';
import {
  polishInviteFormData,
  getInviteFormErrors,
  getDefCompaniesEditFormData,
  polishContactData,
} from 'components/Companies/InviteForm/utils';
import { InvitationInput, useInviteContactMutation, CareTeamMember, CareTeam } from 'core/api';
import { getStorageVal } from 'core/storage';
import React, { ChangeEvent, FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { m, StyleProps, Styles } from 'styles';
import { errToStr, Log } from 'utils';
import { Grid } from '@material-ui/core';
import { FormSelectField, FormContactsTypeField, FormTextField, FormRolesTypeField } from 'components/Form';
import { InviteFormData } from './types';

const log = Log('screen.DashboardCompaniesEdit.scenes.Invite');

type FormData = Partial<InvitationInput>;
type FormErrors = Partial<Record<keyof FormData, string>>;
type FormHelperTexts = Partial<Record<keyof FormData, string>>;

interface Props extends StyleProps {
  currentUser: CareTeamMember;
  errors?: FormErrors;
  publicTeam: CareTeam;
  publicTeamList: CareTeam[];
}

const helpTexts: FormHelperTexts = {
  message: 'Short message (7 to 50 words; 50-300 chars)',
  careTeamId: 'Choose a public team to invite',
};

const draftStorage = getStorageVal<InviteFormData>('companies:edit:draft');

export const InviteToTeamForm: FC<Props> = ({ style, currentUser, publicTeam, publicTeamList }) => {
  // Form

  const [data, setData] = useState<InviteFormData>(getDefCompaniesEditFormData());
  const [errors, setErrs] = useState<FormErrors | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);
  const { user } = currentUser;

  const createInvite = useInviteContactMutation();

  // Navigation

  const history = useHistory();

  // UI

  const snackbar = useSnackbar();

  // Handlers

  const handleDataChange = (value: InviteFormData) => {
    setErrs(undefined);
    const modVal = polishInviteFormData(value);
    setData(modVal);
    log.debug('data changed, value=', modVal);
    draftStorage.set(modVal);
  };

  const handleSubmitPress = async () => {
    try {
      const curErrs = getInviteFormErrors(data);
      if (curErrs) {
        return setErrs(curErrs);
      }
      const { message, careTeamRoleId, careTeamId, inviteeName, contactType } = data;
      if (!careTeamRoleId || !careTeamId || !inviteeName || !contactType) {
        return;
      }
      const input: InvitationInput = {
        careTeamId,
        careTeamRoleId,
        contacts: polishContactData(contactType, user),
        inviteeName,
        message,
      };
      log.info('inviting user, input=', input);
      setProcessing(true);
      await createInvite(input);
      setProcessing(false);
      log.info('invite user done, input=', input);
      draftStorage.remove();
      snackbar.success('Invite user successfully');
      history.goBack();
    } catch (err) {
      setProcessing(false);
      log.err(err);
      snackbar.err(errToStr(err) || 'Unknown error');
    }
  };

  const handleResetPress = () => {
    log.debug('handle reset press');
    if (confirm('Are you sure you want to reset this form?')) {
      handleDataChange(getDefCompaniesEditFormData());
    }
  };

  const handleTextFieldChanged = (k: keyof FormData) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    handleDataChange({ ...data, [k]: value });
  };

  const handleTypeDataChange = <K extends keyof FormData>(k: K) => (val: FormData[K]) => {
    handleDataChange({ ...data, [k]: val });
  };

  return (
    <Paper style={m(styles.container, style)}>
      <Text size={21}>Invite Form</Text>

      <Grid style={m(styles.container, style)} container={true} spacing={2}>
        <Grid item={true} md={6}>
          <FormRolesTypeField
            style={styles.row}
            label="Team Role Type"
            required={true}
            value={data.careTeamRoleId}
            error={!!errors?.careTeamRoleId}
            helperText={errors?.careTeamRoleId || helpTexts.careTeamRoleId}
            onChange={handleTypeDataChange('careTeamRoleId')}
          />
          <FormSelectField
            style={styles.row}
            label="Public team"
            placeholder="Choose team"
            variant="outlined"
            fullWidth={true}
            required={true}
            error={!!errors?.careTeamId}
            helperText={errors?.careTeamId || helpTexts.careTeamId}
            items={publicTeamList.filter(p => p.id !== publicTeam.id).map(itm => itm.id)}
            keyExtractor={id => `${id}`}
            titleExtractor={id => publicTeamList.find(itm => itm.id === id)?.name || `${id}`}
            value={data.careTeamId}
            onChange={handleTypeDataChange('careTeamId')}
          />
          <FormTextField
            style={styles.row}
            label="Message"
            multiline={true}
            fullWidth={true}
            InputProps={{ inputProps: { maxLength: 300 } }}
            error={!!errors?.message}
            helperText={errors?.message || helpTexts.message}
            rows={6}
            placeholder=""
            value={data.message}
            onChange={handleTextFieldChanged('message')}
          />
        </Grid>
        <Grid item={true} md={6}>
          <FormContactsTypeField
            style={styles.row}
            label="Contact Method"
            required={true}
            value={data.contactType}
            error={!!errors?.contacts}
            helperText={errors?.contacts || helpTexts.contacts}
            onChange={handleTypeDataChange('contactType')}
          />
        </Grid>
      </Grid>
      <View style={styles.actions} row={true} justifyContent="space-between">
        <Button
          style={styles.actionBtn}
          disabled={processing}
          variant="text"
          color="default"
          onClick={handleResetPress}
        >
          Reset
        </Button>
        <View row={true} justifyContent="flex-end">
          <Button
            style={styles.actionBtn}
            variant="contained"
            color="primary"
            disabled={processing}
            onClick={handleSubmitPress}
          >
            {!processing ? `Invite` : <ButtonProgress />}
          </Button>
        </View>
      </View>
    </Paper>
  );
};

const styles: Styles = {
  container: {
    padding: 20,
    marginTop: 20,
  },
  row: {
    marginTop: 6,
    marginBottom: 6,
  },
  actions: {
    marginTop: 20,
  },
  actionBtn: {
    marginLeft: 6,
    width: 100,
  },
};

export type DashboardUserInviteSceneProps = Props;
export default InviteToTeamForm;
