import { gql } from '@apollo/client';
import { fields } from 'core/api';

export const UPDATE_ADMIN_USER = gql`mutation updateAdminUser($id: Int!, $input: UpdateAdminUserInput!){
    updateAdminUser(id: $id, input: $input) {
        ${fields.adminUser}
    }
}`;

export const RESET_PASSWORD =
gql`mutation restorePassword($input: RestorePasswordInput!, $onboarding: Boolean, $guard: Guard){
    restorePassword(input: $input, onboarding: $onboarding, guard: $guard) {
        ${fields.authorized}
    }
}`;

export const CREATE_ADMIN_USER = gql`mutation createAdminUser($input: CreateAdminUserInput!){
    createAdminUser(input: $input) {
        ${fields.adminUser}
    }
}`;

export const REMOVE_ADMIN_USER = gql`
  mutation removeAdminUser($id: Int!) {
    removeAdminUser(id: $id)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ChangePasswordInput!, $guard: Guard) {
    changePassword(input: $input, guard: $guard) {
      status
    }
  }
`;

export const RESEND_ADMIN_USER_INVITE = gql`
  mutation resendAdminUserInvite($id: Int!) {
    resendAdminUserInvite(id: $id)
  }
`;
