import React, { FC, ReactNode, useState } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { useDropzone } from 'react-dropzone';
import ImageCropPreviewDialog from 'components/ImageCropPreviewDialog';
import { Button } from '@material-ui/core';
import { buildAttachmentInput } from 'utils/image';
import { AttachmentType } from 'core/api';
import { ReactCropProps } from 'react-image-crop';

interface InjectedProps {
  placeholder?: any;
  name: string;
  defaultValue?: boolean;
  label?: string;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  topIndent?: boolean;
  helperText?: string | ReactNode;
  dropzoneText?: string | ReactNode;
  control: Control<any>;
  dimensions: { width: number; height: number };
  disabled?: boolean;
  cropProps?: Partial<ReactCropProps>;
}

const useStyles = makeStyles(() => ({
  control: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: 8,
    fontWeight: 500,
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#15374E',
  },
  asterisk: {
    color: '#DD4A4A',
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropzone: {
    border: '1px dashed #E8EBED',
    borderRadius: 4,
    marginRight: 24,
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
  },
  dropzoneHelperText: {
    fontSize: 14,
    color: '#C7CFD5',
    fontWeight: 400,
  },
  dropzoneText: {
    fontSize: 12,
    color: '#8A9BA6',
    marginTop: 8,
    display: 'block',
  },
  button: {
    color: 'red',
    borderColor: 'red',
    marginTop: 8,
  },
}));

const Dropzone = ({ multiple, onChange, onReset, value, placeholder, ...rest }) => {
  const classes = useStyles();

  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    ...rest,
  });

  return (
    <div className={classes.dropzoneContainer}>
      <div>
        <div
          {...getRootProps()}
          className={classes.dropzone}
          style={{ ...get(rest, 'dimensions', {}), borderRadius: rest.circular ? '100%' : 0 }}
        >
          <input disabled={rest.disabled} {...getInputProps({ onChange })} />
          {!!value
            ? <img src={value} style={{ ...get(rest, 'dimensions', {}), objectFit: 'contain' }} />
            : (placeholder ? <img src={placeholder} style={{ ...get(rest, 'dimensions', {}), objectFit: 'contain' }} /> : null)}
        </div>

        {value && !rest.disabled && (
          <Button onClick={onReset} className={classes.button} size="small" variant="outlined">
            Reset
          </Button>
        )}
        {!!rest.dropzoneText && <span className={classes.dropzoneText}>{rest.dropzoneText}</span>}
      </div>

      {!!rest.helperText && <span className={classes.dropzoneHelperText}>{rest.helperText}</span>}
    </div>
  );
};

const RHDropzone: FC<InjectedProps> = ({
  placeholder,
  control,
  name,
  defaultValue = '',
  label = '',
  rules,
  topIndent = false,
  helperText,
  dropzoneText,
  dimensions = { width: 240, height: 270 },
  disabled,
  cropProps,
}) => {
  const classes = useStyles();
  const [imageSource, setImageSource] = useState<string>();

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageSource(reader.result?.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleCropSubmit = (cb: (...e: any[]) => void) => (canvas: HTMLCanvasElement) => {
    cb(buildAttachmentInput(AttachmentType.IMAGE, canvas.toDataURL('image/png', .8)));
    setImageSource(undefined);
  };

  const handleReset = (cb: (...e: any[]) => void) => () => {
    setImageSource(undefined);
    cb(undefined);
  };

  return (
    <div style={{ marginTop: topIndent ? 25 : 0 }} className={classes.control}>
      {!!label && (
        <label className={classes.label} htmlFor={name}>
          {label}
          {rules?.required && <span className={classes.asterisk}>*</span>}
        </label>
      )}

      <Controller
        rules={rules}
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <>
            <Dropzone
              placeholder={placeholder}
              circular={cropProps?.circularCrop}
              dropzoneText={dropzoneText}
              disabled={disabled}
              dimensions={dimensions}
              helperText={helperText}
              multiple={false}
              value={get(value, 'url', get(value, 'url'))}
              onReset={handleReset(onChange)}
              onChange={handleFileSelect}
            />
            <ImageCropPreviewDialog
              initialCrop={{
                unit: 'px',
                x: 25,
                y: 25,
                width: dimensions.width,
                height: dimensions.height,
              }}
              cropSize={{
                width: dimensions.width,
                height: dimensions.height,
              }}
              aspect={dimensions.width / dimensions.height}
              source={imageSource}
              onSubmit={handleCropSubmit(onChange)}
              onCancel={() => setImageSource(undefined)}
              cropProps={{
                locked: true,
                minHeight: dimensions.height,
                minWidth: dimensions.width,
                maxHeight: dimensions.height,
                maxWidth: dimensions.width,
                ...cropProps,
              }}
            />
          </>
        )}
      />
    </div>
  );
};

export default RHDropzone;
