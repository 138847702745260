export interface UnknownDict {
  [index: string]: unknown;
}

export const isUnknownDict = (candidate: unknown): candidate is UnknownDict =>
  typeof candidate === 'object' && candidate !== null;

/**
 * Return data or undefinde value
 * @param val - input value
 */
export const dataOrUndef = <T>(val: T | null | undefined): T | undefined => (val ? val : undefined);

export const select = <K extends string | number, T extends unknown>(key: K, data: Record<K, T>) => data[key];

export enum RecurrentPeriod {
  NEVER = 'NEVER',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  EVERY_WEEKDAY = 'WEEKDAY',
  CUSTOM = 'CUSTOM',
}
