import { select } from 'utils';
import { MerchantStatus, ResourceStage, ResourceStatus } from 'core/apollo/consts';

// Enums

export enum UserType {
  Patient = 1,
  InnerCircle = 2,
  FriendsAndFamily = 3,
  Unspecified,
}

export enum BenefitsViewType {
  BENEFITS_CENTER = 'BENEFITS_CENTER',
  BENEFITS_HOME = 'BENEFITS_HOME',
}

export enum TeamDiscoverableType {
  INVITE_ONLY = 'INVITE_ONLY',
  WITHIN_THE_COMPANY = 'WITHIN_THE_COMPANY',
  ANY = 'ANY',
}

export enum InvitationStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Declined = 'declined',
  Error = 'error',
}

export enum RecurrentPeriod {
  NEVER = 'NEVER',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  WEEKDAY = 'WEEKDAY',
  CUSTOM = 'CUSTOM',
}

// Schema

export interface User {
  id: number;
  firstName?: string;
  lastName?: string;
  sex?: string;
  mobileNo?: string;
  email: string;
  iconUrl?: string | null;
  timezone?: string;
  deletedAt?: Date | null;
  role: string;
  companyId: number;
  company: Company | null;
}

export interface AdminUser {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  iconUrl?: string | null;
  deletedAt?: Date | null;
  role: string;
  companyId: number;
  company: string | null;
}

export interface Authorized {
  accessToken: string;
  data?: User;
}

export interface SystemRole {
  id: number;
  name: string;
  description?: string;
}

export interface Location {
  id?: number;
  name?: string;
  address: string;
  longitude: number;
  latitude: number;
}

export interface LocationInput {
  name?: string;
  address: string;
  longitude: number;
  latitude: number;
}

export enum AttachmentType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  LINK = 'LINK',
}

export interface AttachmentInput {
  id?: number;
  type: AttachmentType;
  contentType?: string;
  url?: string;
  uri?: string;
}

export interface WebsiteUrlInput {
  url: string;
  label?: string;
}

export interface PhoneNumberInput {
  phone: string;
  label?: string;
}

// Company

export type CompanyType = 'SLF' | 'CORPORATE';

export const companyTypeToStr = (val: CompanyType): string =>
  select(val, {
    CORPORATE: 'Corporate',
    SLF: 'Senior Living Facility',
  });

export interface Company {
  id: number;
  name: string;
  logo?: string;
  url?: string;
  type: CompanyType;
  companyId?: number;
  location?: Location;
  accessKeys: AccessKey[];
  background?: string;
  tagLine?: string;
  resourceCenterName?: string;
  publicTeam: CareTeam | null;
  createdAt: Date;
  payment?: string; // TODO: need back end support
  status?: string; // TODO: need back end support
}

export interface CreateCompanyInput {
  name: string;
  logo: AttachmentInput;
  type: CompanyType;
  companyId?: number;
  location?: LocationInput;
  websiteUrl?: string;
  secondWebsiteUrl?: string;
  websiteLabel?: string;
  secondWebsiteLabel?: string;
  phone?: string;
  secondPhone?: string;
  phoneLabel?: string;
  secondPhoneLabel?: string;
  accessKeys?: AccessKey[];
  background: AttachmentInput;
  tagLine: string;
  resourceCenterName?: string;
  teamCreatorId: number | null | undefined;
}

export interface UpdateCompanyInput {
  id: number;
  name?: string;
  logo?: AttachmentInput;
  companyId?: number;
  location?: LocationInput;
  websiteUrl?: string;
  background: AttachmentInput;
  tagLine: string;
  resourceCenterName?: string;
  teamCreatorId: number;
}

// Announcements

export type AnnouncementType = 'ICON' | 'IMAGE';

export const isAnnouncementType = (val: unknown): val is AnnouncementType => val === 'ICON' || val === 'IMAGE';

export type PublishTypeEnum = 'MOBILE' | 'EMAIL' | 'SMS' | 'ALL';

interface PublishType {
  type: PublishTypeEnum;
}

export interface AccessKey {
  id: number;
  key: string;
  company?: Company;
}

export interface CompanyAccessKey extends AccessKey {
  companyId: number;
}

// Benefits

export type RequiresEnrollmentType = string | null;

export const isRequiresEnrollmentType = (val: unknown): val is RequiresEnrollmentType =>
  val === 'YES' || val === 'NO' || val === null;

export interface BenefitLink {
  id: number;
  label: string;
  val: string;
}

export interface BenefitPhone {
  id: number;
  label: string;
  val: string;
}

export interface BenefitCategory {
  id: number;
  title: string;
}

export interface Announcement {
  id: number;
  title: string;
  description: string;
  additionalDescription?: string;
  thumbnail: string;
  image?: string;
  link?: string;
  publishStartDate: string;
  publishEndDate?: string;
  expirationDate: string;
  cta?: string;
  type: AnnouncementType;
  accessKeys: AccessKey[];
  publishTypes: [PublishType];
  timezone: string;
}

export interface AnnouncementInput {
  type: AnnouncementType;
  title: string;
  description: string;
  additionalDescription?: string;
  thumbnail?: AttachmentInput;
  image?: AttachmentInput;
  link?: string;
  publishStartDate: string;
  publishEndDate?: string;
  expirationDate?: string;
  cta?: string;
  accessKeyIds: number[];
  publishTypes: PublishTypeEnum[];
  company?: Company;
  timezone: string;
}

export interface AnnouncementUpdateInput {
  id: number;
  type: AnnouncementType;
  title: string;
  description: string;
  additionalDescription?: string;
  thumbnail?: AttachmentInput;
  image?: AttachmentInput;
  link?: string;
  publishStartDate: string;
  publishEndDate?: string;
  expirationDate?: string;
  cta?: string;
  accessKeyIds: number[];
  publishTypes: PublishTypeEnum[];
  timezone: string;
}

export interface EmployeeBenefit {
  id: number;
  name: string;
  headline: string;
  description?: string;
  image?: string;
  logo?: AttachmentInput;
  link: string;
  links: BenefitLink[];
  phones: BenefitPhone[];
  categories: [BenefitCategory];
  company: Company;
  enrollmentDetails?: string;
  callToAction?: string;
  callToActionSecondary?: string;
  requiresEnrollment: string | boolean | null;
  companyId?: number;
  appointmentTypes: [AppointmentType];
  needTypes: [NeedType];
  careConditions: [CareCondition];
  websiteUrl: string;
  secondWebsiteUrl?: string;
  websiteLabel?: string;
  secondWebsiteLabel?: string;
  phone?: string;
  secondPhone?: string;
  phoneLabel?: string;
  secondPhoneLabel?: string;
}

export interface BenefitInput {
  name: string;
  headline: string;
  image?: AttachmentInput;
  description?: string;
  callToAction?: string;
  callToActionSecondary?: string;
  enrollmentDetails?: string;
  requiresEnrollment: string | boolean | null;
  companyId?: number;
  appointmentTypeIds?: number[];
  needTypeIds?: number[];
  careConditionIds?: number[];
  websiteUrls?: WebsiteUrlInput[];
  phoneNumbers?: PhoneNumberInput[];
}

export interface CreateBenefitInput {
  name: string;
  description?: string;
  logo?: AttachmentInput;
  image?: AttachmentInput;
  companyId: number;
  headline: string;
  callToAction?: string;
  callToActionSecondary?: string;
  enrollmentDetails?: string;
  requiresEnrollment: string | null;
  appointmentTypeIds: string[];
  needTypeIds: string[];
  careConditionIds: string[];
  websiteUrl: string;
  secondWebsiteUrl?: string;
  websiteLabel: string;
  secondWebsiteLabel?: string;
  phone: string;
  secondPhone?: string;
  phoneLabel: string;
  secondPhoneLabel?: string;
}

export interface BenefitUpdateInput {
  id: number;
  name: string;
  description?: string;
  websiteUrl?: string;
  logo?: { type: string; url: string };
  image?: AttachmentInput;
  companyId: number;
  headline: string;
  callToAction?: string;
  callToActionSecondary?: string;
  enrollmentDetails?: string;
  requiresEnrollment: string | boolean | null;
  appointmentTypeIds?: number[];
  needTypeIds?: number[];
  careConditionIds?: number[];
  websiteUrls?: WebsiteUrlInput[];
  phoneNumbers?: PhoneNumberInput[];
}

export interface AppointmentType {
  id: number;
  name?: string | null;
}

export interface NeedTag {
  id: number;
  name: string;
  description?: string;
  default?: boolean;
}

export interface NeedType {
  id: number;
  name: string;
  description?: string;
  iconUrl?: string;
  imageUrl?: string;
  private?: boolean;
  isSingleLocation: boolean;
  tags?: NeedTag[];
}

// care team

export type ContactType = 'MOBILE' | 'EMAIL';

export const isContactType = (val: unknown): val is ContactType => val === 'MOBILE' || val === 'EMAIL';

export type RoleType = 2 | 3;

export const isRoleType = (val: unknown): val is RoleType => val === 2 || val === 3;

export interface CareCondition {
  id: number;
  name: string;
  description?: string | null;
}

export interface CareTeamRole {
  id: number;
  name: string;
  description?: string | null;
}

export interface SimpleStatus {
  status?: string;
}

export interface CareTeamMember {
  id: number | string;
  user?: User;
  careTeamRoles?: CareTeamRole[];
}

export interface EntytyId {
  id: number;
  __typename?: string;
}

export interface Invitation {
  id: number;
  status: InvitationStatus;
  email?: string;
  mobileNo?: string;
  iconUrl?: string;
  careTeamRoleId?: number;
  careTeamId?: number;
  name?: string;
  inviteId?: string;
  inviterId: number;
}

export interface InviteLink {
  role: CareTeamRole;
  link: string;
}

export interface CareTeam {
  id: number;
  name?: string | null;
  patient?: CareTeamMember | null;
  careConditions: CareCondition[];
  teamMembers?: CareTeamMember[] | null;
  invitations?: Invitation[] | null;
  owner?: EntytyId | null;
  location?: Location | null;
  description?: string | null;
  patientName?: string;
  public: boolean;
  iconUrl: string | null;
  patientIconUrl?: string | null;
  company: Company;
  visibility: TeamDiscoverableType;
  relation: string;
  benefitsCovered: boolean;
  inviteLinks: InviteLink[];
}

export interface InvitationContactsInput {
  mobileNo?: string | null;
  email?: string | null;
}

export interface Invitation {
  id: number;
  status: InvitationStatus;
  email?: string;
  mobileNo?: string;
  iconUrl?: string;
  careTeamRoleId?: number;
  careTeamId?: number;
  name?: string;
  inviteId?: string;
  inviterId: number;
}

export interface InvitationInput {
  careTeamRoleId: UserType;
  careTeamId: number;
  contacts: InvitationContactsInput[];
  inviteeName: string;
  iconDataURI?: string | null;
  message?: string;
  contactType?: string;
}

// Event

export type ActivityDurationType = string | boolean | null;

export const isActivityDurationType = (val: unknown): val is ActivityDurationType =>
  val === 'YES' || val === 'NO' || val === null;

export type VisibilityType = 'IC' | 'PUBLIC' | 'PRIVATE';

export const isVisibilityType = (val: unknown): val is VisibilityType =>
  val === 'IC' || val === 'PUBLIC' || val === 'PRIVATE';

export type RecurrenceType = 'NEVER' | 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'WEEKDAY' | 'CUSTOM';

export const recurrenceTypeToStr = (val: RecurrenceType): string =>
  select(val, {
    NEVER: 'Never',
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    WEEKDAY: 'Weekday',
    CUSTOM: 'Custom',
  });

export interface RecurrenceOptionValue {
  type?: RecurrenceType;
  dayOfWeek?: number;
  weekOfMonth?: number;
  dayOfMonth?: number;
  numberOfOccurrences?: number;
  separationCount?: number;
}

export interface CreateEventInput {
  title: string;
  startDate: Date | string;
  endDate: Date | string;
  timezone?: string;
  isAllDay: string | boolean | null;
  description?: string;
  createdFor?: number | null;
  attachment?: AttachmentInput | null;
  location?: LocationInput | null;
  volunteers?: number[] | null;
  visibility: VisibilityType;
  isReminderEnabled?: string | boolean | null;
  recurrence?: RecurrenceOptionValue | null;
}

export enum EventUpdateDeleteOptions {
  THIS = 'THIS',
  THIS_AND_FOLLOWING = 'THIS_AND_FOLLOWING',
  ALL = 'ALL',
}

export interface UpdateEventInput {
  id: number | null;
  title: string;
  startDate: Date | string;
  endDate: Date | string;
  timezone?: string;
  isAllDay: string | boolean | null;
  description?: string;
  createdFor?: number | null;
  attachment?: AttachmentInput | null;
  location?: LocationInput | null;
  volunteers?: number[] | null;
  visibility: VisibilityType;
  isReminderEnabled?: string | boolean | null;
  recurrence?: RecurrenceOptionValue | null;
  type?: EventUpdateDeleteOptions;
}

export enum RSVPStatus {
  ATTENDING = 'ATTENDING',
  DECLINED = 'DECLINED',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  UNSURE = 'UNSURE',
}

export interface Attachment {
  id: number;
  url: string;
  type: AttachmentType;
}

export interface Event {
  id: number;
  title: string;
  startDate: Date;
  endDate: Date;
  timezone: string;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
  channelSid: string | null;
  author: User | null;
  createdFor: User | null;
  careTeamId: number;
  attachment: Attachment | null;
  location: Location | null;
  isReminderEnabled: boolean;
  postId?: number;
  rsvpStatus: RSVPStatus;
  isAllDay: boolean;
  visibility: VisibilityType;
  recurrence?: RecurrenceOptionValue | null;
  recurrenceRootId: number | null;
  __typename?: string;
}

export interface EventsFeed {
  total: number;
  cursor: string | null;
  data: Event[];
}

export interface EventsFileData {
  location_name: string;
  location_address: string;
  location_longitude: string;
  location_latitude: string;
  title: string;
  startDate: string;
  endDate: string;
  timezone: string;
  isReminderEnabled: string;
  isAllDay: string;
  visibility: string;
  description: string;
  image: string;
  recurrence: string;
}

export interface MerchantProgram {
  id: string;
  name: string;
}

export interface MetaTag {
  id: string;
  name: string;
}

export interface ResourceCategory {
  id: number;
  name: string;
  level: number;
  categoryId: number | null;
}

export interface Scrapy {
  title: string;
}

export interface Resource {
  id: string;
  additionalNote: string;
  appDescription: string;
  appImage: Attachment;
  appLink: string;
  appLinkText: string;
  appMerchantName: string;
  appointmentTypes: AppointmentType[];
  company: Company;
  conditions: CareCondition[];
  isApp: boolean;
  isWeb: boolean;
  megaCategories: ResourceCategory[];
  merchantHomePageUrl: string | null;
  merchantName: string;
  merchantPPCLink: string | null;
  merchantPageMetadata: string | null;
  merchantProgram: MerchantProgram;
  merchantStatus: MerchantStatus;
  merchantTrackingMetadata: string | null;
  merchantTrackingUrl: string;
  metaTags: MetaTag[];
  subCategories: ResourceCategory[];
  needTypes: NeedType[];
  topics: ResourceCategory[];
  webAutofill: boolean;
  webDescription: string;
  webImage: Attachment;
  webLink: string;
  webLinkText: string;
  webMerchantName: string;
  stage: ResourceStage;
  status: ResourceStatus;
  scheduledAt: Date | null;
}
export interface ResourceInput {
  additionalNote?: string;
  appDescription?: string;
  appAttachment: AttachmentInput | null;
  appLink: string;
  appLinkText: string;
  appMerchantName: string;
  appointmentTypeIds: number[];
  companyId: number;
  conditionIds: number[];
  isApp: boolean;
  isWeb: boolean;
  megaCategoryIds: number[];
  merchantHomePageUrl?: string | null;
  merchantName: string;
  merchantPPCLink?: string | null;
  merchantPageMetadata?: string | null;
  merchantProgramId: string;
  merchantStatus: MerchantStatus;
  merchantTrackingMetadata?: string | null;
  merchantTrackingUrl?: string;
  metaTagIds: string[];
  subCategoryIds: number[];
  needTypeIds: number[];
  topicIds: number[];
  webAutofill?: boolean;
  webDescription?: string;
  webAttachment: AttachmentInput | null;
  webLink: string;
  webLinkText: string;
  webMerchantName: string;
  stage: ResourceStage;
  status: ResourceStatus;
}

export interface ResourceFeed {
  total: number;
  data: Partial<Resource>[];
}

export interface ResourceFeedResponse {
  resourceFeed: ResourceFeed;
}

export interface ResourceResponse {
  resource: Resource | null;
}

export interface ResourceVariables {
  id: number;
}

export interface ResourceFeedVariables {
  offset?: number;
  limit?: number;
  categoryIds?: number[];
  searchQuery?: string;
  orderBy?: { [key: string]: string }[]
}

export interface UserInput {
  firstName: string;
  lastName: string;
  iconDataURI?: string;
  company: string | null;
  role: string;
}

export interface UserFeed {
  total: number;
  data: Partial<User>[];
}

export interface AdminUserFeed {
  total: number;
  data: Partial<AdminUser>[];
}

export interface UserFeedResponse {
  userFeed: UserFeed;
}

export interface AdminUserFeedResponse {
  adminUserFeed: AdminUserFeed;
}
