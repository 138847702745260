import { FormControl, FormHelperText, MenuItem, Select } from '@material-ui/core';
import { isString } from 'lodash';
import React, { ChangeEvent, PureComponent } from 'react';
import { m, StyleProps, Styles } from 'styles';

interface Props<T> extends StyleProps {
  label?: string;
  required?: boolean;
  items: T[];
  value?: T;
  fullWidth?: boolean;
  placeholder?: string;
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  helperText?: string;
  error?: boolean;
  keyExtractor: (v: T) => string;
  titleExtractor: (v: T) => string;
  onChange?: (v: T) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export class FormSelectField<T> extends PureComponent<Props<T>> {
  private handleChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { items, keyExtractor, onChange } = this.props;
    const { value } = e.target;
    if (!isString(value)) {
      return;
    }
    const item = items.find(itm => keyExtractor(itm) === value);
    if (item && onChange) {
      onChange(item);
    }
  };

  render() {
    const {
      style,
      label,
      required,
      items,
      value,
      error,
      helperText,
      fullWidth,
      variant,
      keyExtractor,
      titleExtractor,
      placeholder,
    } = this.props;

    // eslint-disable-next-line no-console
    return (
      <FormControl
        style={m(styles.container, style)}
        variant={variant}
        required={required}
        error={error}
        fullWidth={fullWidth}
      >
        {!!label && (
          <div>
            <span>{label}</span>
            {required && <span>*</span>}
          </div>
        )}
        <Select
          value={value ? keyExtractor(value) : ''}
          required={required}
          fullWidth={fullWidth}
          placeholder={placeholder}
          onChange={this.handleChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
        >
          {items.map(itm => (
            <MenuItem key={keyExtractor(itm)} value={keyExtractor(itm)}>
              {titleExtractor(itm)}
            </MenuItem>
          ))}
        </Select>
        {!!helperText && <FormHelperText required={required}>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

const styles: Styles = {
  container: {},
};

export type FormSelectFieldProps<T> = Props<T>;
export default FormSelectField;
