import React, { useState } from 'react';
import { isNil, get } from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router';
import { Header } from 'components/Common';
import { routes } from 'screens/consts';
import EditUserCell from 'components/Users/EditUserCell';
import { useMutation, useQuery } from '@apollo/client';
import { AdminUser, AttachmentType, UserInput } from 'core/api';
import { GET_ADMIN_USER } from 'core/apollo/queries/users';
import { ROLES } from 'core/constants';
import { humanizeRole } from 'utils/str';
import { buildAttachmentFromUrl } from 'utils/image';
import { Snackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { UPDATE_ADMIN_USER } from 'core/apollo/mutation/users';

const AdminsEditScreen = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();
  const [snackbar, setSnackbar] = useState<{ severity: Color; message: string }>();

  const { data } = useQuery<{ adminUser: AdminUser }>(GET_ADMIN_USER, {
    variables: {
      id,
    },
  });
  const [updateAdminUser, { loading }] = useMutation(UPDATE_ADMIN_USER);

  const getDefaultValues = () => {
    if (isNil(data)) return {};

    const role = data.adminUser?.role || ROLES.CONTENT_COORDINATOR;

    return {
      firstName: data.adminUser?.firstName || '',
      lastName: data.adminUser?.lastName || '',
      email: data.adminUser?.email || '',
      role: { value: role, name: humanizeRole(role) },
      company: data.adminUser?.company,
      iconDataURI: buildAttachmentFromUrl(AttachmentType.IMAGE, 'image/png', data.adminUser?.iconUrl),
    };
  };

  const handleCancelClick = () => {
    const backRoute = get(location, ['state', 'backRoute']);
    history.push(backRoute ? backRoute : routes.admins.list);
  };

  const handleSubmitClick = async (payload: UserInput) => {
    try {
      await updateAdminUser({
        variables: {
          input: payload,
          id: parseInt(id, 10),
        },
        optimisticResponse: {
          updateAdminUser: {
            id: parseInt(id, 10),
            __typename: 'AdminUser',
            ...data,
          },
        },
      });
      setSnackbar({ severity: 'success', message: 'User updated successfully!' });
      handleCancelClick();
    } catch (e) {
      setSnackbar({ severity: 'error', message: e.message });
    }
  };

  const onSnackbarClose = () => {
    setSnackbar(undefined);
  };

  return (
    <div>
      <Header path={location.pathname} />

      <EditUserCell
        defaultValues={getDefaultValues()}
        title="Edit Admin Pages User"
        onCancel={handleCancelClick}
        onSubmit={handleSubmitClick}
        loading={loading}
      />

      <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={onSnackbarClose}>
        <Alert onClose={onSnackbarClose} severity={snackbar?.severity}>
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminsEditScreen;
