import { Button, Paper } from '@material-ui/core';
import { ButtonProgress } from 'components/Buttons';
import { useSnackbar, View } from 'components/Common';
import {
  CompaniesEditForm,
  CompaniesEditFormData,
  CompaniesEditFormErrors,
  companyToEditFormData,
  getCompaniesEditFormErrors,
  getDefCompaniesEditFormData,
  polishCompaniesEditFormData,
  ChildCompanyList,
  PublicTeamList,
} from 'components/Companies';
import {
  Company,
  UpdateCompanyInput,
  useRemoveCompanyMutation,
  useUpdateCompanyMutation,
  useGetCompanyByCompanyIdQuery,
  useGetCompanyPublicTeamsQuery,
} from 'core/api';
import React, { FC, useEffect, useState } from 'react';
import { colors, m, StyleProps, Styles } from 'styles';
import { errToStr, Log } from 'utils';

const log = Log('screen.DashboardCompaniesList.scenes.Edit');

interface Props extends StyleProps {
  id: number;
  companies?: Company[];
  onGoBack: () => void;
}

export const DashboardCompaniesEditScene: FC<Props> = ({ style, id, companies = [], onGoBack }) => {
  // UI

  const snackbar = useSnackbar();

  // Form

  const [data, setData] = useState<CompaniesEditFormData>(getDefCompaniesEditFormData());
  const [errs, setErrs] = useState<CompaniesEditFormErrors | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);

  const { data: companyInfo = [], error } = useGetCompanyByCompanyIdQuery({ companyId: id });
  const { data: publicTeams = [] } = useGetCompanyPublicTeamsQuery({ companyId: id });

  useEffect(() => (error ? snackbar.err(error) : undefined), [error]);

  useEffect(() => {
    const exists = companies.find(itm => itm.id === id);
    if (exists) {
      log.debug('item loading done, data=', exists);
      setData(companyToEditFormData(exists));
    }
  }, [companies]);

  const updateCompany = useUpdateCompanyMutation();
  const removeCompany = useRemoveCompanyMutation();

  // UI

  // Handlers

  const handleDataChange = (value: CompaniesEditFormData) => {
    setErrs(undefined);
    const modVal = polishCompaniesEditFormData(value);
    setData(modVal);
    log.debug('data changed, value=', modVal);
  };

  const handleSubmitPress = async () => {
    log.debug('handle submit press, data=', data);
    const curErrs = getCompaniesEditFormErrors(data);
    if (curErrs) {
      log.debug('form errors found, errs=', curErrs);
      return setErrs(curErrs);
    }
    const {
      name, type, websiteUrl, companyId, logo, location, background, tagLine, resourceCenterName, teamCreatorId
    } = data;

    if (!name || !type || !logo || !background || !tagLine || !teamCreatorId) {
      return;
    }

    const input: UpdateCompanyInput = {
      id,
      name,
      websiteUrl,
      companyId,
      logo,
      location,
      background,
      tagLine,
      resourceCenterName,
      teamCreatorId
    };
    try {
      log.info('updating company, input=', input);
      setProcessing(true);
      await updateCompany(input);
      setProcessing(false);
      log.info('updating company done, input=', input);
      snackbar.success('The company updated successfully');
      onGoBack();
    } catch (err) {
      setProcessing(false);
      snackbar.err(errToStr(err) || 'Unknown error');
      log.err(err);
    }
  };

  const handleCancelPress = () => {
    log.debug('handle cancel press');
    onGoBack();
  };

  const handleRemovePress = async () => {
    log.debug('handlre remove press');
    if (!confirm('Are you sure you want to remove this company?')) {
      return;
    }
    try {
      log.debug('removing company, id=', id);
      await removeCompany(id);
      log.debug('removing company done, id=', id);
      onGoBack();
    } catch (err) {
      log.err('removing company err=', err);
      alert(`Removing error: ${errToStr(err)}`);
    }
  };

  return (
    <>
      <Paper style={m(styles.container, style)}>
        <CompaniesEditForm data={data} errors={errs} companies={companies} onChange={handleDataChange} />
        <View style={styles.actions} row={true} justifyContent="space-between">
          <View flex="1" row={true} justifyContent="flex-end">
            <Button
              style={{ ...styles.actionBtn, ...styles.label }}
              disabled={processing}
              variant="contained"
              color="secondary"
              onClick={handleRemovePress}
            >
              Remove
            </Button>
            <Button
              style={styles.actionBtn}
              disabled={processing}
              variant="contained"
              color="default"
              onClick={handleCancelPress}
            >
              Cancel
            </Button>
            <Button
              style={styles.actionBtn}
              variant="contained"
              color="primary"
              disabled={processing}
              onClick={handleSubmitPress}
            >
              {!processing ? `Save` : <ButtonProgress />}
            </Button>
          </View>
        </View>
      </Paper>

      <ChildCompanyList id={id} companies={companyInfo} />
      <PublicTeamList id={id} publicTeams={publicTeams} />
    </>
  );
};

const styles: Styles = {
  container: {
    padding: 20,
  },
  row: {
    marginTop: 6,
    marginBottom: 6,
  },
  actions: {
    marginTop: 20,
  },
  actionBtn: {
    marginLeft: 6,
    width: 100,
  },
  label: {
    color: colors.white,
  },
};

export type DashboardCompaniesEditSceneProps = Props;
export default DashboardCompaniesEditScene;
