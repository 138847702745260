export enum ResourceStatus {
  SUBMITTED = 'SUBMITTED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum MerchantStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  LOW_FUNDS = 'LOW_FUNDS',
  TEMPORARILY_OFFLINE = 'TEMPORARILY_OFFLINE',
  CLOSED = 'CLOSED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum ResourceStage {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  SCHEDULED = 'SCHEDULED',
}
