import { RecurrentPeriod } from './types';
import { isNil, isNull } from 'lodash';
import { format, getDay, getWeekOfMonth, setDay } from 'date-fns';

export interface RecurrenceOptionValue {
  type?: RecurrentPeriod;
  dayOfWeek?: number;
  weekOfMonth?: number;
  dayOfMonth?: number;
  numberOfOccurrences?: number;
  separationCount?: number;
}

export const getTextFromWeekNumber = (weekNumber: number) => {
  switch (weekNumber) {
    case 1:
      return '1st';
    case 2:
      return '2nd';
    case 3:
      return '3rd';
    case 4:
      return '4th';
    default:
      return 'last';
  }
};

export const recurrencePeriodTypeToText = (props: RecurrenceOptionValue | undefined): string => {
  if (isNil(props)) return 'Never';

  const { type, weekOfMonth = 1, dayOfWeek = 1 } = props;

  if (isNull(weekOfMonth) || isNull(dayOfWeek)) return 'Never';

  switch (type) {
    case RecurrentPeriod.CUSTOM:
      return 'Custom...';
    case RecurrentPeriod.DAILY:
      return 'Daily';
    case RecurrentPeriod.WEEKLY:
      return `Weekly on ${format(setDay(new Date(), dayOfWeek), 'E')}`;
    case RecurrentPeriod.MONTHLY:
      return `Monthly on the ${getTextFromWeekNumber(weekOfMonth)} ${format(setDay(new Date(), dayOfWeek), 'E')}`;
    case RecurrentPeriod.EVERY_WEEKDAY:
      return 'Every weekday (Mon to Fri)';
    default:
      return 'Never';
  }
};

export const getRecurrenceOptions = (date: Date | string | number | undefined) => {
  if (!date) return [];

  const currentDate = new Date(date);
  const currentDay = getDay(currentDate);
  const currentWeekOfMonth = getWeekOfMonth(currentDate);

  return [
    {
      title: recurrencePeriodTypeToText({
        type: RecurrentPeriod.NEVER,
      }),
      value: {
        type: RecurrentPeriod.NEVER,
      },
    },
    {
      title: recurrencePeriodTypeToText({
        type: RecurrentPeriod.DAILY,
      }),
      value: {
        type: RecurrentPeriod.DAILY,
      },
    },
    {
      title: recurrencePeriodTypeToText({
        type: RecurrentPeriod.WEEKLY,
        dayOfWeek: currentDay,
      }),
      value: {
        type: RecurrentPeriod.WEEKLY,
        dayOfWeek: currentDay, // Day of week
      },
    },
    {
      title: recurrencePeriodTypeToText({
        type: RecurrentPeriod.MONTHLY,
        dayOfWeek: currentDay,
        weekOfMonth: currentWeekOfMonth,
      }),
      value: {
        type: RecurrentPeriod.MONTHLY,
        dayOfWeek: currentDay, // day of week
        weekOfMonth: currentWeekOfMonth, // week number
      },
    },
    {
      title: recurrencePeriodTypeToText({
        type: RecurrentPeriod.EVERY_WEEKDAY,
      }),
      value: {
        type: RecurrentPeriod.EVERY_WEEKDAY,
      },
    },
  ];
};

export const getRecurrenceOptionsForImport = (date: Date | string | number | undefined, type: string | undefined) => {
  if (!date) return [];

  const currentDate = new Date(date);
  const currentDay = getDay(currentDate);
  const currentWeekOfMonth = getWeekOfMonth(currentDate);

  switch (type) {
    case RecurrentPeriod.NEVER:
      return { type: RecurrentPeriod.NEVER };
    case RecurrentPeriod.DAILY:
      return { type: RecurrentPeriod.DAILY };
    case RecurrentPeriod.WEEKLY:
      return {
        type: RecurrentPeriod.WEEKLY,
        dayOfWeek: currentDay, // Day of week
      };
    case RecurrentPeriod.MONTHLY:
      return {
        type: RecurrentPeriod.MONTHLY,
        dayOfWeek: currentDay, // day of week
        weekOfMonth: currentWeekOfMonth, // week number
      };
    case RecurrentPeriod.EVERY_WEEKDAY:
      return {
        type: RecurrentPeriod.EVERY_WEEKDAY,
      };
    default:
      return { type: RecurrentPeriod.NEVER };
  }
};
