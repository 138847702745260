import React, { FC, SyntheticEvent } from 'react';
import { StyleProps, Styles, m } from 'styles';
import { ActivityDurationType, isActivityDurationType } from 'core/api';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

interface Props extends StyleProps {
  label?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  value?: ActivityDurationType;
  helperText?: string;
  onChange?: (v: ActivityDurationType) => void;
}

export const FormActivityDurationField: FC<Props> = ({
  style,
  label,
  required,
  error,
  value,
  helperText,
  fullWidth,
  onChange,
}) => {
  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value: newVal } = e.currentTarget;
    if (isActivityDurationType(newVal) && onChange) {
      onChange(newVal);
    }
  };
  return (
    <FormControl
      style={m(styles.container, style)}
      component="fieldset"
      required={required}
      fullWidth={fullWidth}
      error={error}
    >
      {!!label && <FormLabel>{label}</FormLabel>}
      <RadioGroup row value={value} onChange={handleChange}>
        <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
        <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
      </RadioGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const styles: Styles = {
  container: {},
};

export type FormActivityDurationFieldProps = Props;
export default FormActivityDurationField;
