import React, { FC, ReactNode } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';

interface InjectedProps {
  title: string;
  prefix?: ReactNode;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    fontFamily: 'Roboto',
    margin: 0,
    color: '#15374E',
  },
  icon: {
    marginRight: 15,
    color: '#00737E',
  },
}));

const SectionTitle: FC<InjectedProps> = ({ title, prefix = undefined }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {prefix ? prefix : <SettingsIcon className={classes.icon} />}
      <h2 className={classes.title}>{title}</h2>
    </div>
  );
};

export default SectionTitle;
