import React, { FC, useState } from 'react';
import { StyleProps, Styles, m } from 'styles';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { isString } from 'lodash';
import { LocationInput, CreateEventInput } from 'core/api';

interface Props extends StyleProps {
  required?: boolean;
  label?: string;
  value?: LocationInput | null;
  onChange?: (v: LocationInput) => void;
  setErrs: (v: Partial<Record<keyof CreateEventInput, string>>) => void;
}
const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#E8EBEE',
        },
        '&$focused $notchedOutline': {
          borderColor: '#008B98',
          borderWidth: 1,
        },
        '&&& $input': {
          padding: 3,
        },
      },
    },
  },
});

export const FormLocationSearchField: FC<Props> = ({ style, label, required, setErrs, onChange }) => {
  const [address, setAddress] = useState('');

  const handleChange = (address: string) => {
    setAddress(address);
  };

  const handleSelect = (address: string) => {
    if (!isString(address)) {
      return;
    }

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        if (onChange) {
          onChange({
            name: address.split(/, (.+)/)[0],
            address: address,
            longitude: latLng.lng,
            latitude: latLng.lat,
          });
        }
      })
      .catch(error => setErrs({ location: error }));
  };

  return (
    <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div>
          {!!label && (
            <div>
              <span>{label}</span>
              {required && <span>*</span>}
            </div>
          )}
          <Autocomplete
            options={suggestions.map(item => item)}
            getOptionLabel={option => option.description}
            style={m(styles.container, style)}
            renderInput={params => (
              <MuiThemeProvider theme={theme}>
                <TextField
                  {...params}
                  {...getInputProps({
                    placeholder: 'Add Your Location',
                  })}
                  variant="outlined"
                />
              </MuiThemeProvider>
            )}
            renderOption={suggestion => (
              <div {...getSuggestionItemProps(suggestion)}>
                <span>{suggestion.description}</span>
              </div>
            )}
          />
        </div>
      )}
    </PlacesAutocomplete>
  );
};

const styles: Styles = {
  container: {},
};

export type FormLocationSearchFieldProps = Props;
export default FormLocationSearchField;
