import React, { FC } from 'react';
import placeholder from '../../../assets/placeholder.png';
import { useLocation, useHistory } from 'react-router';
import { Header } from 'components/Common';
import SectionCard from 'components/SectionCard';
import Avatar from '@material-ui/core/Avatar';
import { isNil, isEqual, get } from 'lodash';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid } from '@material-ui/core';
import { humanizeRole } from 'utils/str';
import { routes } from 'screens/consts';
import MenuButton, { MenuButtonOption } from 'components/MenuButton';
import { AdminUser, User } from 'core/api';
import { useAuth } from '../../../hooks/useAuth';

export enum UserProfileMenuType {
  EDIT,
  REMOVE,
}

interface InjectedProps {
  user: User | AdminUser;
  onMenuClick?: (option: MenuButtonOption) => void;
}

const useStyles = makeStyles(() => ({
  avatar: {
    width: 100,
    height: 100,
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    marginBottom: 0,
    color: '#15374E',
    fontWeight: 700,
    fontSize: 28,
    marginTop: 0,
  },
  email: {
    marginTop: 4,
    fontSize: 15,
    fontWeight: 500,
    color: '#00737E',
  },
  metaContainer: {
    marginLeft: 24,
  },
  label: {
    fontSize: 15,
    fontWeight: 500,
    color: '#8A9BA6',
  },
  value: {
    fontSize: 15,
    fontWeight: 500,
    color: '#15374E',
  },
  link: {
    color: '#00737E',
    cursor: 'pointer',
  },
  item: {
    marginTop: 32,
  },
}));

const UserProfileCell: FC<InjectedProps> = ({ user, onMenuClick }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { user: currentUser } = useAuth();

  const handlePasswordChangeClick = () => {
    history.push(routes.admins.change_password, {
      backRoute: `${routes.admins.profile}/${user.id}`,
    });
  };

  if (isNil(user)) return null;

  return (
    <div>
      <Header path={location.pathname} />

      <SectionCard>
        <Grid container>
          <Grid item xs={11}>
            <div className={classes.userContainer}>
              <Avatar className={classes.avatar} alt={user.firstName} src={user.iconUrl || placeholder} />
              <div className={classes.metaContainer}>
                <h2 className={classes.name}>
                  {user.firstName} {user.lastName}
                </h2>
                <span className={classes.email}>{user.email}</span>
              </div>
            </div>

            <div>
              <Grid container>
                <Grid item xs={12} container className={classes.item}>
                  <Grid item xs={12} md={3}>
                    <span className={classes.label}>Company</span>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <span className={classes.value}>{get(user, ['company', 'name'], get(user, 'company'))}</span>
                  </Grid>
                </Grid>
                {!!user.role && (
                  <Grid item xs={12} container className={classes.item}>
                    <Grid item xs={12} md={3}>
                      <span className={classes.label}>User Role</span>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <span className={classes.value}>{humanizeRole(user.role)}</span>
                    </Grid>
                  </Grid>
                )}
                {isEqual(currentUser, user) && (
                  <Grid item xs={12} container className={classes.item}>
                    <Grid item xs={12} md={3}>
                      <span className={classes.label}>Password</span>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <span
                        role="button"
                        onClick={handlePasswordChangeClick}
                        className={`${classes.value} ${classes.link}`}
                      >
                        Change Password
                      </span>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
          {!!onMenuClick && (
            <Grid item xs={1}>
              <MenuButton
                options={[
                  { type: UserProfileMenuType.EDIT, label: 'Edit' },
                  ...(isEqual(currentUser, user) ? [] : [{ type: UserProfileMenuType.REMOVE, label: 'Remove' }]),
                ]}
                onClick={onMenuClick}
              />
            </Grid>
          )}
        </Grid>
      </SectionCard>
    </div>
  );
};

export default UserProfileCell;
