import React, { FC } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { StyleProps } from 'styles';

import DashboardAnnsCreateScene from './scenes/Create';
import DashboardAnnsEditScene from './scenes/Edit';
import { PageTitle, Header } from 'components/Common';
import { useGetCompaniesQuery } from 'core/api';

type Props = StyleProps;

export const DashboardAnnsEditScreen: FC<Props> = () => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string | undefined }>();

  const { data: companies } = useGetCompaniesQuery();

  const title = id === 'new' ? 'New Announcement' : 'Edit Announcement';

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <PageTitle title={title} />
      <Header path={location.pathname} />
      {id === 'new' ? (
        <DashboardAnnsCreateScene companies={companies || []} />
      ) : (
        <DashboardAnnsEditScene id={parseInt(id || '', 10)} companies={companies || []} onGoBack={handleGoBack} />
      )}
    </>
  );
};

export type DashboardAnnsEditScreenProps = Props;
export default DashboardAnnsEditScreen;
