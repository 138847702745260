import { AdminUser } from 'core/api';
import { ROLES } from 'core/constants';

export const isSuperAdmin = (user: AdminUser | null) => {
  return user?.role === ROLES.SUPER_ADMIN;
};

export const isContentPartner = (user: AdminUser | null) => {
  return user?.role === ROLES.CONTENT_PARTNER;
};

export const isContentCoordinator = (user: AdminUser | null) => {
  return user?.role === ROLES.CONTENT_COORDINATOR;
};
