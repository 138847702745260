import React, { FC, useEffect, useRef, useState } from 'react';
import { isNil } from 'lodash';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import { Dialog } from '@material-ui/core';
import ReactCrop, { Crop, PixelCrop, ReactCropProps } from 'react-image-crop';
import { canvasPreview, centerAspectCrop } from 'utils/image';
import { useDebouncedEffect } from '../../hooks/useDebounceEffect';

interface InjectedProps {
  source?: string;
  onSubmit: (canvas: HTMLCanvasElement) => void;
  onCancel: () => void;
  aspect?: number;
  cropProps?: Partial<ReactCropProps>;
  initialCrop?: Crop;
  cropSize: { width: number; height: number };
}

const ImageCropPreviewDialog: FC<InjectedProps> = ({
  source,
  aspect,
  cropProps,
  onSubmit,
  onCancel,
  initialCrop,
  cropSize,
}) => {
  const [crop, setCrop] = useState<Crop | undefined>(initialCrop);
  const [preview, setPreview] = useState<PixelCrop>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setCrop(initialCrop);
  }, [initialCrop]);

  useDebouncedEffect(
    () => {
      if (preview?.width && preview?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, preview);
      }
    },
    [preview],
    100,
  );
  const handleSubmit = () => {
    if (isNil(previewCanvasRef.current)) return;

    onSubmit(previewCanvasRef.current);
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      const crop = centerAspectCrop(width, height, aspect, cropSize.width, cropSize.height);
      setCrop(crop);
      setPreview(crop as PixelCrop);
    }

    return null;
  };

  if (isNil(source)) return null;

  return (
    <Dialog fullScreen onClose={onCancel} aria-labelledby="customized-dialog-title" open={!isNil(source)}>
      <DialogTitle id="customized-dialog-title">Adjust image</DialogTitle>
      <DialogContent dividers>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <div>
            <p style={{ fontWeight: 500, marginBottom: 8, marginTop: 0 }}>Image:</p>
            <ReactCrop {...cropProps} crop={crop} onChange={pixelCrop => setCrop(pixelCrop)} onComplete={setPreview}>
              <img ref={imgRef} alt="Crop me" src={source} onLoad={handleImageLoad} />
            </ReactCrop>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 24 }}>
            <p style={{ fontWeight: 500, marginBottom: 8, marginTop: 0 }}>Preview:</p>
            {isNil(preview) && (
              <div
                style={{
                  width: cropSize.width * 0.5,
                  height: cropSize.height * 0.5,
                  backgroundColor: '#f2f2f2',
                  borderRadius: cropProps?.circularCrop ? cropSize.width : 0,
                }}
              />
            )}
            {!isNil(preview) && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  borderRadius: cropProps?.circularCrop ? cropSize.width : 0,
                  border: '1px solid #f2f2f2',
                  objectFit: 'contain',
                  width: cropSize.width,
                  height: cropSize.height,
                }}
              />
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text">
          Cancel
        </Button>
        <Button autoFocus onClick={handleSubmit} variant="contained" color="primary">
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropPreviewDialog;
