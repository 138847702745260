import { Header, PageTitle } from 'components/Common';
import { Event } from 'core/api';
import React, { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { StyleProps } from 'styles';

import CreateScene from './scenes/Create';
import EditScene from './scenes/Edit';

type Props = StyleProps;

interface stateType {
  publicTeamId: number;
  data: Event;
}

export const DashboardEventEditScreen: FC<Props> = () => {
  const location = useLocation();
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string | undefined }>();
  const { state } = useLocation<stateType>();

  const { publicTeamId } = state;

  const title = eventId === 'create-event' ? 'New Event' : 'Edit Event';

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <PageTitle title={title} />
      <Header path={location.pathname} />
      {eventId === 'create-event' ? (
        <CreateScene publicTeamId={publicTeamId} />
      ) : (
        <EditScene id={parseInt(eventId || '', 10)} publicTeamId={publicTeamId} onGoBack={handleGoBack} />
      )}
    </>
  );
};

export type DashboardEventEditScreenProps = Props;
export default DashboardEventEditScreen;
