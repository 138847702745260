import React, { FC } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { StyleProps } from 'styles';

import CreateScene from './scenes/Create';
import EditScene from './scenes/Edit';
import { PageTitle, Header } from 'components/Common';
import { useGetCompaniesQuery } from 'core/api';
import { routes } from '../../../../screens/consts';

type Props = StyleProps;

export const DashboardCompaniesEditScreen: FC<Props> = () => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string | undefined }>();

  const title = id === 'new' ? 'New Company' : 'Edit Company';

  const { data: companies } = useGetCompaniesQuery();

  const handleGoBack = () => {
    history.push(`${routes.companies.default}`);
  };

  return (
    <>
      <PageTitle title={title} />
      <Header path={location.pathname} />
      {id === 'new' ? (
        <CreateScene companies={companies} />
      ) : (
        <EditScene id={parseInt(id || '', 10)} companies={companies} onGoBack={handleGoBack} />
      )}
    </>
  );
};

export type DashboardCompaniesEditScreenProps = Props;
export default DashboardCompaniesEditScreen;
