import { gql, useMutation, useQuery } from '@apollo/client';
import { errToStr } from 'utils';

import { fields } from './fields';
import { Announcement, AnnouncementInput, AnnouncementUpdateInput } from './types';
import { graphQlErrorsToStr } from './utils';

interface AnnsQueryVars {
  expireAt?: number | null;
}

export const useAnnsQuery = (variables?: AnnsQueryVars) => {
  const { data, loading, error } = useQuery<{ announcements: Announcement[] }, AnnsQueryVars>(
    gql`query announcements($expireAt: Date) {
    announcements(expireAt: $expireAt) {
      ${fields.announcement}
    }
  }`,
    { variables, fetchPolicy: 'cache-and-network' },
  );
  return { data: data ? data.announcements : undefined, loading, error: error ? errToStr(error) : undefined };
};

const annsRefetchQuery = {
  query: gql`query announcements($expireAt: Date) {
  announcements(expireAt: $expireAt) {
    ${fields.announcement}
  }
}`,
  variables: { expireAt: null },
};

interface AnnQueryVars {
  id: number;
}

export const useAnnQuery = (variables?: AnnQueryVars) => {
  const { data, loading, error } = useQuery<{ announcement: Announcement }, AnnQueryVars>(
    gql`query announcement($id: Int!) {
  announcement(id: $id) {
    ${fields.announcement}
  }
}`,
    { variables, fetchPolicy: 'cache-and-network' },
  );
  return {
    data: data && data.announcement ? data.announcement : undefined,
    loading,
    error: error ? errToStr(error) : undefined,
  };
};

export const useCreateAnnsMutation = () => {
  const [mutation] = useMutation<{ createAnnouncement: Announcement }, { input: AnnouncementInput }>(
    gql`mutation createAnnouncement($input: AnnouncementInput!){
    createAnnouncement(input: $input){
      ${fields.announcement}
    }
  }`,
    { refetchQueries: [annsRefetchQuery] },
  );
  return async (input: AnnouncementInput): Promise<Announcement> => {
    const { data, errors } = await mutation({ variables: { input } });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    }
    if (data && data.createAnnouncement) {
      return data.createAnnouncement;
    } else {
      throw new Error('Response is empty');
    }
  };
};

export const useRemoveAnnsMutation = () => {
  const [mutation] = useMutation<{ deleteAnnouncement: number }, { id: number }>(
    gql`
      mutation deleteAnnouncement($id: Int!) {
        deleteAnnouncement(id: $id)
      }
    `,
    { refetchQueries: [annsRefetchQuery] },
  );
  return async (id: number): Promise<void> => {
    const { errors } = await mutation({ variables: { id } });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    } else {
      return;
    }
  };
};

export const useUpdateAnnsMutation = () => {
  const [mutation] = useMutation<{ updateAnnouncement: Announcement }, { input: AnnouncementUpdateInput }>(
    gql`mutation updateAnnouncement($input: AnnouncementUpdateInput!){
    updateAnnouncement(input: $input){
      ${fields.announcement}
    }
  }`,
    { refetchQueries: [annsRefetchQuery] },
  );
  return async (input: AnnouncementUpdateInput): Promise<Announcement> => {
    const { data, errors } = await mutation({ variables: { input } });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    }
    if (data && data.updateAnnouncement) {
      return data.updateAnnouncement;
    } else {
      throw new Error('Response is empty');
    }
  };
};

export const sortAnnsByStartDateFn = (a: Announcement) => {
  return -new Date(a.publishStartDate).getTime();
};
