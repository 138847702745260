import { createMuiTheme } from '@material-ui/core/styles';

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#00737E',
      light: '#c8ffff',
      dark: '#63b7a9',
    },
    secondary: {
      main: '#FC7473',
    },
    text: {
      primary: '#043750',
      secondary: '#B6C3CA',
    },
    error: {
      // main: red.A400,
      main: '#DD4A4A',
    },
    background: {
      default: '#F7F7F7',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        margin: '8px 0px',
      },
    },
    MuiTableHead: {
      root: {
        borderBottom: '1px solid rgba(234,234,235,0.5)',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '0px solid rgba(234,234,235,0.5)',
        borderSpacing: '0 5px !important',
      },
    },
    MuiButton: {
      root: {
        fontSize: 14,
        textTransform: 'capitalize',
      },
      contained: {
        // textTransform: 'capitalize',
        // paddingLeft: 30,
        // paddingRight: 30,
        // borderRadius: 19,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'rgba(0, 139, 152, 0.1)',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#E8EBEE',
        },
        '&$focused $notchedOutline': {
          borderColor: '#008B98',
          borderWidth: 1,
        },
      },
      input: {
        padding: 11,
      },
    },
    MuiTableSortLabel: {
      root: {
        '& $icon': {
          fontSize: 24,
        },
        '&$active': {
          '&& $icon': {
            color: '#15374E',
          },
        },
      },
    },
  },
});
