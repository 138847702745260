/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { StyleProps } from 'styles';
import { CSVReader } from 'react-papaparse';
import { every, isNil } from 'lodash';
import { EventsFileData } from 'core/api';

type Props = StyleProps & StylesOpt;
type EventsData = Partial<EventsFileData>;

interface StylesOpt {
  setData: (value: EventsData[]) => void;
  setErrs: (value: string | undefined) => void;
}

const polishedTitles = (titles: string[]) => {
  const arr: string[] = [];
  titles.forEach(item => {
    if (item.includes('location')) {
      if (item.includes('name')) {
        arr.push('location_name');
      } else if (item.includes('address')) {
        arr.push('location_address');
      } else if (item.includes('longitude')) {
        arr.push('location_longitude');
      } else if (item.includes('latitude')) {
        arr.push('location_latitude');
      }
    } else {
      arr.push(item);
    }
  });
  return arr;
};

const getPolishedData = (data: { data: string[]; error: [] }[]) => {
  const polishedData: EventsData[] = [];
  const titles = polishedTitles(data[0].data);
  data.slice(1).forEach(item => {
    const { data } = item;
    if (every(data, v => v === '' || isNil(v))) return;
    const pushedItem: { [id: string]: any } = {};
    titles.forEach((itm, index) => {
      pushedItem[itm] = data[index];
    });
    polishedData.push(pushedItem);
  });
  return polishedData;
};

export const CSVFileReader: FC<Props> = ({ setData, setErrs }) => {
  const handleOnDrop = (data: { data: string[]; error: [] }[]) => {
    const polishedData = getPolishedData(data);
    setData(polishedData);
    setErrs(undefined);
  };

  const handleOnError = (err: string) => {
    setErrs(err);
  };

  const handleOnRemoveFile = () => {
    setData([]);
    setErrs(undefined);
  };

  return (
    <>
      <CSVReader
        onDrop={handleOnDrop}
        onError={handleOnError}
        addRemoveButton
        onRemoveFile={handleOnRemoveFile}
        style={{ dropFile: { width: '100%' } }}
      >
        <span>Drop CSV file here or click to upload.</span>
      </CSVReader>
    </>
  );
};

export type CSVFileReaderProps = Props;
export default CSVFileReader;
