import React, {FC, useRef} from 'react';
import { get, size, isArray } from 'lodash';
import { Controller, RegisterOptions, Control } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown } from '@material-ui/icons';
import Select, { ActionMeta, components, OptionProps } from 'react-select';
import RadioIndicator from 'components/RadioIndicator';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

interface InjectedProps {
  name: string;
  defaultValue?: any;
  label?: string;
  optionLabelKey?: string;
  renderLabel?: (option: any) => void;
  optionValueKey?: string;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  placeholder?: string;
  options?: Array<any>;
  topIndent?: boolean;
  selectProps?: any;
  helperText?: string;
  control: Control<any>;
  disabled?: boolean;
  hidden?: boolean;
  showAllOption?: boolean;
}

const useStyles = makeStyles(() => ({
  control: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: 8,
    fontWeight: 500,
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#15374E',
  },
  asterisk: {
    color: '#DD4A4A',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 12,
    color: '#DD4A4A',
    marginTop: 8,
  },
  helperText: {
    fontSize: 12,
    color: '#8A9BA6',
    marginTop: 8,
  },
}));
const selectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 48,
      borderColor: state.isFocused ? '#008B98' : '#E8EBEE',
      fontSize: '1rem',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#008B98',
        cursor: 'pointer',
      },
    };
  },
  singleValue: provided => ({
    ...provided,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: '#043750',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  valueContainer: provided => ({
    ...provided,
    paddingLeft: 11,
    paddingBottom: 10.5,
    paddingTop: 10.5,
    paddingRight: 32,
  }),
  input: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      paddingLeft: 14,
      paddingTop: 14,
      paddingBottom: 14,
      backgroundColor: state.isSelected ? '#EBF4F5' : '#fff',
      color: '#043750',
      '&:hover': {
        backgroundColor: '#EBF4F5',
        cursor: 'pointer',
      },
    };
  },
};
const selectErrorStyles = {
  control: provided => {
    return {
      ...provided,
      borderColor: '#DD4A4A',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#DD4A4A',
        cursor: 'pointer',
      },
    };
  },
};

const DropdownIndicator = () => {
  return <ArrowDropDown style={{ color: 'rgba(0, 0, 0, 0.54)', marginRight: 7 }} />;
};

const MultiOption = (props: OptionProps<any>) => {
  const { isSelected, children } = props;
  const classes = useStyles();

  return (
    <components.Option {...props}>
      <div className={classes.row}>
        <RadioIndicator style={{ marginRight: 6 }} selected={isSelected} size={16} /> {children}
      </div>
    </components.Option>
  );
};

export const SELECT_ALL_ITEM = -1;

const RHSelect: FC<InjectedProps> = ({
  showAllOption = false,
  name,
  control,
  defaultValue = 0,
  label = '',
  rules,
  placeholder,
  options = [],
  topIndent,
  selectProps,
  helperText,
  optionLabelKey = 'name',
  optionValueKey = 'id',
  disabled,
  hidden,
  renderLabel,
}) => {
  const previousValues = useRef<any>();
  const classes = useStyles();

  const isMulti = (selectProps?.isMulti as unknown) as boolean;
  const customProps = isMulti
    ? {
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
      }
    : {};

  const getOptionLabel = option => {
    if (renderLabel) return renderLabel(option);

    return get(option, optionLabelKey) || get(option, 'label'); // Just last resort
  }
  const getOptionValue = option => get(option, optionValueKey) || get(option, 'value');

  const handleChange = (cb: (...e: any[]) => void) => (newValue: any, meta: ActionMeta<any>) => {
    let value = newValue;

    if (isArray(newValue) && size(newValue) > 1 && showAllOption) {
      // If we have no ALL option in previous result, but it's appear in NEW result then ALL pressed.
      const isAllPressed =
        (previousValues.current || []).some(v => v[optionValueKey || 'value'] !== SELECT_ALL_ITEM) &&
        newValue.some(v => v[optionValueKey || 'value'] === SELECT_ALL_ITEM);


      // Make sure to remove ALL if not ALL selected.
      value = isAllPressed
        ? newValue.filter(i => i[optionValueKey || 'value'] === SELECT_ALL_ITEM)
        : newValue.filter(i => i[optionValueKey || 'value'] !== SELECT_ALL_ITEM)
    }

    cb(value);
    selectProps?.onSelect && selectProps?.onSelect(value, meta);
    previousValues.current = value;
  };

  const Component = selectProps?.isAsync ? AsyncSelect : selectProps?.isCreatable ? CreatableSelect : Select;

  if (hidden)
    return (
      <Controller rules={rules} name={name} control={control} defaultValue={defaultValue} render={() => <div />} />
    );

  const modifiedOptions = (options) => {
    const opts = options.slice();
    if (showAllOption) {
      // options.s
      opts.unshift({
        [optionLabelKey || 'label']: 'All (assign to all)',
        [optionValueKey || 'value']: SELECT_ALL_ITEM
      })
    }

    return opts;
  };

  return (
    <div style={{ marginTop: topIndent ? 25 : 0 }} className={classes.control}>
      {!!label && (
        <label className={classes.label} htmlFor={name}>
          {label}
          {rules?.required && <span className={classes.asterisk}>*</span>}
        </label>
      )}
      <Controller
        rules={rules}
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Component
              isDisabled={disabled}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              {...customProps}
              {...selectProps}
              id={name}
              placeholder={placeholder}
              components={{
                DropdownIndicator,
                ...(!isMulti ? {} : { Option: MultiOption }),
              }}
              styles={{ ...selectStyles, ...(error ? selectErrorStyles : {}) }}
              value={value}
              options={modifiedOptions(options)}
              onChange={handleChange(onChange)}
            />

            {!!error && <span className={classes.errorText}>{error.message}</span>}
            {!!helperText && <span className={classes.helperText}>{helperText}</span>}
          </>
        )}
      />
    </div>
  );
};

export default RHSelect;
