import moment from 'moment';
import momentTimezone from 'moment-timezone';

import { requiredMsg } from 'utils';

import { EventEditFormData, EventEditFormErrors } from './types';
import {AttachmentType, Event} from 'core/api';
import { omit } from 'lodash';

export const getDefEventEditFormData = (): EventEditFormData => ({
  visibility: 'PUBLIC',
  isAllDay: 'NO',
  startDate: moment().add(1, 'hour').startOf('hour').format('YYYY-MM-DDTHH:mm'),
  endDate: moment().add(2, 'hour').startOf('hour').format('YYYY-MM-DDTHH:mm'),
  timezone: momentTimezone.tz.guess(),
  isReminderEnabled: 'NO',
  recurrence: { type: 'NEVER' },
});

export const polishActivityFormData = (value: EventEditFormData): EventEditFormData => {
  let modVal = value;
  if (modVal.title && modVal.title.length > 75) {
    modVal = { ...modVal, title: modVal.title.substring(0, 75) };
  }
  if (modVal.isAllDay === 'YES') {
    modVal = {
      ...modVal,
      startDate: moment(modVal.startDate).startOf('day').format('YYYY-MM-DDTHH:mm'),
      endDate: moment(modVal.endDate).endOf('day').format('YYYY-MM-DDTHH:mm'),
    };
  }
  // description = 300 character
  if (modVal.description && modVal.description.length > 300) {
    modVal = { ...modVal, description: modVal.description.substring(0, 300) };
  }

  return modVal;
};

export const getEventFormErrors = (data: EventEditFormData): EventEditFormErrors | undefined => {
  const { title, startDate, endDate, visibility } = data;
  if (!title) {
    return { title: requiredMsg('Title') };
  }
  if (!startDate) {
    return { startDate: requiredMsg('Start date') };
  }
  if (!endDate) {
    return { endDate: requiredMsg('End date') };
  }
  if (!visibility) {
    return { visibility: requiredMsg('Visibility') };
  }
  return undefined;
};

export const eventToEditFormData = ({
  title,
  startDate,
  endDate,
  location,
  timezone,
  isAllDay,
  description,
  attachment,
  visibility,
  isReminderEnabled,
  recurrence,
}: Event): EventEditFormData => ({
  title,
  startDate: moment(startDate).format('YYYY-MM-DDTHH:mm'),
  endDate: moment(endDate).format('YYYY-MM-DDTHH:mm'),
  location: location?.address
    ? {
        name: location?.name,
        address: location?.address,
        longitude: location?.longitude,
        latitude: location?.latitude,
      }
    : undefined,
  timezone,
  isAllDay: isAllDay ? 'YES' : 'NO',
  description,
  attachment: attachment
    ? {
        type: AttachmentType.IMAGE,
        url: attachment.url,
      }
    : undefined,
  visibility,
  isReminderEnabled: isReminderEnabled ? 'YES' : 'NO',
  recurrence: omit(recurrence, '__typename') || undefined,
});
