import React, { useState } from 'react';
import { isNil } from 'lodash';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { Button } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAuth } from '../../hooks/useAuth';
import { routes } from 'screens/consts';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12,
  },
  name: {
    color: '#15374E',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    lineHeight: '16px',
  },
  email: {
    fontSize: 12,
    color: '#8A9BA6',
    fontWeight: 400,
    textAlign: 'left',
    lineHeight: '14px',
  },
}));

const AccountButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const { user, logout } = useAuth();
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    history.push(`${routes.admins.profile}/${user?.id}`);
    handleClose();
  };

  const handleLogoutClick = () => {
    logout();
    handleClose();
  };

  if (isNil(user)) return null;

  return (
    <>
      <Button
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disableElevation
        disableRipple
        variant="text"
      >
        <Avatar alt={user.firstName} src={user.iconUrl || undefined} />
        <div className={classes.content}>
          <span className={classes.name}>
            {user.firstName} {user.lastName}
          </span>
          <span className={classes.email}>{user.email}</span>
        </div>
      </Button>

      <Menu
        variant="menu"
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogoutClick}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountButton;
