import { PageTitle, Header } from 'components/Common';
import { useLocation, useHistory } from 'react-router-dom';
import React, { FC } from 'react';
import { StyleProps } from 'styles';
import { Log } from 'utils';
import { CareTeamMember, CareTeam, useGetCompanyPublicTeamsQuery } from 'core/api';
import { DashboardUserDetail, InviteToTeamForm } from 'components/Companies';
import { useParams } from 'react-router-dom';

const log = Log('screen.DashboardCompaniesEdit.scenes.User');

type Props = StyleProps;

interface stateType {
  data: CareTeamMember;
  publicTeam: CareTeam;
}

export const DashboardUserScene: FC<Props> = () => {
  // Deps

  const location = useLocation();
  const history = useHistory();

  const { state } = useLocation<stateType>();
  const { data, publicTeam } = state;

  const { companyId } = useParams<{ companyId: string | undefined }>();

  const { data: publicTeams = [] } = useGetCompanyPublicTeamsQuery({ companyId: parseInt(companyId || '', 10) });

  // Handlers

  const handleGoBack = () => {
    log.debug('back button press');
    history.goBack();
  };

  return (
    <>
      <PageTitle title={'User Account'} />
      <Header path={location.pathname} />
      <DashboardUserDetail data={data} publicTeam={publicTeam} handleGoBack={handleGoBack} />
      <InviteToTeamForm currentUser={data} publicTeam={publicTeam} publicTeamList={publicTeams} />
    </>
  );
};

export type DashboardUserSceneProps = Props;
export default DashboardUserScene;
