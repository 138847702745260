import React, { FC, SyntheticEvent } from 'react';
import { StyleProps, Styles, m } from 'styles';
import { UserType, isRoleType } from 'core/api';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

interface Props extends StyleProps {
  label?: string;
  required?: boolean;
  error?: boolean;
  value?: UserType;
  helperText?: string;
  onChange?: (v: UserType) => void;
}

export const FormRolesTypeField: FC<Props> = ({ style, label, required, error, value, helperText, onChange }) => {
  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value: newVal } = e.currentTarget;
    if (isRoleType(parseInt(newVal, 10)) && onChange) {
      onChange(parseInt(newVal, 10));
    }
  };
  return (
    <FormControl style={m(styles.container, style)} component="fieldset" required={required} error={error}>
      {!!label && <FormLabel>{label}</FormLabel>}
      <RadioGroup row value={value} onChange={handleChange}>
        <FormControlLabel value={2} control={<Radio color="primary" />} label="Admin" />
        <FormControlLabel value={3} control={<Radio color="primary" />} label="Member" />
      </RadioGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const styles: Styles = {
  container: {},
};

export type FormRolesTypeFieldProps = Props;
export default FormRolesTypeField;
