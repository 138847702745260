import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  root: {
    width: 400,
    minHeight: 40,
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#F6F7F8',
    paddingLeft: 14,
    paddingRight: 14,
  },
  focused: {},
}));

interface InjectedProps {
  onChange: (text: string) => void;
}

const ResourceSearchInput: FC<InjectedProps> = ({ onChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState<string>('');

  const debounceChangeCallback = useCallback(debounce(onChange, 250), []);

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = e;

    // Immediately respond without any debounce delay.
    if (isEmpty(value)) {
      onChange('');
      setValue('');
      return;
    }

    setValue(e.target.value);
    debounceChangeCallback(e.target.value);
  };
  const handleReset = () => {
    setValue('');
    onChange('');
  };

  return (
    <TextField
      placeholder="Search"
      onChange={handleTextChange}
      value={value}
      InputProps={{
        classes,
        disableUnderline: true,
        endAdornment: !!value && (
          <InputAdornment position="end">
            <IconButton disableFocusRipple aria-label="toggle password visibility" onClick={handleReset} edge="end">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ResourceSearchInput;
