import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useMutation } from '@apollo/client';
import { UserInput } from 'core/api';
import { Snackbar } from '@material-ui/core';
import { CREATE_ADMIN_USER } from 'core/apollo/mutation/users';
import { Alert, Color } from '@material-ui/lab';
import { Header } from 'components/Common';
import { routes } from 'screens/consts';
import { userAdminCreatedResolver } from 'core/apollo/mutation/resolvers';
import CreateUserCell from 'components/Users/CreateUserCell';
import Modal, { ConfirmationModalRef } from 'components/Modal';

const AdminCreateScreen = () => {
  const modalRef = useRef<ConfirmationModalRef>();
  const location = useLocation();
  const history = useHistory();
  const [snackbar, setSnackbar] = useState<{ severity: Color; message: string }>();

  const [createAdminUser, { loading }] = useMutation(CREATE_ADMIN_USER, {
    update: userAdminCreatedResolver,
  });

  const onSnackbarClose = () => {
    setSnackbar(undefined);
  };

  const onSubmit = async (input: UserInput) => {
    try {
      await createAdminUser({
        variables: {
          input,
        },
      });
      history.push(routes.admins.list);
    } catch (e) {
      setSnackbar({ severity: 'error', message: e.message });
    }
  };

  const handleCancelClick = () => {
    history.push(routes.admins.list);
  };

  return (
    <div>
      <Header path={location.pathname} />

      <CreateUserCell
        onSubmit={onSubmit}
        loading={loading}
        onCancel={handleCancelClick}
        title="Create Admin Page User"
        okText="Send Invite"
      />

      <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={onSnackbarClose}>
        <Alert onClose={onSnackbarClose} severity={snackbar?.severity}>
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <Modal modalRef={modalRef} />
    </div>
  );
};

export default AdminCreateScreen;
