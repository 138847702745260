import React, { FC, SyntheticEvent } from 'react';
import { StyleProps, Styles, m } from 'styles';
import { AnnouncementType, isAnnouncementType } from 'core/api';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

interface Props extends StyleProps {
  label?: string;
  required?: boolean;
  error?: boolean;
  value?: AnnouncementType;
  helperText?: string;
  onChange?: (v: AnnouncementType) => void;
}

export const FormAnnsTypeField: FC<Props> = ({ style, label, required, error, value, helperText, onChange }) => {
  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value: newVal } = e.currentTarget;
    if (isAnnouncementType(newVal) && onChange) {
      onChange(newVal);
    }
  };
  return (
    <FormControl style={m(styles.container, style)} component="fieldset" required={required} error={error}>
      {!!label && <FormLabel>{label}</FormLabel>}
      <RadioGroup row value={value} onChange={handleChange}>
        <FormControlLabel value="ICON" control={<Radio color="primary" />} label="Icon" />
        <FormControlLabel value="IMAGE" control={<Radio color="primary" />} label="Image" />
      </RadioGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const styles: Styles = {
  container: {},
};

export type FormAnnsTypeFieldProps = Props;
export default FormAnnsTypeField;
