import React, { FC, ReactNode, useState, ChangeEvent } from 'react';
import clsx from 'clsx';
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  lighten,
  makeStyles,
  TableRow,
  TableSortLabel,
  Toolbar,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import { StyleProps } from 'styles';
import { Company } from 'core/api';
import { View, TableRowWithBottomLine, Image, Text } from 'components/Common';
import { FormSearchField } from 'components/Form';
import Tabs from '@material-ui/core/Tabs';
import { headCells } from './Header';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Vector from './Vector.svg';
import Pagination from './GenericPagination';

const ROWS_PER_PAGE = 10;
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  row: {
    padding: '20px 10px',
  },
  button: {
    borderRadius: 4,
    padding: '6px 12px',
    background: 'none',
    border: '1px solid #E8EBED',
    boxShadow: 'none',
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  active: {
    backgroundColor: '#3D8A91',
    borderRadius: '3px',
    padding: '4px 14px',
    color: '#fff',
  },
  paid: {
    backgroundColor: '#E5F1F2',
    borderRadius: '3px',
    padding: '4px 14px',
    color: '#3D8A91',
  },
  row: {
    padding: '11px 20px',
  },
}));

interface Props extends StyleProps {
  title?: string;
  stickyHeader?: boolean;
  searchPlaceholder?: string;
  items: Company[];
  bottomBorder?: string;
  filterWithSearch?: (search: string, items: Company[]) => Company[];
  keyExtractor?: (item: Company) => string;
  renderRightAction?: () => ReactNode;
  onItemPress?: (item: Company) => void;
}

export const DataTableWithSorting: FC<Props> = ({
  searchPlaceholder,
  items,
  filterWithSearch,
  renderRightAction,
  onItemPress,
}) => {
  const classes = useStyles();

  const [order, setOrder] = useState<string>('asc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const [value, setValue] = useState<number>(1);

  const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const EnhancedTableHead = props => {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={event => {
                onSelectAllClick();
                event.stopPropagation();
              }}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.id === 'id' || headCell.id === 'name' || headCell.id === 'createdAt' ? (
                <TableSortLabel
                  active={orderBy === 'id' || orderBy === 'name' || orderBy === 'createdAt'}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={ArrowDropDownIcon}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <View row={true} justifyContent="space-between" alignItems="center">
        <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}></Tabs>
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
            minHeight: 50,
          })}
        >
          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Button variant="contained" color="primary" onClick={() => null} className={classes.button}>
              <Image style={{ width: 10, height: 10, marginRight: 8 }} source={Vector} />
              <Text color="#5B7383" size={14}>
                Filters
              </Text>
            </Button>
          )}
        </Toolbar>
      </View>
    );
  };

  const SearchableTableToolbar = () => {
    const classes = useToolbarStyles();
    const handleChange = val => {
      setSearch(val);
    };
    return (
      <View className={classes.row} row={true} justifyContent="space-between" alignItems="center">
        <FormSearchField
          label=""
          placeholder={searchPlaceholder}
          value={search}
          autoFocus
          onChange={handleChange}
          style={{ width: 400 }}
        />
        {renderRightAction && renderRightAction()}
      </View>
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = items.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Render

  const renderLogo = (url: string | undefined) => (url ? <img style={{ width: 25, height: 25 }} src={url} /> : null);

  const renderRow = (row: Company) => {
    const isItemSelected = isSelected(row.name);
    return (
      <TableRowWithBottomLine
        hover
        onClick={() => onItemPress && onItemPress(row)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            onChange={event => {
              handleClick(event, row.name);
              event.stopPropagation();
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="left">{renderLogo(row.logo)}</TableCell>
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">
          <Text className={classes.active}>{row.status || '-'}</Text>
        </TableCell>
        <TableCell align="left">
          <Text className={classes.paid}>{row.payment || '-'}</Text>
        </TableCell>
        <TableCell align="left">{row.createdAt ? moment(row.createdAt).format('DD MMM YYYY') : '-'}</TableCell>
      </TableRowWithBottomLine>
    );
  };

  const getItems = () => {
    if (!search || !filterWithSearch) {
      return items;
    }
    return filterWithSearch(search, items);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <SearchableTableToolbar />
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={items.length}
            />
            <TableBody>
              {stableSort(getItems(), getComparator(order, orderBy))
                .slice((page - 1) * ROWS_PER_PAGE, (page - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE)
                .map(renderRow)}
            </TableBody>
          </Table>
        </TableContainer>
        <View className={classes.row} row={true} justifyContent="space-between" alignItems="center">
          <Text>
            {`${(page - 1) * ROWS_PER_PAGE}-
            ${Math.min((page - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE, items.length)} of ${items.length} Companies`}
          </Text>
          <Pagination
            count={Math.ceil(items.length / ROWS_PER_PAGE)}
            shape="rounded"
            page={page}
            onChange={handleChangePage}
            style={{ paddingRight: 70 }}
          />
          <Text></Text>
        </View>
      </Paper>
    </div>
  );
};

export type DataTableWithSortingProps = Props;
export default DataTableWithSorting;
