import { Paper } from '@material-ui/core';
import { DataTable } from 'components/Tables';
import { CareTeam, InviteLink } from 'core/api';
import { routes } from 'screens/consts';
import { View, Text } from 'components/Common';
import React, { FC, useState, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { mx, m, StyleProps, Styles } from 'styles';
import LinkIcon from '@material-ui/icons/Link';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface Props extends StyleProps {
  id: number;
  publicTeams: CareTeam[];
}

export const PublicTeamList: FC<Props> = ({ id, publicTeams }) => {
  // Deps

  const history = useHistory();
  const [link, setLink] = useState({ value: '', copied: false });

  // Handlers

  const handleItemPress = (item: CareTeam) => {
    history.push({
      pathname: `${routes.companies.default}/${id}/${item.id}`,
      state: {
        page: 'publicTeam',
        data: publicTeams.find(team => team.id === item.id),
      },
    });
  };

  const filterItemsWithSearch = (search: string, items: CareTeam[]): CareTeam[] => {
    const searchMod = search.toLowerCase().trim();
    return items.filter(itm => {
      const { name } = itm;
      const nameMod = name ? name.toLowerCase().trim() : '';
      return nameMod.indexOf(searchMod) >= 0;
    });
  };

  // Render

  const renderInviteLinks = (links: InviteLink[] | undefined) => {
    if (!links) {
      return '';
    }
    return links
      .filter(itm => itm.role.name !== 'Patient')
      .map((s, index) => (
        <View
          key={index}
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
          }}
          style={styles.link}
        >
          <View style={{ fontWeight: 'bold' }}>{`${s.role.name} Invite Link:`}</View>
          <View>
            {s.link}
            <CopyToClipboard text={s.link} onCopy={() => setLink({ value: s.link, copied: true })}>
              <LinkIcon />
            </CopyToClipboard>
            {link.copied && link.value === s.link ? <span style={{ color: 'red' }}>Copied!</span> : null}
          </View>
        </View>
      ));
  };

  // Render

  return (
    <Paper style={m(styles.container)}>
      <Text size={21}>Public Teams</Text>
      <DataTable
        title="PublicTeams"
        items={publicTeams}
        searchPlaceholder="Search team name..."
        filterWithSearch={filterItemsWithSearch}
        bottomBorder="line"
        columns={{
          id: {
            title: 'Team ID#',
            component: 'th',
            scope: 'row',
            valExtractor: val => `${val}`,
          },
          name: {
            title: 'Team Name',
            valExtractor: val => val,
          },
          teamMembers: {
            title: '# of Team Members',
            valExtractor: val => val?.length,
          },
          inviteLinks: {
            title: 'Invite Links',
            valExtractor: renderInviteLinks,
          },
        }}
        onItemPress={handleItemPress}
      />
    </Paper>
  );
};

const styles: Styles = {
  container: {
    padding: 20,
    marginTop: 20,
  },
  row: {
    marginTop: 8,
    marginBottom: 20,
  },
  table: {
    ...mx.w100,
  },
  link: {
    marginBottom: 15,
  },
};

export type PublicTeamListProps = Props;
export default PublicTeamList;
