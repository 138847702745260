/* eslint-disable max-len */
import { InMemoryCache } from '@apollo/client';
import { get, uniqBy } from 'lodash';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        resource(existingData, { args, toReference }) {
          return existingData || toReference({ __typename: 'Resource', id: args?.id });
        },
        resourceFeed: {
          keyArgs: ['searchQuery', 'categoryIds'],
          merge(existing, incoming, options) {
            const hasOffset = get(options, ['args', 'offset']);
            if (hasOffset) {
              return {
                ...existing,
                ...incoming,
                data: uniqBy([...(existing?.data || []), ...(incoming?.data || [])], '__ref'),
              };
            }

            return incoming;
          },
        },

        users: {
          keyArgs: ['query'],
          merge(existing, incoming, options) {
            const hasOffset = get(options, ['args', 'offset']);
            if (hasOffset) {
              return {
                ...existing,
                ...incoming,
                data: uniqBy([...(existing?.data || []), ...(incoming?.data || [])], '__ref'),
              };
            }

            return incoming;
          },
        },
      },
    },
  },
});

export default cache;
