import React, { FC } from 'react';
import { get } from 'lodash';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import AuthEntryScreen from './Auth/Entry';
import ForgotPasswordScreen from './Auth/ForgotPassword';
import { routes } from './consts';
import DashboardAnnsEditScreen from './Dashboard/Anns/Edit';
import DashboardAnnsListScreen from './Dashboard/Anns/List';
import DashboardCompaniesEditScreen from './Dashboard/Companies/Edit';
import DashboardCompaniesListScreen from './Dashboard/Companies/List';
import DashboardCompanyDetailScreen from './Dashboard/Companies/Details';
import DashboardUserScreen from './Dashboard/Companies/User';
import DashboardEventEditScreen from './Dashboard/Companies/Events';
import DashboardImportEventsScreen from './Dashboard/Companies/Details/scenes/ImportEvents';
import ResourceEditScreen from 'screens/Resources/Edit';
import ResourceListScreen from 'screens/Resources/List';
import AuthLayout from '../layouts/AuthLayout';
import ResourceCreateScreen from 'screens/Resources/Create';
import { useAuth } from '../hooks/useAuth';
import { Backdrop, CircularProgress, createStyles, Theme } from '@material-ui/core';
import UsersListScreen from 'screens/Users/List';
import { isSuperAdmin } from 'utils/permissions';
import ResetPasswordScreen from 'screens/Auth/ResetPassword';
import UserProfile from 'screens/Users/Profile';
import AdminListScreen from 'screens/Admins/List';
import AdminCreateScreen from 'screens/Admins/Create';
import AdminEditScreen from 'screens/Admins/Edit';
import AdminProfile from 'screens/Admins/Profile';
import ChangePasswordScreen from 'screens/Auth/ChangePassword';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'rgba(255, 255, 255, .3)',
    },
  }),
);

const ProtectedRoute = ({ children, ...rest }) => {
  const { user, loading } = useAuth();
  const classes = useStyles();

  if (loading) {
    return (
      <Backdrop open className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.auth.entry,
              state: { from: location.pathname },
            }}
          />
        )
      }
    />
  );
};

export const Screens: FC = () => {
  const { user } = useAuth();
  const location = useLocation();

  return (
    <>
      <Switch>
        <Route path={routes.auth.base}>
          {user && <Redirect to={{ pathname: get(location, ['state', 'from'], routes.home) }} />}
          <Route path={routes.auth.entry} component={AuthEntryScreen} />
          <Route path={routes.auth.reset_init} component={ForgotPasswordScreen} />
          <Route component={ResetPasswordScreen} path={routes.auth.reset_finish} />
        </Route>

        <ProtectedRoute path={routes.admins.change_password}>
          <ChangePasswordScreen />
        </ProtectedRoute>

        <ProtectedRoute path={routes.admins.default}>
          <AuthLayout>
            <Switch>
              <Route exact path={routes.admins.list} component={AdminListScreen} />
              <Route path={`${routes.admins.edit}/:id`} component={AdminEditScreen} />
              <Route path={routes.admins.create} component={AdminCreateScreen} />
              <Route path={`${routes.admins.profile}/:id`} component={AdminProfile} />
              <Redirect to={routes.admins.default} />
            </Switch>
          </AuthLayout>
        </ProtectedRoute>

        <ProtectedRoute path={routes.resources.default}>
          <AuthLayout>
            <Switch>
              <Route exact path={routes.resources.list} component={ResourceListScreen} />
              <Route path={`${routes.resources.edit}/:id`} component={ResourceEditScreen} />
              <Route path={routes.resources.create} component={ResourceCreateScreen} />
              <Redirect to={routes.resources.default} />
            </Switch>
          </AuthLayout>
        </ProtectedRoute>

        <ProtectedRoute path={routes.users.default}>
          <AuthLayout>
            <Switch>
              <Route exact path={routes.users.list} component={UsersListScreen} />
              <Route path={`${routes.users.profile}/:id`} component={UserProfile} />
              <Redirect to={routes.users.default} />
            </Switch>

            {!isSuperAdmin(user) && <Redirect to={routes.resources.list} />}
          </AuthLayout>
        </ProtectedRoute>

        <ProtectedRoute path={routes.announcements.default}>
          <AuthLayout>
            <Switch>
              <Route path={routes.announcements.list} exact component={DashboardAnnsListScreen} />
              <Route path={`${routes.announcements.default}/:id`} component={DashboardAnnsEditScreen} />
              <Redirect to={routes.announcements.default} />
            </Switch>
          </AuthLayout>
        </ProtectedRoute>

        <ProtectedRoute path={routes.companies.default}>
          <AuthLayout>
            <Switch>
              <Route path={routes.companies.list} exact component={DashboardCompaniesListScreen} />
              <Route path={`${routes.companies.default}/:id`} component={DashboardCompaniesEditScreen} />
              <Route path={`${routes.companies.default}/:id/:subId`} component={DashboardCompanyDetailScreen} />
              <Route path={`${routes.companies.default}/:id/:subId/import`} component={DashboardImportEventsScreen} />
              <Route path={`${routes.companies.default}/:id/:subId/:eventId`} component={DashboardEventEditScreen} />
              <Route
                path={`${routes.companies.default}/:companyId/:teamId/user/:userId`}
                component={DashboardUserScreen}
              />
              <Redirect to={routes.companies.default} />
            </Switch>
          </AuthLayout>
        </ProtectedRoute>

        {user ? (
          <Redirect to={routes.home} />
        ) : (
          <Redirect
            to={{
              pathname: routes.entry,
              state: { from: location.pathname },
            }}
          />
        )}
      </Switch>
    </>
  );
};
