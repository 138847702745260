import {Announcement, AttachmentType} from 'core/api';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { requiredMsg } from 'utils';

import { AnnsEditFormData, AnnsEditFormErrors } from '.';

export const getDefAnnsEditFormData = (): AnnsEditFormData => ({
  type: 'ICON',
  cta: 'Learn More',
  publishStartDate: moment().toISOString(),
  expirationDate: moment().add(7, 'd').toISOString(),
  publishTypes: ['MOBILE', 'EMAIL', 'SMS'],
  timezone: momentTimezone.tz.guess(true),
});

export const polishAnnsEditFormData = (value: AnnsEditFormData): AnnsEditFormData => {
  let modVal = value;
  // Title = 35 character
  if (modVal.title && modVal.title.length > 35) {
    modVal = { ...modVal, title: modVal.title.substring(0, 35) };
  }
  // Description = 120 character
  if (modVal.description && modVal.description.length > 120) {
    modVal = { ...modVal, description: modVal.description.substring(0, 120) };
  }
  // Additional Description = 120 character
  if (modVal.additionalDescription && modVal.additionalDescription.length > 120) {
    modVal = { ...modVal, additionalDescription: modVal.additionalDescription.substring(0, 120) };
  }
  // CTA = 20 character
  if (modVal.cta && modVal.cta.length > 20) {
    modVal = { ...modVal, cta: modVal.cta.substring(0, 20) };
  }
  if (!modVal.cta && modVal.link) {
    modVal = { ...modVal, link: '' };
  }
  // Reset thumbnail if the type was change
  if (modVal.type === 'ICON' && modVal.thumbnail) {
    modVal = { ...modVal, thumbnail: undefined };
  }
  // Move end date, if start is bigger
  if (modVal.publishStartDate && modVal.publishEndDate) {
    const start = new Date(modVal.publishStartDate);
    const end = new Date(modVal.publishEndDate);
    if (start > end) {
      modVal = { ...modVal, publishEndDate: modVal.publishStartDate };
    }
  }
  // Move exp date, if start is bigger
  if (modVal.publishStartDate && modVal.expirationDate) {
    const start = new Date(modVal.publishStartDate).getTime();
    const exp = new Date(modVal.expirationDate).getTime();
    if (start > exp) {
      modVal = { ...modVal, expirationDate: moment(new Date(start)).add(1, 'd').toISOString() };
    }
  }
  return modVal;
};

export const getAnnsEditFormErrors = (data: AnnsEditFormData): AnnsEditFormErrors | undefined => {
  const {
    type,
    title,
    description,
    additionalDescription,
    thumbnail,
    accessKeyIds,
    publishStartDate,
    publishEndDate,
    expirationDate,
    publishTypes,
    cta,
    link,
  } = data;
  if (!type) {
    return { type: requiredMsg('Type') };
  }
  if (!title) {
    return { title: requiredMsg('Headline') };
  }
  if (title.length > 35) {
    return { title: 'Headline is too long' };
  }
  if (!description || !description.trim().length) {
    return { description: requiredMsg('Blurb') };
  }
  if (description.length > 120) {
    return { description: 'Blurb is too long' };
  }
  if (additionalDescription && !additionalDescription.trim().length) {
    return { additionalDescription: 'Please enter valid description' };
  }
  if (!accessKeyIds || !accessKeyIds.length) {
    return { accessKeyIds: requiredMsg('Access key') };
  }
  if (!publishStartDate) {
    return { publishStartDate: requiredMsg('Publish start date') };
  }
  if (!expirationDate) {
    return { expirationDate: requiredMsg('Expiration date') };
  }
  if (!publishTypes) {
    return { publishTypes: requiredMsg('Publish types') };
  }
  if (!publishTypes.length) {
    return { publishTypes: requiredMsg('At least one publish type is required') };
  }
  // End cannot be earlier then Start
  const start = new Date(publishStartDate).getTime();
  if (publishEndDate) {
    const end = new Date(publishEndDate).getTime();
    if (start > end) {
      return { publishEndDate: `Publish end date can't be earlier than start date` };
    }
  }
  // Expiration date can't be earlier than start date
  const exp = new Date(expirationDate).getTime();
  if (start > exp) {
    return { expirationDate: `Expiration date date can't be earlier than start date` };
  }
  // If Image is chosen, both Card Image and Full Size Image are required.
  if (type === 'IMAGE' && !thumbnail) {
    return { thumbnail: requiredMsg('Card image') };
  }
  if (cta && !link) {
    return { link: 'Link is required when CTA is defined' };
  }
  // No errors
  return undefined;
};

export const annsToEditFormData = (item: Announcement): AnnsEditFormData => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, image, thumbnail, publishTypes, accessKeys, ...rest } = item;
  return {
    ...rest,
    image: image
      ? {
          type: AttachmentType.IMAGE,
          url: image,
        }
      : undefined,
    thumbnail: thumbnail
      ? {
          type: AttachmentType.IMAGE,
          url: thumbnail,
        }
      : undefined,
    company: accessKeys ? accessKeys[0].company : undefined,
    publishTypes: publishTypes ? publishTypes.map(itm => itm.type) : undefined,
    accessKeyIds: accessKeys ? accessKeys.map(itm => itm.id) : undefined,
  };
};
