import gql from 'graphql-tag';
import { fields } from 'core/api';

export const GET_USERS = gql`
    query users($query: String, $limit: Int, $offset: Int) {
        users(query: $query, limit: $limit, offset: $offset) {
            ${fields.user}
        }
    }
`;
