import React, { FC, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {colors} from 'styles/colors';

export interface MenuButtonOption {
  type: any;
  label: string;
  danger?: boolean;
}

interface InjectedProps {
  options: MenuButtonOption[];
  onClick: (option: MenuButtonOption) => void;
}

const MenuButton: FC<InjectedProps> = ({ options, onClick }) => {
  const [anchor, setAnchor] = useState<Element | null>(null);

  const onButtonClick = e => {
    e.stopPropagation();
    setAnchor(e.currentTarget);
  };

  const handleClose = e => {
    e.stopPropagation();
    setAnchor(null);
  };
  const handleItemClick = (e, option: MenuButtonOption) => {
    e.stopPropagation();
    handleClose(e);
    onClick(option);
  };

  return (
    <div>
      <IconButton onClick={onButtonClick} color="primary" aria-label="actions" component="span">
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={handleClose}>
        {options.map(option => (
          <MenuItem
            style={{ color: option.danger ? colors.pinkyRed : undefined }}
            key={option.type}
            onClick={e => handleItemClick(e, option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MenuButton;
