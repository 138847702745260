import {Resource} from 'core/api';

export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  CONTENT_COORDINATOR: 'CONTENT_COORDINATOR',
  CONTENT_PARTNER: 'CONTENT_PARTNER',
};

export const RESOURCE_ACTION_TEXT = {
  PUBLISH: {
    TITLE: 'Are you sure you want to publish?',
    DESCRIPTION: 'Publishing will add this merchant to the production app and/or website.',
    CONFIRM_TEXT: 'Publish',
    CANCEL_TEXT: 'Cancel',
  },
  UNPUBLISH: {
    TITLE: 'Are you sure you want to un-publish?',
    DESCRIPTION: 'Un-publishing will remove this merchant from the production app and/or website.',
    CONFIRM_TEXT: 'Un-publish',
    CANCEL_TEXT: 'Cancel',
  },
  CANCEL: {
    TITLE: 'Are you sure you want to cancel?',
    DESCRIPTION: 'By canceling any changes made will not be saved.',
    CONFIRM_TEXT: 'Save as Draft',
    CANCEL_TEXT: 'Cancel',
  },
};

export const RESOURCE_ACTION_DYNAMIC_TEXT = {
  DELETE: {
    getTitle: (resource: Resource) => `Are you sure you want to delete ${resource.merchantName}?`,
    getDescription: (resource: Resource) =>
      `Deleting ${resource.merchantName} is permanent and all data associated with ` +
      `${resource.merchantName} will be deleted.`,
    getConfirmText: 'Delete Resource',
    getCancelText: 'Cancel',
  }
};
