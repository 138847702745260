import React, { FC, useEffect, useRef, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { useHistory } from 'react-router';
import AuthContext from './AuthContext';
import { accessTokenStorage } from 'core/storage';
import { useLazyQuery } from '@apollo/client';
import { GET_ADMIN_USER } from 'core/apollo/queries/users';
import { Authorized, useSignInMutation } from 'core/api';
import { routes } from 'screens/consts';

const AuthProvider: FC<unknown> = ({ children }) => {
  const history = useHistory();
  const from = useRef<string | undefined>();

  const signIn = useSignInMutation();
  const [loading, setLoading] = useState(true);
  const [getUser, response] = useLazyQuery(GET_ADMIN_USER, {
    onCompleted: () => {
      setLoading(false);

      // Make sure to redirect
      if (from.current) history.push(from.current);
    },
    onError: () => setLoading(false),
  });

  const getSession = async () => {
    // Call only when necessary
    if (!isEmpty(accessTokenStorage.get())) {
      getUser();
      return;
    }

    setLoading(false);
  };

  useEffect(() => {
    getSession();
  }, []);

  const setAuth = async (authorized: Authorized) => {
    accessTokenStorage.set(authorized.accessToken);
    await getSession();
  };

  const handleLogin = async ({ email, password }: { email: string; password: string }) => {
    const authData = await signIn({ email, password });
    await setAuth(authData);
  };

  const handleLogout = async () => {
    await response.client?.cache.reset();
    accessTokenStorage.remove();

    // Give some time to state to update...
    setTimeout(() => {
      history.push(routes.auth.entry);
    }, 150);
  };

  return (
    <AuthContext.Provider
      value={{
        logout: handleLogout,
        login: handleLogin,
        setAuth,
        user: get(response, ['data', 'adminUser']),
        loading: loading || response.loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
