import { Button, Paper } from '@material-ui/core';
import { Text, View } from 'components/Common';
import React, { FC } from 'react';
import { m, StyleProps, Styles } from 'styles';
import { CareTeamMember, CareTeam } from 'core/api';

interface Props extends StyleProps {
  data: CareTeamMember;
  publicTeam: CareTeam;
  handleGoBack: () => void;
}

export const DashboardUserDetail: FC<Props> = ({ style, data, publicTeam, handleGoBack }) => {
  return (
    <Paper style={m(styles.container, style)}>
      <View style={styles.actions} row={true} justifyContent="space-between">
        <Text size={21}>User Detail</Text>
        <View row={true} justifyContent="flex-end">
          <Button style={styles.actionBtn} variant="contained" color="default" onClick={handleGoBack}>
            Back
          </Button>
        </View>
      </View>
      <View style={styles.row}>
        Team Member ID
        <View>{`#${data && data.id}`}</View>
      </View>
      <View style={styles.row}>
        User ID
        <View>{`#${data.user && data.user.id}`}</View>
      </View>
      <View style={styles.row}>
        Name
        <View>{`${data.user && data.user.firstName} ${data.user && data.user.lastName}`}</View>
      </View>
      <View style={styles.row}>
        Email
        <View>{`${data.user && data.user.email}`}</View>
      </View>
      <View style={styles.row}>
        Mobile
        <View>{`${data.user && data.user.mobileNo}`}</View>
      </View>
      <View style={styles.row}>
        Team Info
        <View>{`${publicTeam.name} (#${publicTeam.id})`}</View>
      </View>
      <View style={styles.row}>
        Care Team Role
        <View>{`${data.careTeamRoles && data.careTeamRoles[0]?.name || 'Unset'}`}</View>
      </View>
    </Paper>
  );
};

const styles: Styles = {
  container: {
    padding: 20,
  },
  row: {
    marginTop: 6,
    marginBottom: 15,
  },
  actions: {
    marginTop: 20,
  },
  actionBtn: {
    marginLeft: 6,
  },
};

export type DashboardUserDetailProps = Props;
export default DashboardUserDetail;
