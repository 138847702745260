/* eslint-disable @typescript-eslint/no-explicit-any */
import { AttachmentType, CreateEventInput } from 'core/api';
import React, { ChangeEvent, FC } from 'react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { m, StyleProps, Styles } from 'styles';
import { Grid } from '@material-ui/core';
import {
  FormSelectField,
  FormActivityDurationField,
  FormTextField,
  FormActivityRolesTypeField,
  FormDateTimePickerField,
  FormImgPickerField,
  FormLocationSearchField,
} from 'components/Form';
import { getRecurrenceOptions, recurrencePeriodTypeToText } from 'utils/recurrences';

type FormData = Partial<CreateEventInput>;
type FormErrors = Partial<Record<keyof FormData, string>>;
type FormHelperTexts = Partial<Record<keyof FormData, string>>;

interface Props extends StyleProps {
  data: FormData;
  errors?: FormErrors;
  id?: number;
  onChange: (v: FormData) => void;
  setErrs: (v: Partial<Record<keyof CreateEventInput, string>>) => void;
}

const helpTexts: FormHelperTexts = {
  title: '7 to 9 words; max 80 char.',
  description: '7 to 50 words; 50-300 chars',
  recurrence: 'Repeat activities',
};

export const EventEditForm: FC<Props> = ({ style, data, id, errors, onChange, setErrs }) => {
  // Data

  const USTimezones = momentTimezone.tz.zonesForCountry('US');
  const AUTimezones = momentTimezone.tz.zonesForCountry('AU');
  const timezones = USTimezones.concat(AUTimezones);

  // Handlers

  const handleTextFieldChanged = (k: keyof FormData) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    onChange({ ...data, [k]: value });
  };

  const handleTypeDataChange = <K extends keyof FormData>(k: K) => (val: FormData[K]) => {
    onChange({ ...data, [k]: val });
  };

  // Render

  return (
    <Grid style={m(styles.gridContainer, style)} container={true} spacing={2}>
      <Grid item={true} md={6}>
        <FormTextField
          style={styles.row}
          label="Title"
          fullWidth={true}
          required={true}
          InputProps={{ inputProps: { maxLength: 75 } }}
          error={!!errors?.title}
          helperText={errors?.title || helpTexts.title}
          placeholder="Add an Activity"
          value={data.title}
          onChange={handleTextFieldChanged('title')}
        />
        {id && (
          <div>
            <div>Current Location</div>
            <div style={styles.row}>{data.location ? `${data.location.name},${data.location.address}` : '-'}</div>
          </div>
        )}
        <FormLocationSearchField
          style={styles.row}
          label={id ? 'Select New Location' : 'Location'}
          value={data.location}
          setErrs={setErrs}
          onChange={handleTypeDataChange('location')}
        />
        <FormImgPickerField
          style={styles.row}
          label="Background"
          value={data.attachment?.uri || data.attachment?.url}
          error={!!errors?.attachment}
          helperText={errors?.attachment}
          placeholder={`Drag 'n' drop image here, or click to select.\nImage specs: 100x100 px.`}
          onChange={val => onChange({ ...data, attachment: { type: AttachmentType.IMAGE, uri: val } })}
        />
        <FormTextField
          style={styles.row}
          label="Description"
          fullWidth={true}
          multiline={true}
          rows={5}
          InputProps={{ inputProps: { maxLength: 300 } }}
          error={!!errors?.description}
          helperText={errors?.description || helpTexts.description}
          placeholder="Add Activity Details"
          value={data.description}
          onChange={handleTextFieldChanged('description')}
        />
      </Grid>

      <Grid item={true} md={6}>
        <FormActivityDurationField
          style={styles.row}
          label="All Day Activity"
          required={true}
          value={data.isAllDay}
          error={!!errors?.isAllDay}
          helperText={errors?.isAllDay || helpTexts.isAllDay}
          onChange={handleTypeDataChange('isAllDay')}
        />
        <FormDateTimePickerField
          style={styles.row}
          label="Start"
          required={true}
          fullWidth={true}
          type={data.isAllDay === 'YES' ? 'date' : 'datetime-local'}
          value={data.isAllDay === 'YES' ? moment(data.startDate).format('YYYY-MM-DD') : data.startDate}
          error={!!errors?.startDate}
          helperText={errors?.startDate || helpTexts.startDate}
          onChange={handleTextFieldChanged('startDate')}
        />
        {data.isAllDay === 'NO' && (
          <FormDateTimePickerField
            style={styles.row}
            label="End"
            required={true}
            fullWidth={true}
            type="datetime-local"
            value={data.endDate}
            error={!!errors?.endDate}
            helperText={errors?.endDate || helpTexts.endDate}
            onChange={handleTextFieldChanged('endDate')}
          />
        )}
        <FormSelectField
          style={styles.row}
          label="Timezone"
          variant="outlined"
          fullWidth={true}
          error={!!errors?.timezone}
          helperText={errors?.timezone || helpTexts.timezone}
          items={timezones.map(itm => itm)}
          keyExtractor={itm => `${itm}`}
          titleExtractor={itm => `${itm}`}
          value={data.timezone}
          onChange={handleTypeDataChange('timezone')}
        />
        <FormSelectField<any>
          style={styles.row}
          label="Recurrence Type"
          variant="outlined"
          fullWidth={true}
          error={!!errors?.recurrence}
          helperText={errors?.recurrence || helpTexts.recurrence}
          items={getRecurrenceOptions(data.startDate)}
          keyExtractor={itm => itm.title || recurrencePeriodTypeToText(itm)}
          titleExtractor={itm => itm.title || recurrencePeriodTypeToText(itm)}
          value={data.recurrence || undefined}
          onChange={val => onChange({ ...data, recurrence: val.value })}
        />
        <FormActivityDurationField
          style={styles.row}
          label="isReminderEnabled"
          required={true}
          value={data.isReminderEnabled}
          error={!!errors?.isReminderEnabled}
          helperText={errors?.isReminderEnabled || helpTexts.isReminderEnabled}
          onChange={handleTypeDataChange('isReminderEnabled')}
        />
        <FormActivityRolesTypeField
          style={styles.row}
          label="Visibility"
          fullWidth={true}
          required={true}
          value={data.visibility}
          error={!!errors?.visibility}
          helperText={errors?.visibility || helpTexts.visibility}
          onChange={handleTypeDataChange('visibility')}
        />
      </Grid>
    </Grid>
  );
};

const styles: Styles = {
  container: {},
  row: {
    marginTop: 8,
    marginBottom: 20,
  },
  text: {
    marginTop: 20,
    marginBottom: 8,
  },
};

export * from './types';
export * from './utils';
export type EventEditFormProps = Props;
export default EventEditForm;
