import { Button, Paper } from '@material-ui/core';
import { get } from 'lodash';
import { Image, Text, View, PageTitle } from 'components/Common';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { colors, px, StyleProps, Styles } from 'styles';

import logoImg from '../../../assets/logo.png';
import { appConfigs } from 'core/configs';
import { useForm } from 'react-hook-form';
import RHInput from 'components/Form/RHInput';
import { useMutation } from '@apollo/client';
import { routes } from 'screens/consts';
import { CHANGE_PASSWORD } from 'core/apollo/mutation/users';
import { useAuth } from '../../../hooks/useAuth';
import { parseGraphQLError } from 'utils/errors';

type Props = StyleProps;

interface ChangePasswordFormRecords {
  newPassword: string;
  newPasswordConfirmation: string;
  oldPassword: string;
}

export const ChangePasswordScreen: FC<Props> = () => {
  const { user } = useAuth();
  const location = useLocation();

  const { control, handleSubmit, getValues } = useForm<ChangePasswordFormRecords>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
  });

  const [changePassword, { loading: processing, error }] = useMutation(CHANGE_PASSWORD, {
    ignoreResults: true,
  });

  const history = useHistory();

  const goBack = () => {
    const backRoute = get(location, ['state', 'backRoute']);
    history.push(backRoute ? backRoute : routes.admins.list);
  };

  const onSubmit = async (data: ChangePasswordFormRecords) => {
    await changePassword({
      variables: {
        guard: 'admin',
        input: data,
      },
    });

    goBack();
  };

  return (
    <>
      <PageTitle title="Reset Password" />
      <View style={styles.container}>
        <Paper style={styles.content} elevation={2}>
          <View row={true} justifyContent="center" alignItems="center">
            <Image style={styles.logo} source={logoImg} />
          </View>
          <View justifyContent="center" alignItems="center">
            <Text size={22.5}>Set New Password</Text>
            <Text size={14} style={styles.description}>
              Enter a new password for <b>{user?.email}</b> In order to keep your account secure, you can’t reuse your
              old password.
            </Text>
          </View>
          <View style={styles.errWrap} justifyContent="center" alignItems="center">
            {!!error && (
              <Text style={styles.err}>
                <b>Error:</b> {parseGraphQLError(error)}
              </Text>
            )}
          </View>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RHInput
              inputProps={{
                type: 'password',
              }}
              disabled={processing}
              placeholder="Old password"
              label="Old password"
              name="oldPassword"
              control={control}
              rules={{ required: 'Old Password required' }}
            />

            <RHInput
              topIndent
              inputProps={{
                type: 'password',
              }}
              disabled={processing}
              placeholder="New password (8+ characters)"
              label="New password (8+ characters)"
              name="newPassword"
              control={control}
              rules={{ required: 'Password required' }}
            />

            <RHInput
              topIndent
              inputProps={{
                type: 'password',
              }}
              disabled={processing}
              placeholder="Confirm new password"
              label="Confirm new password"
              name="newPasswordConfirmation"
              control={control}
              rules={{
                required: 'Confirmation required',
                validate: value => {
                  const { newPassword } = getValues();
                  return newPassword === value || 'Passwords do not match';
                },
              }}
            />

            <Button
              disableElevation
              style={{ width: '100%', marginTop: 25 }}
              type="submit"
              variant="contained"
              color="primary"
              disabled={processing}
            >
              Reset Password
            </Button>
            <Button onClick={goBack} style={{ marginTop: 15 }} fullWidth variant="text">
              Cancel
            </Button>
          </form>
          <Text style={styles.version} block={true}>{`v${appConfigs.version}`}</Text>
        </Paper>
      </View>
    </>
  );
};

const styles: Styles = {
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: '50px 30px',
    width: 413,
    position: 'relative',
  },
  row1: {
    marginTop: 10,
    marginBottom: 34,
  },
  logo: {
    width: 160,
    height: 32,
    marginBottom: 20,
    marginLeft: -18,
  },
  errWrap: {
    height: 20,
  },
  err: {
    color: colors.error,
    fontSize: px(14),
    textAlign: 'center',
  },
  version: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 4,
    fontSize: '10px',
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    margin: '22px 20px',
  },
};

export default ChangePasswordScreen;
