import { Button, Paper } from '@material-ui/core';
import { ButtonProgress } from 'components/Buttons';
import { CentredProgressIndicator, Text, useSnackbar, View } from 'components/Common';
import EventEditForm from 'components/Companies/EventForm';
import { EventEditFormData, EventEditFormErrors } from 'components/Companies/EventForm/types';
import {
  eventToEditFormData,
  getDefEventEditFormData,
  getEventFormErrors,
  polishActivityFormData,
} from 'components/Companies/EventForm/utils';
import { UpdateEventInput, useEditEventMutation, useGetEventByIdQuery, useRemoveEventMutation } from 'core/api';
import withRecurringOptions, { InjectedRecurringOptionsProps } from 'hoc/withRecurringOptions';
import { isNil } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { colors, m, StyleProps, Styles } from 'styles';
import { errToStr, Log } from 'utils';

const log = Log('screen.DashboardCompanies.scenes.EditEvent');

interface Props extends StyleProps, InjectedRecurringOptionsProps {
  id: number;
  publicTeamId?: number;
  onGoBack: () => void;
}

export const DashboardEventEditScene: FC<Props> = ({
  style,
  publicTeamId,
  id,
  onGoBack,
  showRemoveRecurringDialog,
  showUpdateRecurringDialog,
}) => {
  // UI

  const snackbar = useSnackbar();

  // Form

  const [data, setData] = useState<EventEditFormData>(getDefEventEditFormData());
  const [errs, setErrs] = useState<EventEditFormErrors | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);

  const { data: exists, loading, error } = useGetEventByIdQuery({ publicTeamId, id });

  useEffect(() => {
    if (exists) {
      log.debug('item loading done, data=', exists);
      setData(eventToEditFormData(exists));
    }
  }, [exists]);

  useEffect(() => {
    if (error) {
      snackbar.err(error);
    }
  }, [error]);

  const updateEvent = useEditEventMutation({ publicTeamId });
  const removeEvent = useRemoveEventMutation({ publicTeamId });

  // UI

  // Handlers

  const handleDataChange = (value: EventEditFormData) => {
    setErrs(undefined);
    const modVal = polishActivityFormData(value);
    setData(modVal);
    log.debug('data changed, value=', modVal);
  };

  const handleSubmitPress = async () => {
    if (isNil(exists)) return;

    try {
      const curErrs = getEventFormErrors(data);
      if (curErrs) {
        return setErrs(curErrs);
      }
      const {
        title,
        startDate,
        endDate,
        location,
        timezone,
        isAllDay,
        description,
        attachment,
        visibility,
        isReminderEnabled,
        recurrence,
      } = data;

      if (!title || !startDate || !endDate || !isAllDay || !visibility) {
        return;
      }
      const input: UpdateEventInput = {
        id,
        title,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        timezone,
        isAllDay: isAllDay === 'YES',
        description,
        attachment,
        location: location?.address ? location : null,
        visibility,
        isReminderEnabled: isReminderEnabled === 'YES',
        recurrence,
      };

      showUpdateRecurringDialog(exists, input, async updatedInput => {
        log.info('update new event, input=', updatedInput);
        setProcessing(true);
        await updateEvent(updatedInput);
        setProcessing(false);
        log.info('updating new event done, input=', updatedInput);
        snackbar.success('The event updated successfully');
        onGoBack();
      });
    } catch (err) {
      setProcessing(false);
      log.err(err);
      snackbar.err(errToStr(err) || 'Unknown error');
    }
  };

  const handleCancelPress = () => {
    log.debug('handle cancel press');
    onGoBack();
  };

  const handleResetPress = () => {
    log.debug('handle reset press');
    if (confirm('Are you sure you want to reset this form?')) {
      handleDataChange(getDefEventEditFormData());
    }
  };

  const handleRemovePress = async () => {
    log.debug('handlre remove press');

    if (!confirm('Are you sure you want to remove this event?')) {
      return;
    }

    if (isNil(exists)) return;

    showRemoveRecurringDialog(exists, async type => {
      try {
        log.debug('removing event, id=', id);
        await removeEvent(id, type);
        log.debug('removing event done, id=', id);
        onGoBack();
      } catch (err) {
        log.err('removing event err=', err);
        alert(`Removing error: ${errToStr(err)}`);
      }
    });
  };

  return loading ? (
    <CentredProgressIndicator />
  ) : (
    <Paper style={m(styles.container, style)}>
      <Text size={21}>Edit Event Form</Text>
      <EventEditForm id={id} data={data} errors={errs} onChange={handleDataChange} setErrs={setErrs} />
      <View style={styles.actions} row={true} justifyContent="space-between">
        <Button
          style={styles.actionBtn}
          disabled={processing}
          variant="text"
          color="default"
          onClick={handleResetPress}
        >
          Reset
        </Button>
        <View flex="1" row={true} justifyContent="flex-end">
          <Button
            style={{ ...styles.actionBtn, ...styles.label }}
            disabled={processing}
            variant="contained"
            color="secondary"
            onClick={handleRemovePress}
          >
            Remove
          </Button>
          <Button
            style={styles.actionBtn}
            disabled={processing}
            variant="contained"
            color="default"
            onClick={handleCancelPress}
          >
            Cancel
          </Button>
          <Button
            style={styles.actionBtn}
            variant="contained"
            color="primary"
            disabled={processing}
            onClick={handleSubmitPress}
          >
            {!processing ? `Save` : <ButtonProgress />}
          </Button>
        </View>
      </View>
    </Paper>
  );
};

const styles: Styles = {
  container: {
    padding: 20,
  },
  row: {
    marginTop: 6,
    marginBottom: 6,
  },
  actions: {
    marginTop: 20,
  },
  actionBtn: {
    marginLeft: 6,
    width: 100,
  },
  label: {
    color: colors.white,
  },
};

export type DashboardEventEditSceneProps = Props;
export default withRecurringOptions(DashboardEventEditScene);
