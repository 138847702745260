import React, { CSSProperties, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: theme.spacing(3),
  },
}));

interface InjectedProps {
  style?: CSSProperties;
}

const SectionCard: FC<InjectedProps> = ({ children, style }) => {
  const classes = useStyles();

  return (
    <Box style={style} className={classes.container}>
      {children}
    </Box>
  );
};

export default SectionCard;
