import { Button, Paper } from '@material-ui/core';
import { ButtonProgress } from 'components/Buttons';
import { useSnackbar, View, PageTitle, Text } from 'components/Common';
import {
  CompaniesEditForm,
  CompaniesEditFormData,
  CompaniesEditFormErrors,
  getCompaniesEditFormErrors,
} from 'components/Companies';
import { getDefCompaniesEditFormData, polishCompaniesEditFormData } from 'components/Companies/EditForm/utils';
import { Company, CreateCompanyInput, useCreateCompanyMutation } from 'core/api';
import { getStorageVal } from 'core/storage';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import { m, StyleProps, Styles, colors } from 'styles';
import { errToStr, Log } from 'utils';

const log = Log('screen.DashboardCompaniesEdit.scenes.Create');

interface Props extends StyleProps {
  companies?: Company[];
}

const draftStorage = getStorageVal<CompaniesEditFormData>('companies:edit:draft');

export const DashboardCompaniesCreateScene: FC<Props> = ({ style, companies = [] }) => {
  // Form

  const [data, setData] = useState<CompaniesEditFormData>(draftStorage.get() || getDefCompaniesEditFormData());
  const [errs, setErrs] = useState<CompaniesEditFormErrors | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);

  const createCompany = useCreateCompanyMutation();

  // Navigation

  const history = useHistory();

  // UI

  const snackbar = useSnackbar();

  // Handlers

  const handleDataChange = (value: CompaniesEditFormData) => {
    setErrs(undefined);
    const modVal = polishCompaniesEditFormData(value);
    setData(modVal);
    log.debug('data changed, value=', modVal);
    draftStorage.set(modVal);
  };

  const handleSubmitPress = async () => {
    try {
      const curErrs = getCompaniesEditFormErrors(data);
      if (curErrs) {
        return setErrs(curErrs);
      }
      const {
        name,
        type,
        websiteUrl,
        companyId,
        logo,
        location,
        background,
        tagLine,
        resourceCenterName,
        teamCreatorId,
      } = data;
      if (!name || !type || !logo || !background || !tagLine || !teamCreatorId) {
        return;
      }
      const input: CreateCompanyInput = {
        name,
        type,
        websiteUrl,
        logo,
        companyId,
        location,
        background,
        tagLine,
        resourceCenterName,
        teamCreatorId,
      };

      log.info('creating new company, input=', input);
      setProcessing(true);
      await createCompany(input);
      setProcessing(false);
      log.info('creating new company done, input=', input);
      draftStorage.remove();
      snackbar.success('The new company created successfully');
      history.goBack();
    } catch (err) {
      setProcessing(false);
      log.err(err);
      snackbar.err(errToStr(err) || 'Unknown error');
    }
  };

  const handleCancelPress = () => {
    log.debug('handle cancel press');
    history.goBack();
  };

  const handleResetPress = () => {
    log.debug('handle reset press');
    if (confirm('Are you sure you want to reset this form?')) {
      handleDataChange(getDefCompaniesEditFormData());
    }
  };

  return (
    <>
      <PageTitle title="Companies" />
      <View style={styles.header} row={true} alignItems="center" justifyContent="space-between">
        <View style={styles.title} row={true}>
          <SettingsIcon style={{ color: colors.hoverGreen, marginRight: 10 }} />
          <Text size={24}>Set Up A New Company</Text>
        </View>
        <View style={styles.actions} row={true} justifyContent="flex-end" alignItems="start">
          <Button
            style={styles.actionBtn}
            disabled={processing}
            variant="contained"
            color="default"
            onClick={handleResetPress}
          >
            Reset
          </Button>
          <Button
            style={styles.actionBtn}
            disabled={processing}
            variant="contained"
            color="default"
            onClick={handleCancelPress}
          >
            Cancel
          </Button>
          <Button
            style={styles.actionBtn}
            variant="contained"
            color="primary"
            disabled={processing}
            onClick={handleSubmitPress}
          >
            {!processing ? `Save` : <ButtonProgress />}
          </Button>
        </View>
      </View>
      <Paper style={m(styles.container, style)}>
        <CompaniesEditForm data={data} errors={errs} companies={companies} onChange={handleDataChange} />
      </Paper>
    </>
  );
};

const styles: Styles = {
  container: {
    padding: 20,
  },
  row: {
    marginTop: 6,
    marginBottom: 6,
  },
  actions: {
    marginTop: 20,
  },
  actionBtn: {
    marginLeft: 6,
    width: 100,
    borderRadius: 4,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  header: {
    marginTop: 10,
    marginBottom: 20,
  },
};

export type DashboardCompaniesCreateSceneProps = Props;
export default DashboardCompaniesCreateScene;
