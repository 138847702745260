/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation, useQuery } from '@apollo/client';
import { errToStr } from 'utils';
import { fields } from './fields';
import {CreateEventInput, UpdateEventInput, EventsFeed, Event, EventUpdateDeleteOptions} from './types';
import { graphQlErrorsToStr } from './utils';

interface EventsQueryVars {
  publicTeamId?: number;
  limit?: number;
  id?: number;
}

export const useGetEventByIdQuery = ({ publicTeamId, id }: EventsQueryVars) => {
  const { data, loading, error } = useQuery<{ event: Event }, EventsQueryVars>(
    gql` query event($id: Int!) {
        event(id: $id) {
            ${fields.event}
        }
    }`,
    {
      context: {
        headers: { 'X-MyWays-Team-ID': publicTeamId },
      },
      variables: { id },
      fetchPolicy: 'cache-and-network',
    },
  );
  return { data: data ? data.event : undefined, loading, error: error ? errToStr(error) : undefined };
};

export const useGetEventsQuery = ({ publicTeamId, limit = 20 }: EventsQueryVars) => {
  const { data, loading, error } = useQuery<{ events: EventsFeed }, EventsQueryVars>(
    gql`query events($limit: Int, $cursor: String, $filter: EventsFilter, $feedType: String) {
        events(
            limit: $limit,
            cursor: $cursor,
            filter: $filter,
            feedType: $feedType
        ) @connection(key: "events", filter: [feedType]) {
            ${fields.eventFeed}
        }
    }`,
    {
      context: {
        headers: { 'X-MyWays-Team-ID': publicTeamId },
      },
      variables: { limit },
      fetchPolicy: 'cache-and-network',
    },
  );
  return { data: data ? data.events : undefined, loading, error: error ? errToStr(error) : undefined };
};

export const useCreateEventMutation = ({ publicTeamId }: EventsQueryVars) => {
  const [mutation] = useMutation<{ createEvent: CreateEventInput }, { input: CreateEventInput }>(
    gql`mutation createEvent($input: CreateEventInput!){
    createEvent(input: $input){
      ${fields.event}
    }
  }`,
    {
      context: {
        headers: { 'X-MyWays-Team-ID': publicTeamId },
      },
    },
  );

  return async (input: CreateEventInput): Promise<CreateEventInput> => {
    const { data, errors } = await mutation({ variables: { input } });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    }
    if (data && data.createEvent) {
      return data.createEvent;
    } else {
      throw new Error('Response is empty');
    }
  };
};

export const useEditEventMutation = ({ publicTeamId }: EventsQueryVars) => {
  const [mutation] = useMutation<{ updateEvent: UpdateEventInput }, { input: UpdateEventInput }>(
    gql`mutation($input: UpdateEventInput!){
    updateEvent(input: $input){
        ${fields.event}
    }
}`,
    {
      context: {
        headers: { 'X-MyWays-Team-ID': publicTeamId },
      },
    },
  );

  return async (input: UpdateEventInput): Promise<UpdateEventInput> => {
    const { data, errors } = await mutation({ variables: { input } });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    }
    if (data && data.updateEvent) {
      return data.updateEvent;
    } else {
      throw new Error('Response is empty');
    }
  };
};

export const useCreateMultipleEventsMutation = ({ publicTeamId }: EventsQueryVars) => {
  const [mutation] = useMutation<
    { createMultipleEvents: { [index: string]: any }[] },
    { input: { [index: string]: any }[] }
  >(
    gql`mutation createMultipleEvents($input: [CreateEventInput!]){
    createMultipleEvents(input: $input){
      ${fields.event}
    }
  }`,
    {
      context: {
        headers: { 'X-MyWays-Team-ID': publicTeamId },
      },
    },
  );

  return async (input: { [index: string]: any }[]): Promise<{ [index: string]: any }[]> => {
    const { data, errors } = await mutation({ variables: { input } });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    }
    if (data && data.createMultipleEvents) {
      return data.createMultipleEvents;
    } else {
      throw new Error('Response is empty');
    }
  };
};

export const useRemoveEventMutation = ({ publicTeamId }: EventsQueryVars) => {
  const [mutation] = useMutation<{ deleteEvent: number }, { id: number, type: EventUpdateDeleteOptions }>(
    gql`
      mutation deleteEvent($id: Int!, $type: EventUpdateDeleteOptions) {
        deleteEvent(id: $id, type: $type)
      }
    `,
    {
      context: {
        headers: { 'X-MyWays-Team-ID': publicTeamId },
      },
    }
  );
  return async (id: number, type: EventUpdateDeleteOptions): Promise<void> => {
    const { errors } = await mutation({ variables: { id, type } });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    } else {
      return;
    }
  };
};
