import React, { FC, MutableRefObject, useImperativeHandle, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface ModalConfig {
  title: string;
  content?: string;
  onCancel?: () => void;
  onConfirm: () => void;
  cancelText?: string;
  confirmText?: string;
  confirmBackgroundColor?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  type?: 'confirmation' | 'notification';
}

export interface ConfirmationModalRef {
  show: (config: ModalConfig) => void;
  close: () => void;
}

interface InjectedProps {
  modalRef: MutableRefObject<ConfirmationModalRef | undefined>;
}

const useStyles = makeStyles(() => ({
  dialogTitle: {
    paddingTop: 25,
    paddingBottom: 0,
    paddingLeft: 40,
    paddingRight: 40,
    textAlign: 'center',
  },
  dialogContent: {
    paddingTop: 22,
    paddingBottom: 22,
    paddingLeft: 40,
    paddingRight: 40,
    textAlign: 'center',
  },
  dialogActions: {
    justifyContent: 'center',
    paddingTop: 0,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 40,
  },
}));

const Modal: FC<InjectedProps> = ({ modalRef }) => {
  const classes = useStyles();
  const [config, setConfig] = useState<ModalConfig>();

  useImperativeHandle(modalRef, () => ({
    show: (config: ModalConfig) => {
      setConfig(config);
    },
    close: () => setConfig(undefined),
  }));

  const handleClose = () => {
    setConfig(undefined);
  };

  const handleConfirmClick = () => {
    config?.onConfirm();
    handleClose();
  };
  const handleCancelClick = () => {
    config?.onCancel && config?.onCancel();
    handleClose();
  };

  const renderActions = () => {
    const nodes = [
      <Button key="cancel" onClick={handleCancelClick} variant="outlined" disableElevation>
        {config?.cancelText || 'Cancel'}
      </Button>,
      <Button
        key="confirm"
        style={{ backgroundColor: config?.confirmBackgroundColor }}
        autoFocus
        onClick={handleConfirmClick}
        color="primary"
        variant="contained"
        disableElevation
      >
        {config?.confirmText || 'Confirm'}
      </Button>,
    ];

    switch (config?.type) {
      case 'confirmation':
        return nodes;
      case 'notification':
        return nodes[1];
      default:
        return nodes;
    }
  };

  return (
    <>
      <Dialog maxWidth={config?.maxWidth || 'sm'} onClose={handleCancelClick} open={!!config}>
        <DialogTitle className={classes.dialogTitle}>{config?.title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography>{config?.content}</Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>{renderActions()}</DialogActions>
      </Dialog>
    </>
  );
};

export default Modal;
