import React from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { GET_USER } from 'core/apollo/queries/users';
import UserProfileCell from 'components/Users/UserProfileCell';

const UserProfile = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading } = useQuery(GET_USER, {
    variables: {
      id,
    },
  });

  if (loading) return <CircularProgress />;

  return <UserProfileCell user={data?.user} />;
};

export default UserProfile;
