import { Grid } from '@material-ui/core';
import { FormImgPickerField, FormSelectField, FormTextField } from 'components/Form';
import { View } from 'components/Common';
import { CreateCompanyInput, CompanyType, companyTypeToStr, Company, AttachmentType, User } from 'core/api';
import React, { ChangeEvent, FC } from 'react';
import { useParams } from 'react-router-dom';
import { m, StyleProps, Styles } from 'styles';
import { isEmpty } from 'lodash';
import { STRING_VALUES } from 'strings';
import FormSelectAutocompleteField from 'components/Form/SelectAutocompleteField';
import { useQuery } from '@apollo/client';
import { GET_USERS } from 'core/apollo/query/users';

type FormData = Partial<CreateCompanyInput>;
type FormErrors = Partial<Record<keyof FormData, string>>;
type FormHelperTexts = Partial<Record<keyof FormData, string>>;

interface Props extends StyleProps {
  data: FormData;
  companies: Company[];
  errors?: FormErrors;
  onChange: (v: FormData) => void;
}
const helpTexts: FormHelperTexts = {
  tagLine: 'Short tagline below the logo in the benefits center.(7 to 15 words; 50-100 chars)',
  background: 'Background image displays in benefits center.',
  resourceCenterName:
    'This will appear in the side menu and team profile and direct users to your company-specific resources. (3 to 5 characters) ',
};

export const CompaniesEditForm: FC<Props> = ({ style, data, errors, companies, onChange }) => {
  const { id } = useParams<{ id: string | undefined }>();
  const { data: usersData, loading } = useQuery(GET_USERS, { variables: { limit: 50, offset: 0 } });

  const {
    name = '',
    websiteUrl = '',
    type: companyType,
    companyId,
    logo,
    tagLine = '',
    background,
    accessKeys,
    resourceCenterName = '',
    teamCreatorId,
  } = data;

  // Handlers

  const handleDataChange = <K extends keyof FormData>(k: K) => (val: FormData[K]) => {
    onChange({ ...data, [k]: val });
  };

  const handleTextFieldChanged = (k: keyof FormData) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    onChange({ ...data, [k]: value });
  };

  // Render

  return (
    <Grid style={m(styles.container, style)} container={true} spacing={2}>
      <Grid item={true} md={6}>
        <FormTextField
          style={styles.row}
          label="Name"
          fullWidth={true}
          required={true}
          InputProps={{ inputProps: { maxLength: 50 } }}
          error={!!errors?.name}
          helperText={errors?.name}
          placeholder="CircleOf"
          value={name}
          onChange={handleTextFieldChanged('name')}
        />
        <FormTextField
          style={styles.row}
          label="Website"
          fullWidth={true}
          error={!!errors?.websiteUrl}
          helperText={errors?.websiteUrl}
          placeholder="https://circleof.com/"
          value={websiteUrl}
          onChange={handleTextFieldChanged('websiteUrl')}
        />
        <FormSelectField<CompanyType>
          style={styles.row}
          label="Type"
          variant="outlined"
          fullWidth={true}
          required={true}
          error={!!errors?.type}
          helperText={errors?.type}
          items={['CORPORATE', 'SLF']}
          keyExtractor={itm => itm}
          titleExtractor={companyTypeToStr}
          value={companyType}
          onChange={handleDataChange('type')}
        />
        <FormSelectField
          style={styles.row}
          label="Parent company"
          variant="outlined"
          fullWidth={true}
          required={true}
          error={!!errors?.companyId}
          helperText={errors?.companyId}
          items={companies.map(itm => itm.id)}
          keyExtractor={id => `${id}`}
          titleExtractor={id => companies.find(itm => itm.id === id)?.name || `${id}`}
          value={companyId}
          onChange={handleDataChange('companyId')}
        />
        <FormTextField
          style={styles.row}
          required={true}
          label="Tagline"
          multiline={true}
          fullWidth={true}
          InputProps={{ inputProps: { maxLength: 180 } }}
          error={!!errors?.tagLine}
          helperText={errors?.tagLine || helpTexts.tagLine}
          rows={2}
          placeholder=""
          value={tagLine}
          onChange={handleTextFieldChanged('tagLine')}
        />
        <FormTextField
          style={styles.row}
          label="Custom Resource Center Name"
          fullWidth={true}
          InputProps={{ inputProps: { maxLength: 30 } }}
          error={!!errors?.resourceCenterName}
          helperText={errors?.resourceCenterName || helpTexts.resourceCenterName}
          placeholder="Resource Center"
          value={resourceCenterName}
          onChange={handleTextFieldChanged('resourceCenterName')}
        />

        <FormSelectAutocompleteField<User>
          style={styles.row}
          defaultValue={usersData?.users.find((u: User) => u.id === teamCreatorId)}
          loading={loading}
          onChange={user => handleDataChange('teamCreatorId')(user?.id)}
          hasError={!!errors?.teamCreatorId}
          keyExtractor={(option: User) => `${option.firstName} ${option.lastName} (${option.email}}`}
          options={usersData?.users || []}
          label="Organization Team creator"
        />
      </Grid>
      <Grid item={true} md={6}>
        <FormImgPickerField
          style={styles.row}
          required={true}
          label="Logo"
          value={logo?.uri || logo?.url}
          error={!!errors?.logo}
          helperText={errors?.logo}
          placeholder={`Drag 'n' drop image here, or click to select.\nImage specs: 100x100 px.`}
          onChange={val => onChange({ ...data, logo: { type: AttachmentType.IMAGE, uri: val } })}
        />
        <FormImgPickerField
          style={styles.row}
          required={true}
          label="Background Image"
          value={background?.uri || background?.url}
          error={!!errors?.background}
          helperText={errors?.background || helpTexts.background}
          size={120}
          placeholder={`Drag 'n' drop image here, or click to select.\nImage specs: 750x485 px.`}
          onChange={val => onChange({ ...data, background: { type: AttachmentType.IMAGE, uri: val } })}
        />
        {id !== 'new' && (
          <View>
            <View style={styles.text}>Access Keys</View>
            {!isEmpty(accessKeys) ? accessKeys?.map(s => s.key).join(', ') : STRING_VALUES.NO_ACCESS_KEYS}
          </View>
        )}
      </Grid>
    </Grid>
  );
};

const styles: Styles = {
  container: {},
  row: {
    marginTop: 8,
    marginBottom: 20,
  },
  text: {
    marginTop: 20,
    marginBottom: 8,
  },
};

export * from './types';
export * from './utils';
export type CompaniesEditFormProps = Props;
export default CompaniesEditForm;
