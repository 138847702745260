import { Paper, Link } from '@material-ui/core';
import { DataTable } from 'components/Tables';
import { companyTypeToStr, Company, AccessKey } from 'core/api';
import { routes } from 'screens/consts';
import { Text } from 'components/Common';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { colors, mx, m, StyleProps, Styles } from 'styles';

interface Props extends StyleProps {
  companies: Company[];
  id: number;
}

export const ChildCompanyList: FC<Props> = ({ companies, id }) => {
  // Deps

  const history = useHistory();

  // Data

  const filterItemsWithSearch = (search: string, items: Company[]): Company[] => {
    const searchMod = search.toLowerCase().trim();
    return items.filter(itm => {
      const { name } = itm;
      const nameMod = name ? name.toLowerCase().trim() : '';
      return nameMod.indexOf(searchMod) >= 0;
    });
  };

  // Handlers

  const handleItemPress = (item: Company) => {
    history.push({
      pathname: `${routes.companies.default}/${id}/${item.id}`,
      state: { page: 'childCompany' },
    });
  };

  // Render

  const renderLink = (val: string | undefined) =>
    !val ? null : (
      <Link style={styles.link} href={val} target="__blank">
        {val}
      </Link>
    );

  const renderParentCompany = (companyId: number | undefined): string => {
    if (!companyId) {
      return '';
    }
    const ex = companies.find(itm => itm.id === companyId);
    return ex ? ex.name : '';
  };

  const renderLogo = (url: string | undefined) => (url ? <img style={{ width: 25, height: 25 }} src={url} /> : null);

  const renderAccessKeys = (accessKeys: AccessKey[] | undefined): string => {
    if (!accessKeys) {
      return '';
    }
    return accessKeys.map(s => s.key).join(', ');
  };

  // Render

  return (
    <Paper style={m(styles.container)}>
      <Text size={21}>Children Companies</Text>
      <DataTable
        title="Companies"
        items={companies}
        bottomBorder="line"
        searchPlaceholder="Search name..."
        filterWithSearch={filterItemsWithSearch}
        columns={{
          id: {
            title: 'Company ID#',
            component: 'th',
            scope: 'row',
            valExtractor: val => `${val}`,
          },
          logo: {
            title: 'Logo',
            valExtractor: renderLogo,
          },
          name: {
            title: 'Name',
            valExtractor: val => val,
          },
          type: {
            title: 'Type',
            valExtractor: companyTypeToStr,
          },
          url: {
            title: 'Website',
            valExtractor: renderLink,
          },
          companyId: {
            title: 'Parent company',
            valExtractor: renderParentCompany,
          },
          accessKeys: {
            title: 'Access Keys',
            valExtractor: renderAccessKeys,
          },
        }}
        onItemPress={handleItemPress}
      />
    </Paper>
  );
};

const styles: Styles = {
  container: {
    padding: 20,
    marginTop: 20,
  },
  row: {
    marginTop: 8,
    marginBottom: 20,
  },
  table: {
    ...mx.w100,
  },
  link: {
    color: colors.hoverGreen,
    textDecoration: 'underline',
  },
};

export type ChildCompanyListProps = Props;
export default ChildCompanyList;
