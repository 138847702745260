const app = {
  auth: {
    base: '/auth',
    entry: '/auth/entry',
    reset_init: '/auth/reset/init',
    reset_finish: '/auth/reset/finish',
  },
  resources: {
    default: '/resources',
    list: '/resources',
    create: '/resources/create',
    edit: '/resources/edit',
  },
  admins: {
    default: '/admins',
    list: '/admins',
    create: '/admins/create',
    edit: '/admins/edit',
    profile: '/admins/profile',
    change_password: '/admins/password/change',
  },
  users: {
    default: '/users',
    list: '/users',
    profile: '/users/profile',
    create: '/users/create',
    edit: '/users/edit',
  },
  announcements: {
    default: '/announcements',
    list: '/announcements',
  },
  companies: {
    default: '/companies',
    list: '/companies',
  },
};

const named = {
  entry: app.auth.entry,
  home: app.resources.list,
};

export const routes = {
  ...app,
  ...named,
};
