import React, { FC, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import SectionTitle from 'components/SectionTitle';
import { Button } from '@material-ui/core';
import { ResourceStage } from 'core/apollo/consts';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Modal, { ConfirmationModalRef } from 'components/Modal';
import { useAuth } from '../../../hooks/useAuth';
import { isContentPartner, isSuperAdmin } from 'utils/permissions';
import { RESOURCE_ACTION_TEXT } from 'core/constants';
import { Resource } from 'core/api';
import { isPublishAllowed, isReadyForReviewAllowed, isUnPublishAllowed } from 'utils/resources';

const useStyles = makeStyles(() => ({
  sectionHeader: {
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface InjectedProps {
  onClick: (stage: ResourceStage) => void;
  onCancel?: () => void;
  loading?: ResourceStage;
  isDirty: boolean;
  resource?: Resource | null;
}

const ResourceFormHeader: FC<InjectedProps> = ({ onClick, onCancel, loading, isDirty, resource }) => {
  const classes = useStyles();
  const modalRef = useRef<ConfirmationModalRef>();
  const { user } = useAuth();

  const handleSaveDraftClick = () => onClick(ResourceStage.DRAFT);
  const handleReadyForReviewClick = () => onClick(ResourceStage.READY_FOR_REVIEW);
  const handlePublishClick = () => {
    modalRef.current?.show({
      title: RESOURCE_ACTION_TEXT.PUBLISH.TITLE,
      content: RESOURCE_ACTION_TEXT.PUBLISH.DESCRIPTION,
      cancelText: RESOURCE_ACTION_TEXT.PUBLISH.CANCEL_TEXT,
      confirmText: RESOURCE_ACTION_TEXT.PUBLISH.CONFIRM_TEXT,
      onCancel: () => null,
      onConfirm: () => onClick(ResourceStage.PUBLISHED),
    });
  };
  const handleUnPublishClick = () => {
    modalRef.current?.show({
      title: RESOURCE_ACTION_TEXT.UNPUBLISH.TITLE,
      content: RESOURCE_ACTION_TEXT.UNPUBLISH.DESCRIPTION,
      cancelText: RESOURCE_ACTION_TEXT.UNPUBLISH.CANCEL_TEXT,
      confirmText: RESOURCE_ACTION_TEXT.UNPUBLISH.CONFIRM_TEXT,
      onCancel: () => null,
      onConfirm: () => onClick(ResourceStage.UNPUBLISHED),
    });
  };
  const handleCancelClick = () => {
    if (isDirty) {
      modalRef.current?.show({
        title: RESOURCE_ACTION_TEXT.CANCEL.TITLE,
        content: RESOURCE_ACTION_TEXT.CANCEL.DESCRIPTION,
        cancelText: RESOURCE_ACTION_TEXT.CANCEL.CANCEL_TEXT,
        confirmText: RESOURCE_ACTION_TEXT.CANCEL.CONFIRM_TEXT,
        onCancel,
        onConfirm: handleSaveDraftClick,
      });

      return;
    }

    onCancel && onCancel();
  };

  return (
    <Grid direction="row" justify="space-between" container className={classes.sectionHeader}>
      <Grid item>
        <SectionTitle title="Resource Information" />
      </Grid>
      <Grid item>
        <Button
          disabled={!!loading}
          style={{ marginRight: 12 }}
          onClick={handleCancelClick}
          variant="outlined"
          disableElevation
        >
          Cancel
        </Button>

        {(isSuperAdmin(user) || isContentPartner(user)) && (
          <Button
            disabled={!!loading}
            style={{ marginRight: 12 }}
            onClick={handleSaveDraftClick}
            variant="contained"
            color="primary"
            disableElevation
          >
            Save as Draft
            {loading === ResourceStage.DRAFT && (
              <CircularProgress size={14} style={{ marginLeft: 8, color: 'inherit' }} />
            )}
          </Button>
        )}

        {(isSuperAdmin(user) || isContentPartner(user)) && isReadyForReviewAllowed(resource) && (
          <Button
            disabled={!!loading}
            style={{ marginRight: 12 }}
            onClick={handleReadyForReviewClick}
            variant="contained"
            color="primary"
            disableElevation
          >
            Ready for Review
            {loading === ResourceStage.READY_FOR_REVIEW && (
              <CircularProgress size={14} style={{ marginLeft: 8, color: 'inherit' }} />
            )}
          </Button>
        )}

        {isSuperAdmin(user) && isPublishAllowed(resource) && (
          <Button
            disabled={!!loading}
            style={{ marginRight: 12 }}
            onClick={handlePublishClick}
            variant="contained"
            color="primary"
            disableElevation
          >
            Publish
            {loading === ResourceStage.PUBLISHED && (
              <CircularProgress size={14} style={{ marginLeft: 8, color: 'inherit' }} />
            )}
          </Button>
        )}

        {isSuperAdmin(user) && isUnPublishAllowed(resource) && (
          <Button
            disabled={!!loading}
            onClick={handleUnPublishClick}
            variant="contained"
            color="primary"
            disableElevation
          >
            Un-publish
            {loading === ResourceStage.UNPUBLISHED && (
              <CircularProgress size={14} style={{ marginLeft: 8, color: 'inherit' }} />
            )}
          </Button>
        )}
      </Grid>

      <Modal modalRef={modalRef} />
    </Grid>
  );
};

export default ResourceFormHeader;
