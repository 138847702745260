import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface InjectedProps {
  title: string;
  containerClassName?: string;
  textClassName?: string;
  backgroundColor?: string;
  textColor?: string;
  style?: any;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 21,
    padding: '4px 8px',
    borderRadius: 4,
  },
  text: {
    fontWeight: 500,
    fontSize: 11,
  },
}));

const Tag: FC<InjectedProps> = ({ title, backgroundColor = '#E1E9E8', textColor = '#fff', style }) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={{ backgroundColor, ...style }}>
      <span style={{ color: textColor }} className={classes.text}>
        {title}
      </span>
    </div>
  );
};

export default Tag;
