const shortcuts = {
  w100: {
    width: '100%',
  },
  f1: {
    flex: 1,
  },
};

export const mx = {
  ...shortcuts,
};
