import { gql, useMutation } from '@apollo/client';

import { fields } from './fields';
import { Authorized } from './types';
import { graphQlErrorsToStr } from './utils';
import { SimpleStatus } from 'core/api';

interface SignInMutationVars {
  email: string;
  password: string;
}

export const useSignInMutation = () => {
  const [mutation] = useMutation<{ login: Authorized }, { email: string; password: string }>(
    gql`mutation ($email: String!, $password: String!, $guard: String = "admin"){
    login(email: $email, password: $password, guard: $guard){
      ${fields.authorized}
    }
  }`,
  );
  return async (variables: SignInMutationVars): Promise<Authorized> => {
    const { data, errors } = await mutation({ variables, fetchPolicy: 'no-cache' });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    }
    if (data && data.login) {
      return data.login;
    } else {
      throw new Error('Response is empty');
    }
  };
};

export const forgotPassword = () => {
  const [mutation] = useMutation<{ forgotPassword: SimpleStatus }, { email: string }>(
    gql`
      mutation($email: String!, $guard: Guard) {
        forgotPassword(email: $email, guard: $guard) {
          status
        }
      }
    `,
  );
  return async (variables): Promise<SimpleStatus> => {
    const { data, errors } = await mutation({ variables: { ...variables, guard: 'admin' } });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    }
    if (data && data.forgotPassword) {
      return data.forgotPassword;
    } else {
      throw new Error('Response is empty');
    }
  };
};
