import React, { useRef } from 'react';
import { isNil } from 'lodash';
import UserProfileCell, { UserProfileMenuType } from 'components/Users/UserProfileCell';
import { MenuButtonOption } from 'components/MenuButton';
import { routes } from 'screens/consts';
import { useHistory, useParams } from 'react-router';
import { ApolloCache, useMutation, useQuery } from '@apollo/client';
import { GET_ADMIN_USER } from 'core/apollo/queries/users';
import { CircularProgress } from '@material-ui/core';
import { AdminUser } from 'core/api';
import Modal, { ConfirmationModalRef } from 'components/Modal';
import { REMOVE_ADMIN_USER } from 'core/apollo/mutation/users';

const AdminProfile = () => {
  const modalRef = useRef<ConfirmationModalRef>();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { data, loading } = useQuery<{ adminUser: AdminUser }>(GET_ADMIN_USER, {
    variables: {
      id,
    },
  });
  const [removeAdminUser] = useMutation(REMOVE_ADMIN_USER, {
    ignoreResults: true,
    onCompleted: () => {
      history.push(routes.admins.list);
    },
  });

  const handleMenuItemClick = (option: MenuButtonOption) => {
    switch (option.type as UserProfileMenuType) {
      case UserProfileMenuType.EDIT: {
        history.push(`${routes.admins.edit}/${data?.adminUser?.id}`, {
          backRoute: `${routes.admins.profile}/${data?.adminUser?.id}`,
        });
        return;
      }

      case UserProfileMenuType.REMOVE: {
        modalRef.current?.show({
          title: 'Remove User',
          content: 'Are you sure you want to remove this user? This process cannot be undone.',
          confirmText: 'Remove',
          cancelText: 'Cancel',
          onConfirm: () =>
            removeAdminUser({
              variables: {
                id: parseInt(id, 10),
              },
              optimisticResponse: {
                removeAdminUser: parseInt(id, 10),
              },
              update: (cache: ApolloCache<any>) => {
                const normalizedId = cache.identify({ id, __typename: 'AdminUser' });
                cache.evict({ id: normalizedId });
                cache.gc();
              },
            }),
          confirmBackgroundColor: '#DD4A4A',
        });

        return;
      }
    }

    return;
  };

  if (loading) return <CircularProgress />;

  const user = data?.adminUser;

  if (isNil(user)) return null;

  return (
    <>
      <UserProfileCell onMenuClick={handleMenuItemClick} user={user} />
      <Modal modalRef={modalRef} />
    </>
  );
};

export default AdminProfile;
