import React, { FC } from 'react';
import { map, get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import RHInput from 'components/Form/RHInput';
import { v4 as uuid } from 'uuid';
import Grid from '@material-ui/core/Grid';
import RHCheckbox from 'components/Form/RHCheckbox';
import { MerchantStatus, ResourceStatus } from 'core/apollo/consts';
import RHSelect from 'components/Form/RHSelect';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPANIES } from 'core/apollo/queries/companies';
import { Company, MerchantProgram } from 'core/api';
import { GET_MERCHANT_PROGRAMS } from 'core/apollo/queries/merchant';
import { CREATE_MERCHANT_PROGRAM } from 'core/apollo/mutation/merchant';
import { merchantProgramCreatedResolver } from 'core/apollo/mutation/resolvers';
import { UseFormReturn } from 'react-hook-form';
import { humanizeMerchantStatus, humanizeResourceStatus } from 'utils/str';
import { isSuperAdmin } from 'utils/permissions';
import { useAuth } from '../../../../../hooks/useAuth';

interface InjectedProps {
  form: UseFormReturn<any>;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Roboto',
    margin: 0,
    color: '#15374E',
  },
  content: {
    marginTop: 25,
    display: 'flex',
    flexDirection: 'row',
  },
  box: {},
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxesLabel: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#15374E',
  },
}));

const ResourceMerchantForm: FC<InjectedProps> = ({ form: { control, setValue, setError, resetField }, disabled }) => {
  const classes = useStyles();
  const { user } = useAuth();

  const { loading: companiesLoading, data: companiesResponse } = useQuery<{ companies: Company[] }>(GET_COMPANIES, {
    onCompleted: data => {
      resetField('company', {
        defaultValue: data.companies.find(c => c.name === 'CircleOf'),
      });
    },
  });
  const { loading: programsLoading, data: programsResponse } = useQuery<{ merchantPrograms: MerchantProgram[] }>(
    GET_MERCHANT_PROGRAMS,
  );

  const [createMerchantProgram] = useMutation(CREATE_MERCHANT_PROGRAM, {
    update: merchantProgramCreatedResolver,
    ignoreResults: true,
  });

  const statusOptions = map(ResourceStatus, (v, k) => ({ name: humanizeResourceStatus(v), id: k }));
  const merchantStatusOptions = map(MerchantStatus, (v, k) => ({ name: humanizeMerchantStatus(v), id: k }));

  const handleMerchantProgramCreate = async (label: string) => {
    try {
      const response = await createMerchantProgram({
        variables: {
          input: {
            id: uuid,
            name: label,
          },
        },
      });
      setValue('merchantProgram', get(response, ['data', 'createMerchantProgram']));
    } catch (e) {
      setError('merchantProgram', e.message);
    }
  };

  return (
    <div>
      <h3 className={classes.title}>Merchant Information</h3>

      <Grid spacing={3} container className={classes.content}>
        <Grid item className={classes.box} xs={12} md={6}>
          <RHInput
            disabled={disabled}
            control={control}
            placeholder="Title/Merchant Name"
            name="merchantName"
            label="Title/Merchant Name"
            rules={{ required: 'Merchant name missing' }}
          />

          <RHSelect
            disabled={disabled}
            control={control}
            topIndent
            placeholder="Status"
            name="status"
            label="Status"
            options={statusOptions}
            rules={{ required: true }}
          />

          <RHSelect
            disabled={disabled}
            control={control}
            topIndent
            placeholder="Program"
            name="merchantProgram"
            label="Program"
            rules={{ required: true }}
            options={programsResponse?.merchantPrograms}
            selectProps={{
              onCreateOption: handleMerchantProgramCreate,
              isCreatable: true,
              isLoading: programsLoading,
            }}
          />

          <RHInput
            disabled={disabled}
            control={control}
            topIndent
            placeholder="Link to Merchant’s PPC Keyword Rules"
            name="merchantPPCLink"
            label="Link to Merchant’s PPC Keyword Rules"
          />

          <RHSelect
            disabled={disabled}
            control={control}
            topIndent
            placeholder="Merchant Status"
            name="merchantStatus"
            label="Merchant Status"
            rules={{ required: true }}
            options={merchantStatusOptions}
          />
        </Grid>
        <Grid item className={classes.box} xs={12} md={6}>
          <RHSelect
            disabled={disabled || !isSuperAdmin(user)}
            control={control}
            selectProps={{
              isLoading: companiesLoading,
            }}
            helperText="Set by default to CircleOf"
            placeholder="Company"
            name="company"
            label="Company"
            options={companiesResponse?.companies}
            rules={{ required: true }}
          />

          <div className={classes.checkboxesContainer}>
            <label className={classes.checkboxesLabel}>
              Where to post?<span style={{ color: '#DD4A4A' }}>*</span>
            </label>
            <div className={classes.checkboxes}>
              <RHCheckbox disabled={disabled} control={control} label="App" name="isApp" />
              <RHCheckbox disabled={disabled} control={control} label="Website" name="isWeb" />
            </div>
          </div>

          <RHInput
            disabled={disabled}
            control={control}
            topIndent
            multiline
            rows={4}
            placeholder="Add Internal Notes..."
            name="additionalNote"
            label="Additional Note"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ResourceMerchantForm;
