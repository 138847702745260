import { gql } from '@apollo/client';
import { fields } from 'core/api';

export const CREATE_RESOURCE = gql`mutation createResource($input: CreateResourceInput!){
    createResource(input: $input) {
        ${fields.resourceBasic}
    }
}`;

export const UPDATE_RESOURCE = gql`mutation updateResource($input: UpdateResourceInput!, $id: Int!){
    updateResource(input: $input, id: $id) {
        ${fields.resourceBasic}
    }
}`;

export const UPDATE_RESOURCE_STAGE = gql`mutation updateResourceStage($input: UpdateResourceStageInput!, $id: Int!){
    updateResourceStage(input: $input, id: $id) {
        ${fields.resourceBasic}
    }
}`;

export const DELETE_RESOURCE = gql`mutation deleteResource($id: Int!){
    deleteResource(id: $id) {
        ${fields.resourceBasic}
    }
}`;
