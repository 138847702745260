import {Grid, TextField} from '@material-ui/core';
import moment from 'moment-timezone';
import {DateTimePicker} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {View} from 'components/Common';
import {FormAnnsTypeField, FormImgPickerField, FormSelectField} from 'components/Form';
import {AccessKey, AnnouncementInput, AttachmentType, Company} from 'core/api';
import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {m, StyleProps, Styles} from 'styles';
import {isEmpty} from 'lodash';

type FormData = Partial<AnnouncementInput>;
type FormErrors = Partial<Record<keyof FormData, string>>;
type FormHelperTexts = Partial<Record<keyof FormData, string>>;

interface Props extends StyleProps {
  data: FormData;
  errors?: FormErrors;
  companies: Company[];
  onChange: (v: FormData) => void;
}

const helpTexts: FormHelperTexts = {
  type: 'The announcement text will appear next to an icon or an image.',
  title: '35 character headline (3-6 words).',
  image: 'This image is used on the expanded view. Image specs: 380x230 px.',
  thumbnail:
    'An image will appear next to an announcement. This element defaults to the bell icon. Image specs: 56x56 px.',
  description:
    '120 characters describing the issue needing user attention (about 2 sentences). Additional copy may be entered in the full description.',
  additionalDescription:
    'An additional 120 characters to provide additional context in an ‘expanded’ view of the announcement.',
  cta: '1-2 word call to action.',
  publishTypes: 'Whether to also send mobile, email, and/or in-app notifications.',
  expirationDate:
    'The app will publish announcements for a specified period of time (publish date until expiration date). The default expiration date is 7 days after the publish date.',
  link: 'Web address of any additional content.',
};

export const AnnsEditForm: FC<Props> = ({ style, data, errors, companies, onChange }) => {
  const {
    title = '',
    type,
    thumbnail,
    image,
    description = '',
    additionalDescription = '',
    cta = '',
    publishStartDate,
    publishEndDate,
    expirationDate,
    link = '',
    // 12/16/2020 note: hide for now
    // publishTypes,
    accessKeyIds,
    company,
    timezone,
  } = data;

  const [curCompany, setCurCompany] = useState<Company>();

  const curDate = new Date();

  const companiesWithKeys = companies.filter(itm => !isEmpty(itm.accessKeys));

  const accessKeyList = curCompany ? companiesWithKeys.find(itm => itm.id === curCompany.id)?.accessKeys : undefined;

  const curAccessKey =
    accessKeyIds && accessKeyIds.length && accessKeyList
      ? accessKeyList.find(itm => itm.id === accessKeyIds[0])
      : undefined;

  useEffect(() => {
    if (company) {
      setCurCompany(company);
    }
  }, []);

  // Handlres

  const handleTextFieldChanged = (k: keyof FormData) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    onChange({ ...data, [k]: value });
  };

  const handleDatePickerChanged = (k: keyof FormData) => (val: MaterialUiPickersDate) => {
    onChange({ ...data, [k]: val ? val.format() : undefined });
  };

  const handleDataChange = <K extends keyof FormData>(k: K) => (val: FormData[K]) => {
    onChange({ ...data, [k]: val });
  };

  return (
    <Grid style={m(styles.container, style)} container={true} spacing={2}>
      <Grid item={true} md={6}>
        <TextField
          style={styles.row}
          label="Headline"
          fullWidth={true}
          required={true}
          InputProps={{ inputProps: { maxLength: 35 } }}
          error={!!errors?.title}
          helperText={errors?.title || helpTexts.title}
          placeholder="COVID-19 Updates"
          value={title}
          onChange={handleTextFieldChanged('title')}
        />
        <TextField
          style={styles.row}
          label="Blurb"
          multiline={true}
          fullWidth={true}
          required={true}
          InputProps={{ inputProps: { maxLength: 180 } }}
          error={!!errors?.description}
          helperText={errors?.description || helpTexts.description}
          rows={4}
          placeholder="Announcement Blurb"
          value={description}
          onChange={handleTextFieldChanged('description')}
        />
        <TextField
          style={styles.row}
          label="Full Description"
          multiline={true}
          fullWidth={true}
          rows={4}
          placeholder="Full announcment description"
          value={additionalDescription}
          error={!!errors?.additionalDescription}
          helperText={errors?.additionalDescription || helpTexts.additionalDescription}
          onChange={handleTextFieldChanged('additionalDescription')}
        />
        <TextField
          style={styles.row}
          label="CTA Copy"
          fullWidth={true}
          placeholder="Learn More, Call Now, etc."
          value={cta}
          InputProps={{ inputProps: { maxLength: 20 } }}
          error={!!errors?.cta}
          helperText={errors?.cta || helpTexts.cta}
          onChange={handleTextFieldChanged('cta')}
        />
        {!!cta && (
          <TextField
            style={styles.row}
            type="url"
            label="Destination URL"
            fullWidth={true}
            placeholder="https://content.com/"
            error={!!errors?.link}
            helperText={errors?.link || helpTexts.link}
            value={link}
            onChange={handleTextFieldChanged('link')}
          />
        )}
        <Grid container={true} style={styles.row} spacing={2}>
          <Grid item={true} md={6}>
            <DateTimePicker
              label="Publish Start Date"
              fullWidth={true}
              required={true}
              minDate={curDate}
              format="MM/DD/YYYY hh:mmA"
              value={publishStartDate}
              error={!!errors?.publishStartDate}
              helperText={errors?.publishStartDate || helpTexts.publishStartDate}
              onChange={handleDatePickerChanged('publishStartDate')}
            />
          </Grid>
          <Grid item={true} md={6}>
            <DateTimePicker
              label="Publish End Date"
              fullWidth={true}
              minDate={publishStartDate ? publishStartDate : curDate}
              value={publishEndDate}
              format="MM/DD/YYYY hh:mmA"
              error={!!errors?.publishEndDate}
              helperText={errors?.publishEndDate || helpTexts.publishEndDate}
              onChange={handleDatePickerChanged('publishEndDate')}
            />
          </Grid>
        </Grid>
        <View style={styles.row}>
          <DateTimePicker
            label="Expiration Date"
            fullWidth={true}
            required={true}
            minDate={curDate}
            value={expirationDate}
            format="MM/DD/YYYY hh:mmA"
            error={!!errors?.expirationDate}
            helperText={errors?.expirationDate || helpTexts.expirationDate}
            onChange={handleDatePickerChanged('expirationDate')}
          />
        </View>

        <FormSelectField<string>
          style={styles.row}
          label="Timezone"
          placeholder="Select timezone"
          fullWidth={true}
          items={moment.tz.names()}
          value={timezone || moment.tz.guess(true)}
          keyExtractor={itm => itm}
          titleExtractor={itm => itm}
          error={!!errors?.timezone}
          helperText={errors?.timezone || helpTexts.timezone}
          onChange={v => onChange({ ...data, timezone: v })}
        />
      </Grid>
      <Grid item={true} md={6}>
        {/* 12/16/2020 note: As per discussion, hide publish type UI for now */}
        {/* <FormPublishTypeField
          label="Publish Type"
          required={true}
          style={styles.row}
          value={publishTypes}
          error={!!errors?.publishTypes}
          helperText={errors?.publishTypes || helpTexts.publishTypes}
          onChange={handleDataChange('publishTypes')}
        /> */}
        <FormAnnsTypeField
          style={styles.row}
          label="Type"
          required={true}
          value={type}
          error={!!errors?.type}
          helperText={errors?.type || helpTexts.type}
          onChange={handleDataChange('type')}
        />
        {type === 'IMAGE' && (
          <FormImgPickerField
            style={styles.row}
            label="Card Image"
            required={true}
            value={thumbnail?.uri || thumbnail?.url}
            error={!!errors?.thumbnail}
            helperText={errors?.thumbnail || helpTexts.thumbnail}
            placeholder={`Drag 'n' drop image here, or click to select.\nImage specs: 56x56 px.`}
            onChange={val => onChange({ ...data, thumbnail: { type: AttachmentType.IMAGE, uri: val } })}
          />
        )}
        <FormImgPickerField
          style={styles.row}
          label="Full Size Image"
          value={image?.uri || image?.url}
          required={false}
          placeholder={`Drag 'n' drop image here, or click to select.\nImage specs: 380x230 px.`}
          error={!!errors?.image}
          helperText={errors?.image || helpTexts.image}
          onChange={val => onChange({ ...data, image: { type: AttachmentType.IMAGE, uri: val } })}
        />
        <FormSelectField<Company>
          style={styles.row}
          label="Company"
          placeholder="Choose type"
          fullWidth={true}
          items={companiesWithKeys}
          value={curCompany}
          keyExtractor={itm => `${itm.id}`}
          titleExtractor={itm => itm.name}
          error={!!errors?.accessKeyIds}
          helperText={errors?.accessKeyIds || helpTexts.accessKeyIds}
          onChange={v => setCurCompany(v)}
        />
        {!!curCompany && accessKeyList && (
          <FormSelectField<AccessKey>
            style={styles.row}
            items={accessKeyList.filter(itm => itm.id)}
            label="Company Access Code"
            placeholder="Choose company access code"
            keyExtractor={itm => `${itm.id}`}
            titleExtractor={itm => itm.key}
            error={!!errors?.accessKeyIds}
            helperText={errors?.accessKeyIds || helpTexts.accessKeyIds}
            fullWidth={true}
            value={curAccessKey}
            onChange={itm => onChange({ ...data, accessKeyIds: [itm.id] })}
          />
        )}
      </Grid>
    </Grid>
  );
};

const styles: Styles = {
  container: {},
  row: {
    marginTop: 8,
    marginBottom: 8,
  },
};

export * from './utils';
export * from './types';
export type AnnsEditFormProps = Props;
export default AnnsEditForm;
