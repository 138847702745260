import React, { FC } from 'react';
import placeholder from '../../../assets/placeholder.png';
import { UseFormReturn } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import RHInput from 'components/Form/RHInput';
import { map } from 'lodash';
import RHSelect from 'components/Form/RHSelect';
import { makeStyles } from '@material-ui/core/styles';
import RHDropzone from 'components/Form/RHDropzone';
import { ROLES } from 'core/constants';
import { humanizeRole } from 'utils/str';
import { AttachmentInput } from 'core/api';

export interface UserFormRecords {
  firstName: string;
  lastName: string;
  email: string;
  company: string | null;
  role: { name: string; value: string } | null;
  iconDataURI: AttachmentInput | null;
}

interface InjectedProps {
  form: UseFormReturn<UserFormRecords>;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Roboto',
    margin: 0,
    color: '#15374E',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  box: {},
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxesLabel: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#15374E',
  },
}));

const UserEditForm: FC<InjectedProps> = ({ disabled, form }) => {
  const classes = useStyles();
  const { control } = form;

  return (
    <form>
      <Grid spacing={3} container className={classes.content}>
        <Grid item className={classes.box} xs={12} md={6}>
          <Grid spacing={3} container>
            <Grid item xs={12} md={6}>
              <RHInput
                disabled={disabled}
                control={control}
                placeholder="First Name"
                name="firstName"
                label="First Name"
                rules={{ required: 'First name missing' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHInput
                disabled={disabled}
                control={control}
                placeholder="Last Name"
                name="lastName"
                label="Last Name"
                rules={{ required: 'Last name missing' }}
              />
            </Grid>
          </Grid>

          <RHInput
            topIndent
            disabled={disabled}
            control={control}
            placeholder="Email"
            name="email"
            label="Email"
            rules={{ required: 'Email missing' }}
          />

          <RHInput
            topIndent
            disabled={disabled}
            control={control}
            placeholder="Company"
            name="company"
            label="Employer"
            rules={{ required: 'Company missing' }}
          />
        </Grid>
        <Grid item className={classes.box} xs={12} md={6}>
          <RHDropzone
            placeholder={placeholder}
            cropProps={{
              circularCrop: true,
            }}
            dimensions={{
              width: 100,
              height: 100,
            }}
            disabled={disabled}
            control={control}
            rules={{ required: false }}
            label="Profile Image"
            name="iconDataURI"
            helperText={
              <span>
                Drag ‘n’ drop image here, or click to select.
                <br />
                Image specs: 100x100 px.
              </span>
            }
          />

          <RHSelect
            disabled={disabled}
            control={control}
            topIndent
            placeholder="Role"
            name="role"
            label="User Role"
            options={map(ROLES, (v, k) => ({ value: k, name: humanizeRole(v) }))}
            rules={{ required: true }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default UserEditForm;
