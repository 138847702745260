import { Button, Paper } from '@material-ui/core';
import {
  AnnsEditForm,
  AnnsEditFormData,
  AnnsEditFormErrors,
  getAnnsEditFormErrors,
  getDefAnnsEditFormData,
  polishAnnsEditFormData,
} from 'components/Anns';
import { ButtonProgress } from 'components/Buttons';
import { useSnackbar, View } from 'components/Common';
import { AnnouncementInput, Company, useCreateAnnsMutation } from 'core/api';
import { getStorageVal } from 'core/storage';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { m, StyleProps, Styles } from 'styles';
import { errToStr, Log } from 'utils';

const log = Log('screen.DashboardAnnsEdit.scenes.Create');

interface Props extends StyleProps {
  companies: Company[];
}

const draftStorage = getStorageVal<AnnsEditFormData>('anns:edit:draft');

export const DashboardAnnsCreateScene: FC<Props> = ({ style, companies }) => {
  // Form

  const [data, setData] = useState<AnnsEditFormData>(draftStorage.get() || getDefAnnsEditFormData());
  const [errs, setErrs] = useState<AnnsEditFormErrors | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);

  const createAnns = useCreateAnnsMutation();

  // Navigation

  const history = useHistory();

  // UI

  const snackbar = useSnackbar();

  // Handlers

  const handleDataChange = (value: AnnsEditFormData) => {
    setErrs(undefined);
    const modVal = polishAnnsEditFormData(value);
    setData(modVal);
    log.debug('data changed, value=', modVal);
    draftStorage.set(modVal);
  };

  const handleSubmitPress = async () => {
    try {
      const curErrs = getAnnsEditFormErrors(data);
      if (curErrs) {
        log.debug('form errors found, errs=', curErrs);
        return setErrs(curErrs);
      }
      const {
        type,
        title,
        description,
        additionalDescription,
        cta,
        publishStartDate,
        publishEndDate,
        expirationDate,
        link,
        image,
        publishTypes,
        thumbnail,
        accessKeyIds,
        timezone,
      } = data;
      log.debug('handle submit press');
      if (!type || !title || !description || !publishStartDate || !publishTypes || !accessKeyIds || !timezone) {
        return;
      }
      const input: AnnouncementInput = {
        timezone,
        type,
        title,
        description,
        additionalDescription,
        cta,
        publishStartDate,
        publishEndDate,
        expirationDate,
        link,
        publishTypes,
        accessKeyIds,
        thumbnail,
        image,
      };
      log.info('creating new anns, input=', input);
      setProcessing(true);
      await createAnns(input);
      setProcessing(false);
      log.info('creating new anns done, input=', input);
      draftStorage.remove();
      snackbar.success('The new announcement created successfully');
      history.goBack();
    } catch (err) {
      setProcessing(false);
      log.err(err);
      snackbar.err(errToStr(err) || 'Unknown error');
    }
  };

  const handleCancelPress = () => {
    log.debug('handle cancel press');
    history.goBack();
  };

  const handleResetPress = () => {
    log.debug('handle reset press');
    if (confirm('Are you sure you want to reset this form?')) {
      handleDataChange(getDefAnnsEditFormData());
    }
  };

  return (
    <Paper style={m(styles.container, style)}>
      <AnnsEditForm data={data} errors={errs} companies={companies} onChange={handleDataChange} />
      <View style={styles.actions} row={true} justifyContent="space-between">
        <Button
          style={styles.actionBtn}
          disabled={processing}
          variant="text"
          color="default"
          onClick={handleResetPress}
        >
          Reset
        </Button>
        <View row={true} justifyContent="flex-end">
          <Button
            style={styles.actionBtn}
            disabled={processing}
            variant="contained"
            color="default"
            onClick={handleCancelPress}
          >
            Cancel
          </Button>
          <Button
            style={styles.actionBtn}
            variant="contained"
            color="primary"
            disabled={processing}
            onClick={handleSubmitPress}
          >
            {!processing ? `Create` : <ButtonProgress />}
          </Button>
        </View>
      </View>
    </Paper>
  );
};

const styles: Styles = {
  container: {
    padding: 20,
  },
  row: {
    marginTop: 6,
    marginBottom: 6,
  },
  actions: {
    marginTop: 20,
  },
  actionBtn: {
    marginLeft: 6,
    width: 100,
  },
};

export type DashboardAnnsCreateSceneProps = Props;
export default DashboardAnnsCreateScene;
