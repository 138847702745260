import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { appConfigs } from 'core/configs';
import { accessTokenStorage } from 'core/storage';
import { Log } from 'utils';

import cache from 'core/apollo/cache';

const log = Log('core.api');

log.info('init api with env=', appConfigs.env, ', url=', appConfigs.api.uri, ', wss=', appConfigs.api.wss);

const httpLink = createHttpLink({
  uri: appConfigs.api.uri,
});

const authLink = setContext((_, { headers }) => {
  const token = accessTokenStorage.get();
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : undefined,
      ['x-circleof-client']: btoa('dashboard'), // TODO: Hardcode for now. But in future make some configuration.
    },
  };
});

const dashboardLink = setContext((operation, previousContext) => {
  const { headers } = previousContext;

  return {
    ...previousContext,
    headers: {
      ...headers,
      'x-admin-dashboard': true,
    },
  };
});

export const api = new ApolloClient({
  link: ApolloLink.from([dashboardLink, authLink, httpLink]),
  cache,
  connectToDevTools: true,
});

export * from './types';
export * from './fields';
export * from './auth';
export * from './system';
export * from './anns';
export * from './companies';
export * from './taskTypes';
export * from './teams';
export * from './events';
