/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty, isNil } from 'lodash';
import { EventsFileData } from 'core/api';
import { AttachmentType } from 'core/api/types';
import { getRecurrenceOptionsForImport } from 'utils/recurrences';

type EventsData = Partial<EventsFileData>;

const requiredMsg = (v: string) => `Invalid CSV file, ${v} is required`;

const isInvalid = (v: unknown) => v === '' || isNil(v);

export const getFileErrors = (data: EventsData[]) => {
  let errorMsg;
  if (isEmpty(data)) {
    return 'No file uploaded!';
  }
  data.forEach(item => {
    if (isInvalid(item.title)) {
      errorMsg = requiredMsg('title');
      return;
    } else if (isInvalid(item.description)) {
      errorMsg = requiredMsg('description');
      return;
    } else if (isInvalid(item.timezone)) {
      errorMsg = requiredMsg('timezone');
      return;
    } else if (isInvalid(item.startDate)) {
      errorMsg = requiredMsg('startDate');
      return;
    } else if (isInvalid(item.endDate)) {
      errorMsg = requiredMsg('endDate');
      return;
    } else if (isInvalid(item.isAllDay)) {
      errorMsg = requiredMsg('isAllDay');
      return;
    } else if (isInvalid(item.isReminderEnabled)) {
      errorMsg = requiredMsg('isReminderEnabled');
      return;
    } else if (isInvalid(item.visibility)) {
      errorMsg = requiredMsg('visibility');
      return;
    } else if (isInvalid(item.location_name)) {
      errorMsg = requiredMsg('location_name');
      return;
    } else if (isInvalid(item.location_address)) {
      errorMsg = requiredMsg('location_address');
      return;
    } else if (isInvalid(item.location_longitude)) {
      errorMsg = requiredMsg('location_longitude');
      return;
    } else if (isInvalid(item.location_latitude)) {
      errorMsg = requiredMsg('location_latitude');
      return;
    }
  });
  return errorMsg;
};

export const polishFileData = (value: EventsData[]): { [index: string]: any }[] => {
  const modVal: { [index: string]: any }[] = [];
  value.forEach(itm => {
    const pushedItem: { [index: string]: any } = { location: {} };
    for (const [key, value] of Object.entries(itm)) {
      if (key.includes('location')) {
        if (key.includes('name')) {
          pushedItem['location'].name = value;
        } else if (key.includes('address')) {
          pushedItem['location'].address = value;
        } else if (key.includes('longitude')) {
          pushedItem['location'].longitude = value;
        } else if (key.includes('latitude')) {
          pushedItem['location'].latitude = value;
        }
      } else if (key.includes('isAllDay')) {
        pushedItem[key] = value?.toLocaleLowerCase() === 'true';
      } else if (key.includes('isReminderEnabled')) {
        pushedItem[key] = value?.toLocaleLowerCase() === 'true';
      } else if (key.includes('image')) {
        pushedItem['attachment'] = {
          type: AttachmentType.IMAGE,
          contentType: 'image/jpeg',
          url: value,
        };
      } else if (key === 'recurrence') {
        pushedItem[key] = getRecurrenceOptionsForImport(itm.startDate, value);
      } else {
        pushedItem[key] = value;
      }
    }
    modVal.push(pushedItem);
  });
  return modVal;
};
