/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AdminUser, Authorized } from 'core/api';

export interface AuthContextProps {
  user: AdminUser | null;
  setAuth: (authorized: Authorized) => void;
  logout: () => void;
  login: ({ email, password }: { email: string; password: string }) => void;
  loading: boolean;
}

const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  login: () => {
    return;
  },
  logout: () => {
    return;
  },
  loading: false,
  setAuth: authorized => {
    return;
  },
});

export default AuthContext;
