import React, { CSSProperties, FC } from 'react';

interface InjectedProps {
  size: number;
  selected?: boolean;
  style?: CSSProperties;
}

const RadioIndicator: FC<InjectedProps> = ({ size, selected = false, style }) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: size,
        borderWidth: 2,
        borderColor: '#00737E',
        borderStyle: 'solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
    >
      {selected && (
        <div
          style={{
            width: size / 2,
            height: size / 2,
            backgroundColor: '#00737E',
            borderRadius: size / 2,
          }}
        />
      )}
    </div>
  );
};

export default RadioIndicator;
