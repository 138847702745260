import { gql } from '@apollo/client';
import { fields } from 'core/api';

export const GET_CONDITIONS = gql`
    query careConditions {
        careConditions {
            ${fields.careCondition}
        }
    }
`;
