import { Button, CircularProgress, Grid, Paper } from '@material-ui/core';
import { isNil, get } from 'lodash';
import { Image, Text, View, PageTitle } from 'components/Common';
import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { colors, px, StyleProps, Styles } from 'styles';

import logoImg from '../../../assets/logo.png';
import { appConfigs } from 'core/configs';
import { useForm } from 'react-hook-form';
import RHInput from 'components/Form/RHInput';
import { useMutation, useQuery } from '@apollo/client';
import { GET_RESTORE_TOKEN_EMAIL } from 'core/apollo/queries/users';
import { routes } from 'screens/consts';
import { RESET_PASSWORD } from 'core/apollo/mutation/users';
import { useAuth } from '../../../hooks/useAuth';

type Props = StyleProps;

interface ResetPasswordFormRecords {
  password: string;
  confirmPassword: string;
}

export const ResetPasswordScreen: FC<Props> = () => {
  const location = useLocation();
  const { setAuth } = useAuth();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const onboarding = Boolean(query.get('onboarding'));

  const { control, handleSubmit, getValues } = useForm<ResetPasswordFormRecords>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const { data: restoreTokenResponse, error, loading } = useQuery(GET_RESTORE_TOKEN_EMAIL, {
    variables: { token },
  });
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    ignoreResults: true,
  });

  const [err, setErr] = useState<string | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);

  const history = useHistory();

  const onSubmit = async (data: ResetPasswordFormRecords) => {
    setProcessing(true);
    setErr(undefined);
    try {
      const response = await resetPassword({
        variables: {
          onboarding,
          guard: 'admin',
          input: {
            email: restoreTokenResponse?.passwordRestoreTokenEmail,
            token,
            password: data.password,
          },
        },
      });
      setProcessing(false);

      setAuth(get(response, ['data', 'restorePassword']));
    } catch (e) {
      setProcessing(false);
      setErr(e.message);
    }
  };

  if (loading)
    return (
      <Grid style={{ height: '100%' }} container justify="center" alignItems="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );

  if (error || isNil(restoreTokenResponse.passwordRestoreTokenEmail)) {
    return (
      <>
        <PageTitle title="Reset Password" />
        <View style={styles.container}>
          <Paper style={styles.content} elevation={2}>
            <View row={true} justifyContent="center" alignItems="center">
              <Image style={styles.logo} source={logoImg} />
            </View>
            <View justifyContent="center" alignItems="center">
              <Text size={22.5}>Page Cannot Be Found</Text>
              <Text size={14} style={styles.description}>
                We’re unable to locate what you are looking for.
              </Text>
            </View>

            <Button
              onClick={() => history.push(routes.auth.entry)}
              disableElevation
              style={{ width: '100%', marginTop: 25 }}
              variant="contained"
              color="primary"
            >
              Go Back
            </Button>
          </Paper>
        </View>
      </>
    );
  }

  return (
    <>
      <PageTitle title="Reset Password" />
      <View style={styles.container}>
        <Paper style={styles.content} elevation={2}>
          <View row={true} justifyContent="center" alignItems="center">
            <Image style={styles.logo} source={logoImg} />
          </View>
          <View justifyContent="center" alignItems="center">
            <Text size={22.5}>Set New Password</Text>
            <Text size={14} style={styles.description}>
              Enter a new password for <b>{restoreTokenResponse?.passwordRestoreTokenEmail}</b> In order to keep your
              account secure, you can’t reuse your old password.
            </Text>
          </View>
          <View style={styles.errWrap} justifyContent="center" alignItems="center">
            {!!err && (
              <Text style={styles.err}>
                <b>Error:</b> {err}
              </Text>
            )}
          </View>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RHInput
              inputProps={{
                type: 'password',
              }}
              disabled={processing}
              placeholder="New password (8+ characters)"
              label="New password (8+ characters)"
              name="password"
              control={control}
              rules={{ required: 'Password required' }}
            />

            <RHInput
              topIndent
              inputProps={{
                type: 'password',
              }}
              disabled={processing}
              placeholder="Confirm new password"
              label="Confirm new password"
              name="confirmPassword"
              control={control}
              rules={{
                required: 'Confirmation required',
                validate: value => {
                  const { password } = getValues();
                  return password === value || 'Passwords do not match';
                },
              }}
            />

            <Button
              disableElevation
              style={{ width: '100%', marginTop: 25 }}
              type="submit"
              variant="contained"
              color="primary"
              disabled={processing}
            >
              {onboarding ? 'Set' : 'Reset'} Password
            </Button>
          </form>
          <Text style={styles.version} block={true}>{`v${appConfigs.version}`}</Text>
        </Paper>
      </View>
    </>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: '50px 30px',
    width: 413,
    position: 'relative',
  },
  row1: {
    marginTop: 10,
    marginBottom: 34,
  },
  logo: {
    width: 160,
    height: 32,
    marginBottom: 20,
    marginLeft: -18,
  },
  errWrap: {
    height: 20,
  },
  err: {
    color: colors.error,
    fontSize: px(14),
    textAlign: 'center',
  },
  version: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 4,
    fontSize: '10px',
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    margin: '22px 20px',
  },
};

export default ResetPasswordScreen;
