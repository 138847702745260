import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React, { createContext, FC, useContext, useState } from 'react';

interface SnackbarContext {
  show: (opt: SnackbarOpt) => void;
  err: (message: string) => void;
  warn: (message: string) => void;
  info: (message: string) => void;
  success: (message: string) => void;
}

interface SnackbarOpt {
  message?: string;
  type?: SnackbarType;
}

type SnackbarType = 'error' | 'warning' | 'info' | 'success';

const Context = createContext<SnackbarContext | undefined>(undefined);

export const useSnackbar = (): SnackbarContext => {
  const val = useContext(Context);
  return val
    ? val
    : {
        show: () => undefined,
        err: () => undefined,
        warn: () => undefined,
        info: () => undefined,
        success: () => undefined,
      };
};

export const SnackbarProvider: FC = ({ children }) => {
  const [opt, setOpt] = useState<(SnackbarOpt & { open?: boolean }) | undefined>(undefined);

  const show = (opt: SnackbarOpt) => {
    setOpt({ open: true, ...opt });
  };

  const err = (message: string) => show({ type: 'error', message });
  const warn = (message: string) => show({ type: 'warning', message });
  const info = (message: string) => show({ type: 'info', message });
  const success = (message: string) => show({ type: 'success', message });

  const handleClose = () => {
    setOpt(prev => (prev ? { ...prev, open: false } : { open: false }));
  };

  return (
    <Context.Provider value={{ show, err, warn, info, success }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={opt?.open || false}
        autoHideDuration={6000}
        onClose={handleClose}
        message={opt?.message && !opt?.type ? opt.message : undefined}
      >
        {opt && opt.type ? (
          <MuiAlert elevation={6} variant="filled" severity={opt.type}>
            {opt?.message || ''}
          </MuiAlert>
        ) : undefined}
      </Snackbar>
    </Context.Provider>
  );
};
