import { Button, Paper } from '@material-ui/core';
import { Image, Text, View, PageTitle } from 'components/Common';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { colors, px, StyleProps, Styles } from 'styles';
import { errToStr, Log } from 'utils';
import { routes } from '../../consts';

import logoImg from '../../../assets/logo.png';
import { appConfigs } from 'core/configs';
import { useAuth } from '../../../hooks/useAuth';
import { useForm } from 'react-hook-form';
import RHInput from 'components/Form/RHInput';
import makeStyles from '@material-ui/core/styles/makeStyles';

const log = Log('screen.AuthEntry');

type Props = StyleProps;

interface SignInFormValues {
  email: '';
  password: '';
}

const useStyles = makeStyles(() => ({
  submitButton: {
    width: '100%',
  },
}));

export const AuthEntryScreen: FC<Props> = () => {
  const classes = useStyles();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [err, setErr] = useState<string | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);
  const { login } = useAuth();

  const history = useHistory();

  const onSubmit = async ({ email, password }: SignInFormValues) => {
    if (!email || !password) {
      return;
    }

    try {
      setProcessing(true);
      await login({ email, password });
      setProcessing(false);
    } catch (err) {
      setProcessing(false);
      log.err(errToStr(err));
      setErr(errToStr(err));
    }
  };

  const handleForgotPasswordPress = () => {
    history.push(routes.auth.reset_init);
  };

  return (
    <>
      <PageTitle title="Login" />
      <View style={styles.container}>
        <Paper style={styles.content} elevation={2}>
          <View row={true} justifyContent="center" alignItems="center">
            <Image style={styles.logo} source={logoImg} />
          </View>
          <View style={styles.errWrap} justifyContent="center" alignItems="center">
            {!!err && (
              <Text style={styles.err}>
                <b>Error:</b> {err}
              </Text>
            )}
          </View>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RHInput
              inputProps={{
                type: 'email',
              }}
              disabled={processing}
              control={control}
              placeholder="Email"
              name="email"
              label="Email Address"
            />

            <RHInput
              inputProps={{
                type: 'password',
              }}
              disabled={processing}
              topIndent
              control={control}
              placeholder="Password"
              name="password"
              label="Password"
            />

            <View direction="row" justifyContent="flex-end">
              <Button style={styles.password} variant="text" onClick={handleForgotPasswordPress}>
                Forgot Password?
              </Button>
            </View>
            <Button
              type="submit"
              disableElevation
              className={classes.submitButton}
              variant="contained"
              color="primary"
              disabled={processing}
            >
              Login
            </Button>
          </form>
          <Text style={styles.version} block={true}>{`v${appConfigs.version}`}</Text>
        </Paper>
      </View>
    </>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: '50px 30px',
    width: 413,
    position: 'relative',
  },
  row1: {
    marginTop: 10,
    marginBottom: 20,
  },
  row2: {
    marginTop: 10,
  },
  logo: {
    width: 160,
    height: 32,
    marginBottom: 20,
    marginLeft: -18,
  },
  errWrap: {
    height: 20,
  },
  err: {
    color: colors.error,
    fontSize: px(14),
    textAlign: 'center',
  },
  version: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 4,
    fontSize: '10px',
    textAlign: 'center',
  },
  password: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 34,
    color: '#008B98',
    fontWeight: 'bold',
    fontSize: 12,
  },
};

export default AuthEntryScreen;
