import { Button } from '@material-ui/core';
import { CentredProgressIndicator, PageTitle, useSnackbar, View, Header, CompaniesIcon } from 'components/Common';
import { DataTableWithSorting } from 'components/Tables';
import { Company, useGetCompaniesQuery } from 'core/api';
import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'screens/consts';
import { Text } from 'components/Common';
import { colors, mx, StyleProps, Styles } from 'styles';
import { Log } from 'utils';
const log = Log('screen.DashboardCompaniesList');

type Props = StyleProps;

export const DashboardCompaniesListScreen: FC<Props> = ({ style }) => {
  // Deps

  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { data = [], loading, error } = useGetCompaniesQuery();

  useEffect(() => (error ? snackbar.err(error) : undefined), [error]);

  // Data

  const filterItemsWithSearch = (search: string, items: Company[]): Company[] => {
    const searchMod = search.toLowerCase().trim();
    return items.filter(itm => {
      const { name } = itm;
      const nameMod = name ? name.toLowerCase().trim() : '';
      return nameMod.indexOf(searchMod) >= 0;
    });
  };

  // Handlers

  const handleItemPress = (item: Company) => {
    log.debug('item click, item=', item.id);
    history.push(`${routes.companies.default}/${item.id}`);
  };

  const handleCreatePress = () => {
    log.debug('create press');
    history.push(`${routes.companies.default}/new`);
  };

  // Render

  if (loading) {
    return <CentredProgressIndicator />;
  }

  return (
    <>
      <PageTitle title="Companies" />
      <Header path={location.pathname} />
      <View row={true} style={styles.title}>
        <CompaniesIcon style={{ color: colors.hoverGreen, marginRight: 10 }} />
        <Text size={24}>Companies</Text>
      </View>
      <View style={[styles.container, style]}>
        <DataTableWithSorting
          items={data}
          bottomBorder="line"
          searchPlaceholder="Search"
          filterWithSearch={filterItemsWithSearch}
          renderRightAction={() => (
            <Button variant="contained" color="primary" onClick={handleCreatePress} style={styles.button}>
              Set Up A New Company
            </Button>
          )}
          onItemPress={handleItemPress}
        />
      </View>
    </>
  );
};

const styles: Styles = {
  container: {
    margin: '20px 0px',
  },
  table: {
    ...mx.w100,
  },
  link: {
    color: colors.hoverGreen,
    textDecoration: 'underline',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  button: {
    borderRadius: 4,
    padding: '6px 12px',
  },
};

export type DashboardCompaniesListScreenProps = Props;
export default DashboardCompaniesListScreen;
