import { isError, isFunction, isNumber, isString } from 'lodash';
import moment from 'moment';
import format from 'date-fns/format';

import { isUnknownDict } from './types';
import { MerchantStatus, ResourceStage, ResourceStatus } from 'core/apollo/consts';
import { ROLES } from 'core/constants';
import { Resource } from 'core/api';

// Errorrs

/**
 * Convert unknown error to string
 * @param err - Error, string, number or an object with `toString()` property
 */
export const errToStr = (err: unknown): string | undefined => {
  if (!err) {
    return undefined;
  }
  if (isError(err)) {
    return err.message;
  }
  if (isString(err)) {
    return err;
  }
  if (isNumber(err)) {
    return `${err}`;
  }
  if (isUnknownDict(err) && isFunction(err.toString)) {
    return err.toString();
  }
  return undefined;
};

// Date

export const dateToStr = (val: string | Date, format: string = 'YYYY-MM-DD hh:mmA') => moment(val).format(format);

// Operations

export const cutStr = (val: string, length: number) => val.substring(0, length);

export const strToNumArr = (a: string[]) => a.map(x => parseInt(x, 10));

// Validators

export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

/**
 * Check if string is url
 * @param val input string
 */
export const isUrlStr = (val: string) =>
  URL_REGEX.test(val);

export const humanizeMerchantStatus = (status: MerchantStatus | null): string => {
  switch (status) {
    case MerchantStatus.ONLINE:
      return 'Online';
    case MerchantStatus.OFFLINE:
      return 'Offline';
    case MerchantStatus.LOW_FUNDS:
      return 'Low Funds';
    case MerchantStatus.TEMPORARILY_OFFLINE:
      return 'Temporarily Offline';
    case MerchantStatus.CLOSED:
      return 'Closed';
    case MerchantStatus.NOT_AVAILABLE:
      return 'N/A';
    default:
      return 'N/A';
  }
};

export const humanizeResourceStatus = (status: ResourceStatus | null): string => {
  switch (status) {
    case ResourceStatus.SUBMITTED:
      return 'Submitted';
    case ResourceStatus.PENDING:
      return 'Pending';
    case ResourceStatus.APPROVED:
      return 'Approved';
    case ResourceStatus.REJECTED:
      return 'Rejected';
    case ResourceStatus.NOT_AVAILABLE:
      return 'N/A';
    default:
      return 'N/A';
  }
};

export const humanizeResourceStage = (stage: ResourceStage, resource: Resource): string => {
  switch (stage) {
    case ResourceStage.DRAFT:
      return 'Draft';
    case ResourceStage.PUBLISHED:
      return 'Published';
    case ResourceStage.UNPUBLISHED:
      return 'Un-Published';
    case ResourceStage.READY_FOR_REVIEW:
      return 'Ready For Review';
    case ResourceStage.SCHEDULED:
      return `Publish ${format(new Date(resource.scheduledAt || new Date()), 'MMM dd, yyyy')}`;
    default:
      return 'N/A';
  }
};

export const humanizeRole = (role: string): string => {
  switch (role) {
    case ROLES.SUPER_ADMIN:
      return 'Super Admin';
    case ROLES.CONTENT_COORDINATOR:
      return 'Content Coordinator';
    case ROLES.CONTENT_PARTNER:
      return 'Content Partner';
    default:
      return 'User';
  }
};
