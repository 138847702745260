import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { AnnouncementIcon, ResourcesIcon, CompaniesIcon, UserIcon } from 'components/Common';
import { last } from 'lodash';
import React, { FC } from 'react';
import { colors, m, StyleProps, Styles } from 'styles';
import { select } from 'utils';
import { routes } from 'screens/consts';

interface Props extends StyleProps {
  path: string;
}

interface NavBreadcumbItem {
  id?: string;
  title: string;
  icon?: 'announcements' | 'companies' | 'resources' | 'user';
  uri?: string;
}

const pathToItems = (path: string): NavBreadcumbItem[] => {
  const parts = path.split('/');
  const items: NavBreadcumbItem[] = [];
  parts.forEach((part, index) => {
    if (part === 'dashboard') {
      return;
    }
    if (part === 'anns') {
      return items.push({ id: 'anns', title: 'Announcements', icon: 'announcements', uri: routes.announcements.list });
    }
    if (part === 'companies') {
      return items.push({ id: 'companies', title: 'Companies', icon: 'companies', uri: routes.companies.list });
    }
    if (part === 'resources') {
      return items.push({ id: 'resources', title: 'Marketplace', icon: 'resources', uri: routes.resources.list });
    }
    if (part === 'users') {
      return items.push({ title: 'User', icon: 'user' });
    }
    if (part === 'list') {
      const parent = last(items);
      if (parent && parent.id === 'anns') {
        const uri = index == parts.length - 1 ? undefined : `${routes.announcements.list}`;
        return items.push({ title: 'List', uri });
      }
      if (parent && parent.id === 'companies') {
        const uri = index == parts.length - 1 ? undefined : `${routes.companies.list}`;
        return items.push({ title: 'List', uri });
      }
      if (parent && parent.id === 'benefits') {
        const uri = index == parts.length - 1 ? undefined : routes.resources.list;
        return items.push({ title: 'List', uri });
      }
    }
    if (part === 'new') {
      return items.push({ title: 'New' });
    }
    if (!isNaN(parseInt(part, 10))) {
      if (parts.includes('companies') && index === 4) {
        const uri = index == parts.length - 1 ? undefined : `${routes.companies.default}/${part}`;
        return items.push({ title: `#${part}`, uri });
      }
      return items.push({ title: `#${part}` });
    }
  });
  return items;
};

export const NavBreadcumbs: FC<Props> = ({ style, path }) => {
  const items = pathToItems(path);

  const renderItemIcon = ({ icon }: NavBreadcumbItem) =>
    icon
      ? select(icon, {
          announcements: <AnnouncementIcon style={styles.icon} />,
          companies: <CompaniesIcon style={styles.icon} />,
          resources: <ResourcesIcon style={styles.icon} />,
          user: <UserIcon style={styles.icon} />,
        })
      : null;

  return (
    <Breadcrumbs style={m(styles.container, style)} aria-label="breadcrumb">
      {items.map((itm, key) =>
        itm.uri ? (
          <Link key={key} style={styles.link} color="inherit" href={itm.uri}>
            {renderItemIcon(itm)}
            {itm.title}
          </Link>
        ) : (
          <Typography key={key} style={styles.title}>
            {renderItemIcon(itm)}
            {itm.title}
          </Typography>
        ),
      )}
    </Breadcrumbs>
  );
};

const styles: Styles = {
  container: {
    marginTop: 10,
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: 6,
    width: 20,
    height: 20,
  },
  title: {
    display: 'flex',
    color: colors.hoverGreen,
  },
};

export type NavBreadcumbsProps = Props;
export default NavBreadcumbs;
