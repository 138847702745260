import { gql } from '@apollo/client';
import { fields } from 'core/api';

export const GET_USERS = gql`  
  query users($query: String, $limit: Int, $offset: Int) {
      users(query: $query, limit: $limit, offset: $offset) {
          ${fields.user}
      }
  }
`;

export const GET_USER_FEED = gql`
    query userFeed($searchQuery: String, $limit: Int, $offset: Int) {
        userFeed(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
            total
            data {
                ${fields.user}
            }
        }
    }
`;

export const GET_USER = gql`
    query user($id: Int) {
        user(id: $id) {
            ${fields.user}
        }
    }
`;

export const GET_ADMIN_USER = gql`
    query adminUser($id: Int) {
        adminUser(id: $id) {
            ${fields.adminUser}
        }
    }
`;

export const GET_RESTORE_TOKEN_EMAIL = gql`
  query passwordRestoreTokenEmail($token: String) {
    passwordRestoreTokenEmail(token: $token)
  }
`;

export const GET_ADMIN_USER_FEED = gql`
    query adminUserFeed($searchQuery: String, $limit: Int, $offset: Int) {
        adminUserFeed(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
            total
            data {
                ${fields.adminUser}
            }
        }
    }
`;
