import React, { FC } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { StyleProps } from 'styles';

import PublicTeamScene from '../PublicTeam';
import EditScene from '../Edit/scenes/Edit';
import { PageTitle, Header } from 'components/Common';
import { useGetCompaniesQuery, CareTeam } from 'core/api';

type Props = StyleProps;

interface stateType {
  data: CareTeam;
  page: string;
}

export const DetailsScreen: FC<Props> = () => {
  const location = useLocation();
  const history = useHistory();

  const { subId } = useParams<{ subId: string | undefined }>();
  const { state } = useLocation<stateType>();
  const { page, data } = state;

  const title = page === 'childCompany' ? 'Child Company' : 'Public Team';

  const { data: companies } = useGetCompaniesQuery();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <PageTitle title={title} />
      <Header path={location.pathname} />
      {page === 'childCompany' ? (
        <EditScene id={parseInt(subId || '', 10)} companies={companies} onGoBack={handleGoBack} />
      ) : (
        <PublicTeamScene publicTeam={data} onGoBack={handleGoBack} />
      )}
    </>
  );
};

export type DetailsScreenProps = Props;
export default DetailsScreen;
