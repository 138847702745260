import { gql } from '@apollo/client';
import { fields } from 'core/api';

export const GET_APPOINTMENT_TYPES = gql`
    query appointmentTypes {
        appointmentTypes {
            ${fields.appointmentType}
        }
    }
`;
