import { gql, useMutation, useQuery } from '@apollo/client';
import { errToStr } from 'utils';
import { fields } from './fields';
import { Company, CareTeam, CreateCompanyInput, UpdateCompanyInput } from './types';
import { graphQlErrorsToStr } from './utils';

interface CompaniesQueryVars {
  companyId?: number | null;
}

export const useGetCompaniesQuery = () => {
  const { data, loading, error } = useQuery<{ companies: Company[] }>(
    gql`query companies {
    companies {
      ${fields.company}
    }
  }`,
    { fetchPolicy: 'cache-and-network' },
  );
  return { data: data ? data.companies : undefined, loading, error: error ? errToStr(error) : undefined };
};

export const useGetCompanyByCompanyIdQuery = ({ companyId }: CompaniesQueryVars) => {
  const { data, loading, error } = useQuery<{ companies: Company[] }, CompaniesQueryVars>(
    gql`query companies($companyId: Int) {
    companies(companyId: $companyId) {
      ${fields.company}
    }
  }`,
    {
      fetchPolicy: 'cache-and-network',
      variables: { companyId },
    },
  );
  return { data: data ? data.companies : undefined, loading, error: error ? errToStr(error) : undefined };
};

export const useGetCompanyPublicTeamsQuery = ({ companyId }: CompaniesQueryVars) => {
  const { data, loading, error } = useQuery<{ companyPublicTeams: CareTeam[] }, CompaniesQueryVars>(
    gql`query companyPublicTeams($companyId: Int!) {
    companyPublicTeams(companyId: $companyId) {
      ${fields.careTeam}
    }
  }`,
    {
      fetchPolicy: 'cache-and-network',
      variables: { companyId },
    },
  );
  return { data: data ? data.companyPublicTeams : undefined, loading, error: error ? errToStr(error) : undefined };
};

const companiesRefetchQuery = {
  query: gql`query companies {
    companies {
      ${fields.company}
    }
  }`,
  variables: {},
};

export const useCreateCompanyMutation = () => {
  const [mutation] = useMutation<{ createCompany: Company }, { input: CreateCompanyInput }>(
    gql`mutation createCompany($input: CreateCompanyInput!){
    createCompany(input: $input){
      ${fields.company}
    }
  }`,
    { refetchQueries: [companiesRefetchQuery] },
  );
  return async (input: CreateCompanyInput): Promise<Company> => {
    const { data, errors } = await mutation({ variables: { input } });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    }
    if (data && data.createCompany) {
      return data.createCompany;
    } else {
      throw new Error('Response is empty');
    }
  };
};

export const useUpdateCompanyMutation = () => {
  const [mutation] = useMutation<{ updateCompany: Company }, { input: UpdateCompanyInput }>(
    gql`mutation updateCompany($input: UpdateCompanyInput!){
    updateCompany(input: $input){
      ${fields.company}
    }
  }`,
    { refetchQueries: [companiesRefetchQuery] },
  );
  return async (input: UpdateCompanyInput): Promise<Company> => {
    const { data, errors } = await mutation({ variables: { input } });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    }
    if (data && data.updateCompany) {
      return data.updateCompany;
    } else {
      throw new Error('Response is empty');
    }
  };
};

export const useRemoveCompanyMutation = () => {
  const [mutation] = useMutation<{ removeCompany: Company }, { id: number }>(
    gql`mutation removeCompany($id: Int!) {
    removeCompany(id:$id) {
      ${fields.company}
    }
  }`,
    { refetchQueries: [companiesRefetchQuery] },
  );
  return async (id: number): Promise<Company> => {
    const { data, errors } = await mutation({ variables: { id } });
    if (errors && errors.length) {
      throw new Error(graphQlErrorsToStr(errors));
    }
    if (data && data.removeCompany) {
      return data.removeCompany;
    } else {
      throw new Error('Response is empty');
    }
  };
};
