// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect } from 'react';

interface Props {
  title?: string;
}

export const PageTitle: FC<Props> = ({ title }) => {
  useEffect(() => {
    document.title = title ? `${title} | CircleOf` : 'CircleOf';
  }, [title]);
  return null;
};

export type PageTitleProps = Props;
export default PageTitle;
