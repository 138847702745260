import { Table, TableContainer, TableHead, TableCell, TableBody, TableCellBaseProps } from '@material-ui/core';
import React, { ReactNode, PureComponent, ElementType } from 'react';
import { StyleProps, Styles, m } from 'styles';
import { map } from 'lodash';
import { View } from 'components/Common';
import { FormSearchField } from 'components/Form';
import { StyledTableCell, StyledTableRow, TableRowWithBottomLine, TableRowWithoutBackground } from 'components/Common';

interface Props<T> extends StyleProps {
  title?: string;
  stickyHeader?: boolean;
  searchPlaceholder?: string;
  items: T[];
  columns: Columns<T>;
  bottomBorder?: string;
  filterWithSearch?: (search: string, items: T[]) => T[];
  keyExtractor?: (item: T) => string;
  renderRighAction?: () => ReactNode;
  onItemPress?: (item: T) => void;
}

interface State {
  search: string;
}

type Columns<T> = {
  [K in keyof T]?: Column<T, K>;
};

interface Column<T, K extends keyof T> {
  title: string;
  valExtractor: (val: T[K], item: T) => ReactNode | null | undefined;
  component?: ElementType<TableCellBaseProps>;
  scope?: TableCellBaseProps['scope'];
}

export class DataTable<T> extends PureComponent<Props<T>, State> {
  state: State = {
    search: '',
  };

  public render() {
    const {
      style,
      title,
      stickyHeader = true,
      searchPlaceholder,
      items,
      columns,
      bottomBorder,
      keyExtractor,
      filterWithSearch,
      renderRighAction,
      onItemPress,
    } = this.props;
    const { search } = this.state;

    const getItems = (): T[] => {
      if (!search || !filterWithSearch) {
        return items;
      }
      return filterWithSearch(search, items);
    };

    // Render

    const renderRow = (itm: T, index: number) => {
      const cells: ReactNode[] = [];
      for (const key in columns) {
        const cell = columns[key];
        if (cell) {
          cells.push(
            <TableCell
              key={key}
              style={m(!!onItemPress && key !== 'accessKeys' && { cursor: 'pointer' })}
              component={cell.component}
              scope={cell.scope}
              onClick={() => key !== 'accessKeys' && onItemPress && onItemPress(itm)}
            >
              {cell.valExtractor(itm[key], itm)}
            </TableCell>,
          );
        }
      }
      return bottomBorder === 'line' ? (
        <TableRowWithBottomLine key={keyExtractor ? keyExtractor(itm) : index} hover={true}>
          {cells}
        </TableRowWithBottomLine>
      ) : (
        <StyledTableRow key={keyExtractor ? keyExtractor(itm) : index} hover={true}>
          {cells}
        </StyledTableRow>
      );
    };

    return (
      <View>
        <View style={styles.row} row={true} justifyContent="space-between" alignItems="center">
          <FormSearchField
            label=""
            placeholder={searchPlaceholder}
            value={search}
            onChange={val => this.setState({ search: val })}
            style={{ width: 265 }}
          />
          {renderRighAction && renderRighAction()}
        </View>
        <TableContainer style={styles.row}>
          <Table style={m(styles.container, style)} aria-label={title} stickyHeader={stickyHeader}>
            <TableHead>
              <TableRowWithoutBackground>
                {map(columns, (val, key) => (
                  <StyledTableCell style={styles.cell} key={key}>
                    {val?.title}
                  </StyledTableCell>
                ))}
              </TableRowWithoutBackground>
            </TableHead>
            <TableBody>{getItems().map(renderRow)}</TableBody>
          </Table>
        </TableContainer>
      </View>
    );
  }
}

const styles: Styles = {
  container: {},
  cell: {
    width: '10%',
    fontSize: 13,
  },
  row: {
    marginTop: 6,
    marginBottom: 6,
  },
};

export type DataTableProps<T> = Props<T>;
export default DataTable;
