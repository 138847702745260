import { isNil } from 'lodash';
import { Resource } from 'core/api';
import {ResourceStage, ResourceStatus} from 'core/apollo/consts';

export const isUnPublishAllowed = (resource?: Resource | null) => {
  if (isNil(resource)) return false;
  [ResourceStage.SCHEDULED, ResourceStage.PUBLISHED].includes(resource?.stage);
};

export const isPublishAllowed = (resource?: Resource | null) => {
  if (isNil(resource)) return false;
  return resource?.stage !== ResourceStage.PUBLISHED;
};

export const isScheduleAllowed = (resource?: Resource | null) => {
  if (isNil(resource)) return false;
  return ![ResourceStage.SCHEDULED, ResourceStage.PUBLISHED].includes(resource?.stage);
};

export const isReadyForReviewAllowed = (resource?: Resource | null) => {
  if (isNil(resource)) return false;
  return ![ResourceStage.READY_FOR_REVIEW, ResourceStage.PUBLISHED, ResourceStage.SCHEDULED].includes(resource?.stage);
};

export const isLinkRequired = (status: ResourceStatus) => {
  return ![ResourceStatus.SUBMITTED, ResourceStatus.PENDING, ResourceStatus.REJECTED].includes(status);
};
