// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, useEffect } from 'react';

import { TableCell, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'styles';

export const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: 'transparent',
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    '& td, & th': {
      borderBottom: `7px solid ${colors.bottomLineGrey}`,
      borderTop: '0px',
    },
    backgroundColor: '#fff',
  },
}))(TableRow);

export const TableRowWithBottomLine = withStyles(() => ({
  root: {
    '& td, & th': {
      borderBottom: `1px solid ${colors.bottomLineGrey}`,
      borderTop: '0px',
    },
    backgroundColor: '#fff',
  },
}))(TableRow);

export const TableRowWithoutBackground = withStyles(() => ({
  root: {
    '& td, & th': {
      borderBottom: `1px solid ${colors.bottomLineGrey}`,
      borderTop: '0px',
    },
    backgroundColor: '#fff',
  },
}))(TableRow);
