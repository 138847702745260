import {AttachmentType, Company} from 'core/api';
import { isNumber } from 'lodash';
import { isUrlStr, requiredMsg } from 'utils';

import { CompaniesEditFormData, CompaniesEditFormErrors } from './types';

export const getDefCompaniesEditFormData = (): CompaniesEditFormData => ({});

export const polishCompaniesEditFormData = (value: CompaniesEditFormData): CompaniesEditFormData => {
  let modVal = value;
  // Name = 50 character
  if (modVal.name && modVal.name.length > 50) {
    modVal = { ...modVal, name: modVal.name.substring(0, 50) };
  }
  // tagline = 50-100 characters
  if (modVal.tagLine && modVal.tagLine.length > 100) {
    modVal = { ...modVal, tagLine: modVal.tagLine.substring(0, 100) };
  }
  return modVal;
};

export const getCompaniesEditFormErrors = (data: CompaniesEditFormData): CompaniesEditFormErrors | undefined => {
  const { name, websiteUrl, type: companyType, logo, background, tagLine, teamCreatorId } = data;
  if (!name) {
    return { name: requiredMsg('Name') };
  }
  if (!companyType) {
    return { type: requiredMsg('Type') };
  }
  if (!tagLine) {
    return { tagLine: requiredMsg('Tagline') };
  }
  if (!logo) {
    return { logo: requiredMsg('Logo') };
  }
  if (!background) {
    return { background: requiredMsg('Background image') };
  }
  if (websiteUrl && !isUrlStr(websiteUrl)) {
    return { websiteUrl: 'Wrong website url format' };
  }
  if (!teamCreatorId) {
    return { teamCreatorId: 'Team creator required!' }
  }
  return undefined;
};

export const companyToEditFormData = ({
  name,
  logo,
  type,
  companyId,
  location,
  url,
  accessKeys,
  background,
  tagLine,
  resourceCenterName,
  publicTeam,
}: Company): CompaniesEditFormData => {
  return ({
    name: name ? name : undefined,
    logo: logo
      ? {
        type: AttachmentType.IMAGE,
        url: logo,
      }
      : undefined,
    background: logo
      ? {
        type: AttachmentType.IMAGE,
        url: background,
      }
      : undefined,
    tagLine,
    type,
    companyId: isNumber(companyId) ? companyId : undefined,
    location,
    websiteUrl: url ? url : undefined,
    accessKeys,
    resourceCenterName,
    teamCreatorId: publicTeam?.owner?.id || undefined
  })
};
