import { centerCrop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import { AttachmentType } from 'core/api';

export const canvasPreview = async (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop) => {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

  ctx.restore();
};

export const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
  width: number,
  height: number,
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width,
        height,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
};

export const buildAttachmentInput = (attachmentType: AttachmentType, base64: string) => {
  const base64ContentArray = base64.split(',');
  const mimeType = (base64ContentArray[0]?.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/) || [])[0];

  return {
    type: attachmentType,
    contentType: mimeType,
    url: base64, // We will provide it to our optimistic UI
    uri: base64,
  };
};
export const buildAttachmentFromUrl = (attachmentType: AttachmentType, mime: string, url?: string | null) => {
  return {
    type: attachmentType,
    contentType: mime,
    url: url || undefined,
    uri: url || undefined,
  };
};
