import React, { FC, ChangeEvent } from 'react';
import { StyleProps } from 'styles';
import { TextField } from '@material-ui/core';

interface Props extends StyleProps {
  value?: string | Date;
  required?: boolean;
  label?: string;
  rows?: number;
  fullWidth?: boolean;
  multiline?: boolean;
  placeholder?: string;
  helperText?: string;
  type?: string;
  disabled?: boolean;
  InputProps?: { [arbitrary: string]: { maxLength: number } };
  error?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FormDateTimePickerField: FC<Props> = ({
  label,
  style,
  value,
  rows = 1,
  required = false,
  fullWidth = true,
  multiline = false,
  placeholder,
  type,
  disabled = false,
  error,
  helperText,
  InputProps,
  onChange,
}) => {
  return (
    <>
      {!!label && (
        <div>
          <span>{label}</span>
          {required && <span>*</span>}
        </div>
      )}
      <TextField
        value={value}
        style={style}
        variant="outlined"
        rows={rows}
        placeholder={placeholder}
        InputProps={InputProps}
        fullWidth={fullWidth}
        multiline={multiline}
        required={required}
        disabled={disabled}
        type={type}
        error={error}
        helperText={helperText}
        onChange={onChange}
      />
    </>
  );
};

export type FormDateTimePickerFieldProps = Props;
export default FormDateTimePickerField;
