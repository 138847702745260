import { Paper, IconButton } from '@material-ui/core';
import { BackgroundImage } from 'components/Common';
import React, { FC, SyntheticEvent } from 'react';
import { m, StyleProps, Styles } from 'styles';
import RmIcon from '@material-ui/icons/Clear';

interface Props extends StyleProps {
  src?: string;
  size?: number;
  onRemovePress?: (src: string | undefined) => void;
}

export const FormImgPickerFieldItem: FC<Props> = ({ style, size, src, onRemovePress }) => {
  const styles = getStyles(size);

  const handleRemovePress = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (onRemovePress) {
      onRemovePress(src);
    }
  };

  return (
    <Paper style={m(styles.container, style)}>
      <IconButton size="small" style={styles.rmBtn} onClick={handleRemovePress}>
        <RmIcon style={styles.rmBtnIcon} />
      </IconButton>
      <BackgroundImage style={styles.img} src={src} size="cover" position="center" />
    </Paper>
  );
};

const getStyles = (size: number = 80): Styles => ({
  container: {
    width: size,
    height: size,
    borderRadius: 5,
  },
  img: {
    width: size,
    height: size,
    borderRadius: 5,
  },
  rmBtn: {
    position: 'absolute',
    top: -8,
    right: -8,
    backgroundColor: 'white',
    boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.5)',
    width: 22,
    height: 22,
  },
  rmBtnIcon: {
    width: 18,
    height: 18,
  },
});

export type FormImgPickerFieldItemProps = Props;
export default FormImgPickerFieldItem;
