import React, { FC } from 'react';
import { Controller, RegisterOptions, Control } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel } from '@material-ui/core';

interface InjectedProps {
  name: string;
  defaultValue?: boolean;
  label?: string;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  topIndent?: boolean;
  control: Control<any>;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  control: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#15374E',
  },
  asterisk: {
    color: '#DD4A4A',
  },
}));

const RHCheckbox: FC<InjectedProps> = ({
  name,
  control,
  defaultValue = false,
  label = '',
  rules,
  topIndent = false,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div style={{ marginTop: topIndent ? 25 : 0 }} className={classes.control}>
      <Controller
        rules={rules}
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            disabled={disabled}
            control={<Checkbox disabled={disabled} onChange={e => onChange(e.target.checked)} checked={value} />}
            label={
              !!label && (
                <span>
                  {label}
                  {rules?.required && <span className={classes.asterisk}>*</span>}
                </span>
              )
            }
          />
        )}
      />
    </div>
  );
};

export default RHCheckbox;
