import React, { FC, ReactNode } from 'react';
import { isNumber } from 'lodash';
import { Controller, RegisterOptions, Control } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { InputProps as StandardInputProps, MenuItem } from '@material-ui/core';

interface InjectedProps {
  name: string;
  defaultValue?: string;
  label?: string;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  placeholder?: string;
  select?: boolean;
  options?: Array<{ value: any; label: string }>;
  helperText?: string;
  topIndent?: boolean | number;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  button?: ReactNode;
  control: Control<any>;
  inputProps?: Partial<StandardInputProps>;
}

const useInputStyles = makeStyles(() => ({
  root: {
    minHeight: 48,
  },
}));

const useStyles = makeStyles(() => ({
  control: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: 8,
    fontWeight: 500,
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#15374E',
  },
  asterisk: {
    color: '#DD4A4A',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexGrow: 1,
  },
  root: {
    flex: 1,
  },
}));

const RHInput: FC<InjectedProps> = ({
  name,
  defaultValue = '',
  label = '',
  rules,
  placeholder,
  select,
  options = [],
  helperText,
  topIndent = false,
  multiline = false,
  rows,
  disabled = false,
  button = null,
  control,
  inputProps = {},
}) => {
  const classes = useStyles();
  const classesInput = useInputStyles();

  return (
    <div style={{ marginTop: topIndent ? (isNumber(topIndent) ? topIndent : 25) : 0 }} className={classes.control}>
      {!!label && (
        <label className={classes.label} htmlFor={name}>
          {label}
          {rules?.required && <span className={classes.asterisk}>*</span>}
        </label>
      )}
      <Controller
        rules={rules}
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={classes.row}>
            <TextField
              error={!!error}
              disabled={disabled}
              rows={rows}
              multiline={multiline}
              helperText={error?.message || helperText}
              select={select}
              id={name}
              className={classes.root}
              InputProps={{ classes: classesInput, style: { marginRight: button ? 8 : 0 }, disabled, ...inputProps }}
              size="small"
              placeholder={placeholder}
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              onChange={onChange}
              value={value}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            {button}
          </div>
        )}
      />
    </div>
  );
};

export default RHInput;
