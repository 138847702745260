import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import ResourceForm, { ResourceFormRecords } from 'components/Resources/ResourceForm';
import { Header } from 'components/Common';
import { routes } from 'screens/consts';
import { useForm } from 'react-hook-form';
import { ResourceStage } from 'core/apollo/consts';
import {useApolloClient, useMutation} from '@apollo/client';
import { CREATE_RESOURCE } from 'core/apollo/mutation/resources';
import { resourceCreatedResolver } from 'core/apollo/mutation/resolvers';
import { ResourceInput } from 'core/api';
import { Alert, Color } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import ResourceFormHeader from 'components/Resources/ResourceFormHeader';
import { normalizeFormValues } from 'screens/Resources/util';

const ResourceCreateScreen = () => {
  const form = useForm<ResourceFormRecords>({
    reValidateMode: 'onChange',
    defaultValues: {
      stage: ResourceStage.DRAFT,
      additionalNote: '',
      appDescription: '',
      appImage: null,
      appLink: '',
      appLinkText: 'Learn More',
      appMerchantName: '',
      appointmentTypes: [],
      conditions: [],
      isApp: true,
      isWeb: true,
      megaCategories: [],
      merchantHomePageUrl: '',
      merchantName: '',
      merchantPPCLink: '',
      merchantPageMetadata: '',
      merchantProgram: null,
      merchantStatus: null,
      merchantTrackingMetadata: '',
      merchantTrackingUrl: '',
      metaTags: [],
      subCategories: [],
      needTypes: [],
      topics: [],
      webAutofill: false,
      webDescription: '',
      webImage: null,
      webLink: '',
      webLinkText: 'Visit Website',
      webMerchantName: '',
    },
  });
  const location = useLocation();
  const history = useHistory();
  const [snackbar, setSnackbar] = useState<{ severity: Color; message: string }>();
  const [loading, setLoading] = useState<ResourceStage>();
  const client = useApolloClient();

  const [createResource] = useMutation(CREATE_RESOURCE, {
    ignoreResults: true,
    update: resourceCreatedResolver,
  });

  const { handleSubmit } = form;

  const onSnackbarClose = () => {
    setSnackbar(undefined);
  };

  const onSubmit = async (data: ResourceFormRecords, stage: ResourceStage) => {
    const payload: ResourceInput = normalizeFormValues({ ...data, stage, }, client);

    try {
      setLoading(stage);
      await createResource({
        variables: {
          input: payload,
        },
      });
      setLoading(undefined);
      setSnackbar({ severity: 'success', message: 'Resource created successfully!' });
      history.push(routes.resources.list);
    } catch (e) {
      setSnackbar({ severity: 'error', message: e.message });
      setLoading(undefined);
    }
  };
  const handleSubmitClick = (stage: ResourceStage) => handleSubmit(d => onSubmit(d, stage))();
  const handleCancelClick = () => {
    history.push(routes.resources.list);
  };

  return (
    <div>
      <Header path={location.pathname} />

      <ResourceFormHeader
        isDirty={form.formState.isDirty}
        onCancel={handleCancelClick}
        loading={loading}
        onClick={handleSubmitClick}
      />

      <ResourceForm form={form} />

      <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={onSnackbarClose}>
        <Alert onClose={onSnackbarClose} severity={snackbar?.severity}>
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ResourceCreateScreen;
