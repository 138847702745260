import { ApolloCache } from '@apollo/client';
import { gql } from '@apollo/client';
import { fields } from 'core/api';

export const merchantProgramCreatedResolver = (cache: ApolloCache<any>, { data: { createMerchantProgram } }) => {
  return cache.modify({
    fields: {
      merchantPrograms(existing = []) {
        const newRef = cache.writeFragment({
          data: createMerchantProgram,
          fragment: gql`
                      fragment NewMerchangeProgram on MerchantProgram {
                          ${fields.merchantProgram}
                      }
                  `,
        });
        return existing.concat(newRef);
      },
    },
  });
};

export const metaTagCreatedResolver = (cache: ApolloCache<any>, { data: { createMetaTag } }) => {
  return cache.modify({
    fields: {
      metaTags(existing = []) {
        const newRef = cache.writeFragment({
          data: createMetaTag,
          fragment: gql`
                        fragment NewMetaTag on MetaTag {
                            ${fields.metaTag}
                        }
                    `,
        });
        return existing.concat(newRef);
      },
    },
  });
};

export const resourceCreatedResolver = (cache: ApolloCache<any>, { data: { createResource } }) => {
  return cache.modify({
    fields: {
      resourceFeed(existing = { data: [], total: 0 }) {
        const newRef = cache.writeFragment({
          data: createResource,
          fragment: gql`
                        fragment newResource on Resource {
                            ${fields.resourceBasic}
                        }
                    `,
        });
        return {
          ...existing,
          data: existing.data.concat(newRef),
        };
      },
    },
  });
};

export const userCreatedResolver = (cache: ApolloCache<any>, { data: { createUser } }) => {
  return cache.modify({
    fields: {
      userFeed(existing = { data: [], total: 0 }) {
        const newRef = cache.writeFragment({
          data: createUser,
          fragment: gql`
                        fragment newUser on User {
                            ${fields.user}
                        }
                    `,
        });
        return {
          ...existing,
          data: existing.data.concat(newRef),
        };
      },
    },
  });
};

export const userAdminCreatedResolver = (cache: ApolloCache<any>, { data: { createAdminUser } }) => {
  return cache.modify({
    fields: {
      adminUserFeed(existing = { data: [], total: 0 }) {
        const newRef = cache.writeFragment({
          data: createAdminUser,
          fragment: gql`
                        fragment newAdminUser on User {
                            ${fields.user}
                        }
                    `,
        });
        return {
          ...existing,
          data: existing.data.concat(newRef),
        };
      },
    },
  });
};
