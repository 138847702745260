import { gql } from '@apollo/client';
import { fields } from 'core/api';

export const GET_MERCHANT_PROGRAMS = gql`
    query merchantPrograms {
        merchantPrograms {
            ${fields.merchantProgram}
        }
    }
`;
