import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import { Group } from '@material-ui/icons';
import clsx from 'clsx';
import { AnnouncementIcon, CompaniesIcon, ResourcesIcon, UsersIcon, Text, Image } from 'components/Common';
import { appConfigs } from 'core/configs';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'screens/consts';
import { colors } from 'styles';
import logoImg from 'assets/logo.png';
import { useAuth } from '../hooks/useAuth';
import { isSuperAdmin } from 'utils/permissions';

const AuthLayout: FC = ({ children }) => {
  const classes = useStyles();
  const { user } = useAuth();

  // Navigation

  const history = useHistory();
  const location = useLocation();

  const isActive = (route: string) => location.pathname.indexOf(route) === 0;
  const selectedStyle = (isActive: boolean) => (isActive ? colors.hoverGreen : '');

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.logo}>
          <Image style={{ width: 112, height: 22, marginLeft: 19, marginRight: 10 }} source={logoImg} />
          <Text>Admin</Text>
        </div>
        <List className={classes.list}>
          {isSuperAdmin(user) && (
            <>
              <ListSubheader className={classes.subheader}>CLIENTS</ListSubheader>
              <ListItem
                button
                selected={isActive(routes.companies.default)}
                onClick={() => history.push(routes.companies.default)}
              >
                <ListItemIcon className={classes.icon}>
                  <CompaniesIcon style={{ color: selectedStyle(isActive(routes.companies.default)) }} />
                </ListItemIcon>
                <ListItemText
                  className={classes.itemText}
                  primary="Companies"
                  style={{ color: selectedStyle(isActive(routes.companies.default)) }}
                />
              </ListItem>
            </>
          )}
          <ListSubheader className={classes.subheader}>ACTIVITIES</ListSubheader>
          {isSuperAdmin(user) && (
            <ListItem
              button
              selected={isActive(routes.announcements.default)}
              onClick={() => history.push(routes.announcements.default)}
            >
              <ListItemIcon className={classes.icon}>
                <AnnouncementIcon style={{ color: selectedStyle(isActive(routes.announcements.default)) }} />
              </ListItemIcon>
              <ListItemText
                className={classes.itemText}
                primary="Announcements"
                style={{ color: selectedStyle(isActive(routes.announcements.default)) }}
              />
            </ListItem>
          )}
          <ListItem
            button
            selected={isActive(routes.resources.list)}
            onClick={() => history.push(routes.resources.list)}
          >
            <ListItemIcon className={classes.icon}>
              <ResourcesIcon style={{ color: selectedStyle(isActive(routes.resources.list)) }} />
            </ListItemIcon>
            <ListItemText
              className={classes.itemText}
              primary="Marketplace"
              style={{ color: selectedStyle(isActive(routes.resources.list)) }}
            />
          </ListItem>

          {isSuperAdmin(user) && (
            <ListItem button selected={isActive(routes.users.list)} onClick={() => history.push(routes.users.list)}>
              <ListItemIcon className={classes.icon}>
                <UsersIcon style={{ color: selectedStyle(isActive(routes.users.list)) }} />
              </ListItemIcon>
              <ListItemText
                className={classes.itemText}
                primary="Users"
                style={{ color: selectedStyle(isActive(routes.users.list)) }}
              />
            </ListItem>
          )}

          {isSuperAdmin(user) && (
            <>
              <ListSubheader className={classes.subheader}>SETTINGS</ListSubheader>
              <ListItem button selected={isActive(routes.admins.list)} onClick={() => history.push(routes.admins.list)}>
                <ListItemIcon>
                  <Group style={{ color: selectedStyle(isActive(routes.admins.list)) }} />
                </ListItemIcon>
                <ListItemText
                  className={classes.itemText}
                  primary="Admin Users"
                  style={{ color: selectedStyle(isActive(routes.admins.list)) }}
                />
              </ListItem>
            </>
          )}
        </List>
        {/* <Divider /> */}
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
        <Text className={classes.version}>{`v${appConfigs.version}`}</Text>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  list: {
    display: 'relative',
  },
  version: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 3,
    fontSize: '10px',
    textAlign: 'center',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  icon: {},
  divider: {
    marginBottom: 15,
  },
  itemText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#8A9BA6',
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  subheader: {
    fontSize: 10,
    fontWeight: 500,
    color: '#8A9BA6',
  },
}));

export default AuthLayout;
