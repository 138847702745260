const zeplin = {
  white: '#ffffff',
  ice: '#ecf2f1',
  iceTwo: '#e1eae9',
  lightGray: '#ebebeb',
  veryLightPink: '#e1e1e1',
  purpleyGrey: '#9b9b9b',
  greyish: '#b0afaf',
  coolGrey: '#aeb6bc',
  warmGrey: '#707070',
  greyishBrown: '#454545',
  greyishBrownTwo: '#434343',
  black: '#272727',
  veryLightPinkTwo: '#eeeeee',
  silver: '#cdced2',
  cloudyBlue: '#b8c3c9',
  veryLightPinkThree: '#dfdfdf',
  paleGrey: '#fbfbfc',
  iceThree: '#e2ebea',
  duckEggBlue: '#d4e3e5',
  lightTeal: '#9ee3de',
  paleTurquoise: '#a8e8da',
  paleTurquoiseTwo: '#aae6d9',
  seafoamBlueThree: '#56cdb3',
  sea: '#43959d',
  seaTwo: '#3d8a91',
  bluegreen: '#00737e',
  darkGreenBlue: '#1e686f',
  darkSea: '#0e4a52',
  darkSlateBlue: '#15374e',
  salmon: '#ff7675',
  pinkyRed: '#ff244e',
  watermelon: '#f54668',
  scarlet: '#d0021b',
  carmine: '#a00316',
  paleSkyBlue: '#c7e4ff',
  darkSkyBlue: '#4a90e2',
  lightViolet: '#dbc5f0',
  sunnyYellow: '#f8e71c',
  brightBlue: '#007aff',
  backgroundGrey: '#F7F7F7',
  hoverGreen: '#008B98',
  borderGrey: '#F2F2F2',
  bottomLineGrey: '#ECEFF1',
};

const theme = {
  primary: zeplin.paleTurquoise,
  text: {
    primary: zeplin.darkSlateBlue,
    secondary: zeplin.sea,
    higlight: zeplin.sunnyYellow,
  },
  list: {
    border: zeplin.lightGray,
  },
  error: '#DD4A4A',
};

export const colors = {
  ...zeplin,
  ...theme,
};
