const companyBasic = `
  id
  name
  logo
  background
  tagLine
  url
  type
  companyId
  resourceCenterName
  createdAt
`;

const user = `
id
firstName
lastName
sex
mobileNo
email
iconUrl
timezone
deletedAt
companyId
active
isAppInstalled
company {
  ${companyBasic}
}
`;

const adminUser = `
id
firstName
lastName
email
iconUrl
deletedAt
role
active
company
`;

const authorized = `
accessToken
data {
  ${user}
}
`;

const announcement = `
id
title
description
additionalDescription
thumbnail
image
link
publishStartDate
publishEndDate
expirationDate
cta
type
timezone
accessKeys {
  id
  key
  company {
    id
    name
    companyId
  }
}
publishTypes {
  type
}
`;

const location = `
id
name
address
longitude
latitude
`;

const careTeamRole = `
id
name
description
`;

const careTeamMember = `
id
user {
  ${user}
}
careTeamRoles{
  ${careTeamRole}
}
`;

const InviteLink = `
role {
  ${careTeamRole}
}
link
`;

const careTeam = `
id
name
public
owner {
  ${user}
}
teamMembers {
  ${careTeamMember}
}
inviteLinks{
  ${InviteLink}
}
`;

const company = `
${companyBasic}
publicTeam {
  id
  name
  public
  owner {
    ${user}
  }
}
location {
  ${location}
}
accessKeys {
  id
  key
}
`;

const employeeBenefit = `
id
name
headline
description
image
link
company {
  id
  companyId
}
links {
  id
  label
  val
}
phones{
  id
  label
  val
}
categories {
  id
  title
}
enrollmentDetails
callToAction
callToActionSecondary
requiresEnrollment
appointmentTypes{
  id
  name
}
needTypes{
  id
  name
  description
}
careConditions{
  id
  name
  description
}
`;

const appointmentType = `
id
name
`;

const needType = `
id
name
description
iconUrl
imageUrl
private
isSingleLocation
`;

const careCondition = `
id
name
description
`;

const invitation = `
id
status
email
mobileNo
inviteId
iconUrl
careTeamRoleId
careTeamId
name
inviterId
`;

// Posts
const attachment = `
  id
  url
  type
`;

const recurrence = `
  type
  weekOfMonth
  dayOfWeek
`;

const event = `
  id
  title
  startDate
  endDate
  timezone
  description
  channelSid
  author { ${user} }
  createdFor { ${user} }
  careTeamId
  attachment { ${attachment} }
  location { ${location} }
  isReminderEnabled
  postId
  rsvpStatus
  isAllDay
  visibility
  recurrenceRootId
  recurrence {
    ${recurrence}
  }
`;

const eventFeed = `
  total
  cursor
  data { ${event} }
`;

const merchantProgram = `
  id
  name
`;

const metaTag = `
  id
  name
`;

const resourceCategory = `
  id
  name
  level
  categoryId
`;

const scrapy = `
  title
`;

const resourceBasic = `
  id
  additionalNote
  appDescription
  appLink
  appLinkText
  appMerchantName
  isApp
  isWeb
  merchantHomePageUrl
  merchantName
  merchantPPCLink
  merchantPageMetadata
  merchantStatus
  status
  merchantTrackingMetadata
  merchantTrackingUrl
  webAutofill
  webDescription
  webLink
  webLinkText
  webMerchantName
  stage
  scheduledAt
  appImage { ${attachment} }
  webImage { ${attachment} }
`;

const resource = `
  ${resourceBasic}
  appointmentTypes { ${appointmentType} }
  company { ${company} }
  conditions { ${careCondition} }
  megaCategories { ${resourceCategory} }
  merchantProgram { ${merchantProgram} }
  metaTags { ${metaTag} }
  subCategories { ${resourceCategory} }
  needTypes { ${needType} }
  topics { ${resourceCategory} }
`;

export const fields = {
  user,
  authorized,
  announcement,
  appointmentType,
  careCondition,
  careTeam,
  company,
  employeeBenefit,
  event,
  eventFeed,
  needType,
  invitation,
  merchantProgram,
  metaTag,
  resourceCategory,
  scrapy,
  resourceBasic,
  resource,
  companyBasic,
  adminUser,
};
