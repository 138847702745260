/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNil } from 'lodash';
import { Button, Paper, Grid } from '@material-ui/core';
import { ButtonProgress } from 'components/Buttons';
import { useSnackbar, View, Text, CSVFileReader } from 'components/Common';

import { EventsFileData, useCreateMultipleEventsMutation } from 'core/api';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { m, StyleProps, Styles } from 'styles';
import { errToStr, Log } from 'utils';
import { DataTable } from 'components/Tables';
import { getFileErrors, polishFileData } from './utils';

const log = Log('screen.DashboardCompaniesEdit.scenes.CreateEvent');

interface Props extends StyleProps {
  publicTeamId: number;
  handleGoBack: () => void;
}
type EventsData = Partial<EventsFileData>;

export const ImportEventsForm: FC<Props> = ({ style, publicTeamId, handleGoBack }) => {
  // Form

  const [data, setData] = useState<EventsData[]>([]);
  const [errors, setErrs] = useState<string | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);

  const createEvents = useCreateMultipleEventsMutation({ publicTeamId });

  // Navigation

  const history = useHistory();

  // UI

  const snackbar = useSnackbar();

  // Data
  const filterEventItemsWithSearch = (search: string, items: EventsData[]) => {
    const searchMod = search.toLowerCase().trim();
    return items.filter(itm => {
      const { title } = itm;
      const nameMod = title ? title.toLowerCase().trim() : '';
      return nameMod.indexOf(searchMod) >= 0;
    });
  };

  // Handlers

  const handleSubmitPress = async () => {
    try {
      const curErrs = getFileErrors(data);
      if (curErrs) {
        return setErrs(curErrs);
      }
      const input: { [index: string]: any }[] = polishFileData(data);
      log.info('creating multiple events, input=', input);
      setProcessing(true);
      await createEvents(input);
      setProcessing(false);
      log.info('create multiple events done, input=', input);
      snackbar.success('Create multiple events successfully');
      history.goBack();
    } catch (err) {
      setProcessing(false);
      log.err(err);
      snackbar.err(errToStr(err) || 'Unknown error');
    }
  };

  // Render

  const renderDate = (date: string | Date | undefined): string => {
    if (!date) {
      return '';
    }
    return moment(date).local().format('ddd, MMM DD YYYY, h:mmA');
  };

  const renderImage = (url: string | undefined, item: any) => {
    if (isNil(url)) return null;

    return <img width={80} src={url} alt={item.title} />;
  };

  return (
    <Paper style={m(styles.container, style)}>
      <View style={styles.actions} row={true} justifyContent="space-between">
        <Text size={21}>Import Events</Text>

        <View row={true} justifyContent="flex-end">
          <Button style={styles.actionBtn} variant="contained" color="default" onClick={handleGoBack}>
            Back
          </Button>
          <Button
            style={styles.actionBtn}
            variant="contained"
            color="primary"
            disabled={processing}
            onClick={handleSubmitPress}
          >
            {!processing ? `Submit` : <ButtonProgress />}
          </Button>
        </View>
      </View>
      <Grid style={m(styles.gridContainer, style)} container={true} spacing={2}>
        <Grid item={true} md={12} style={styles.reader}>
          <CSVFileReader setData={value => setData(value)} setErrs={setErrs} />
          <Text color="red">{errors}</Text>
        </Grid>
        <Grid item={true} md={12}>
          <DataTable
            title="events"
            items={data || []}
            bottomBorder="line"
            searchPlaceholder="Search event..."
            filterWithSearch={filterEventItemsWithSearch}
            columns={{
              image: {
                title: 'Image',
                valExtractor: (val, item) => renderImage(val, item),
              },
              title: {
                title: 'Title',
                valExtractor: val => `${val}`,
              },
              description: {
                title: 'Details',
                valExtractor: val => `${val}`,
              },
              timezone: {
                title: 'Timezone',
                valExtractor: val => `${val}`,
              },
              visibility: {
                title: 'Visibility',
                valExtractor: val => `${val}`,
              },
              startDate: {
                title: 'Start',
                valExtractor: renderDate,
              },
              endDate: {
                title: 'End',
                valExtractor: renderDate,
              },
              recurrence: {
                title: 'Recurrence',
                valExtractor: val => `${val}`,
              },
              isAllDay: {
                title: 'isAllDay',
                valExtractor: val => `${val}`,
              },
              isReminderEnabled: {
                title: 'isReminderEnabled',
                valExtractor: val => `${val || ''}`,
              },
              location_name: {
                title: 'location_name',
                valExtractor: val => val || '',
              },
              location_address: {
                title: 'location_address',
                valExtractor: val => val || '',
              },
              location_longitude: {
                title: 'location_longitude',
                valExtractor: val => val || '',
              },
              location_latitude: {
                title: 'location_latitude',
                valExtractor: val => val || '',
              },
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const styles: Styles = {
  container: {
    padding: 20,
    marginTop: 20,
  },
  gridContainer: {
    padding: 20,
  },
  reader: {
    marginTop: 6,
    marginBottom: 20,
  },
  actions: {
    marginTop: 20,
  },
  actionBtn: {
    marginLeft: 6,
    width: 100,
  },
};

export type DashboardImportEventsSceneProps = Props;
export default ImportEventsForm;
