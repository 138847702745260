import { omit } from 'lodash';
import { UserInput } from 'core/api';
import { UserFormRecords } from 'components/Users/UserEditForm';
import { ROLES } from 'core/constants';

export const normalizeFormValues = (payload: UserFormRecords): UserInput => {
  return {
    ...omit(payload, ['company']),
    role: payload.role?.value || ROLES.CONTENT_PARTNER,
    company: payload.company,
    iconDataURI: payload.iconDataURI?.uri || payload.iconDataURI?.url,
  };
};
