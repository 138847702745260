import { View, Text } from 'components/Common';
import React, { FC } from 'react';
import { StyleProps, Styles, m, colors } from 'styles';
import { FormLabel, FormHelperText } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { mbToB } from 'utils';
import FormImgPickerFieldItem from './components/Item';

interface Props extends StyleProps {
  label?: string;
  value?: string;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  helperText?: string;
  size?: number;
  onChange?: (v: string | undefined) => void;
}

export const FormImgPickerField: FC<Props> = ({
  style,
  label,
  placeholder = `Drag 'n' drop some files here, or click to select files`,
  helperText,
  value,
  error,
  required,
  size,
  disabled,
  onChange,
}) => {
  // Handlers

  const handleChange = (files: File[]) => {
    if (!files.length) {
      return;
    }
    const reader = new FileReader();
    // reader.onabort = () => console.log('file reading was aborted')
    // reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      const base64Str = reader.result;
      if (onChange && base64Str) {
        onChange(base64Str?.toString());
      }
    };
    reader.readAsDataURL(files[0]);
  };

  // Dropzone

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['.jpg', '.jpeg', '.png'],
    maxSize: mbToB(3),
    multiple: false,
    onDrop: handleChange,
  });

  // Render

  const renderContent = () => (value ? renderValue() : renderPlaceholder());

  const renderValue = () => (
    <View>
      <FormImgPickerFieldItem src={value} size={size} onRemovePress={() => onChange && onChange(undefined)} />
    </View>
  );

  const renderPlaceholder = () =>
    placeholder
      ? placeholder.split(`\n`).map((itm, key) => (
          <Text style={styles.text} key={key} block={true}>
            {itm}
          </Text>
        ))
      : null;

  const renderDragActive = () => <Text style={styles.text}>{`Drop the files here...`}</Text>;

  return (
    <View style={[styles.container, style]}>
      {!!label && (
        <FormLabel style={styles.label} error={error} required={required} disabled={disabled}>
          {label}
        </FormLabel>
      )}
      <div style={m(styles.dropzone, error && { borderColor: colors.error })} {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? renderDragActive() : renderContent()}
      </div>
      {!!helperText && (
        <FormHelperText required={required} disabled={disabled} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </View>
  );
};

const styles: Styles = {
  container: {
    cursor: 'pointer',
  },
  label: {
    marginBottom: 8,
  },
  dropzone: {
    borderColor: '#E3E3E3',
    borderWidth: 3,
    borderStyle: 'dashed',
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 181,
  },
  text: {
    color: '#B6C3CA',
    textAlign: 'center',
    marginTop: 2,
    marginBottom: 2,
  },
};

export type FormImgPickerFieldProps = Props;
export default FormImgPickerField;
