import React, { FC, useEffect } from 'react';
import { UserInput } from 'core/api';
import { Button, CircularProgress } from '@material-ui/core';
import { omit, isEmpty } from 'lodash';
import { isSuperAdmin } from 'utils/permissions';
import UserEditForm, { UserFormRecords } from 'components/Users/UserEditForm';
import { DefaultValues, useForm } from 'react-hook-form';
import { useAuth } from '../../../hooks/useAuth';
import SectionTitle from 'components/SectionTitle';
import SectionCard from 'components/SectionCard';
import Grid from '@material-ui/core/Grid';
import { normalizeFormValues } from 'screens/Users/util';

interface InjectedProps {
  onCancel: () => void;
  onSubmit: (payload: UserInput) => void;
  title?: string;
  defaultValues?: DefaultValues<UserFormRecords>;
  loading?: boolean;
}

const EditUserCell: FC<InjectedProps> = ({ onCancel, title = 'Edit User', defaultValues = {}, onSubmit, loading }) => {
  const form = useForm<UserFormRecords>({
    defaultValues,
  });

  useEffect(() => {
    if (isEmpty(defaultValues)) return;

    form.reset(defaultValues);
  }, [defaultValues]);

  const { user: currentUser } = useAuth();

  const processFormData = async (data: UserFormRecords) => {
    const payload: UserInput = omit(
      normalizeFormValues({
        ...data,
      }),
      '__typename',
    );

    onSubmit(payload);
  };

  const handleCancelClick = () => {
    onCancel();
  };

  const handleSaveClick = form.handleSubmit(processFormData);

  return (
    <>
      <Grid direction="row" justify="space-between" container>
        <Grid item>
          <SectionTitle title={title} />
        </Grid>
        <Grid item>
          <Button
            disabled={!!loading}
            style={{ marginRight: 12 }}
            onClick={handleCancelClick}
            variant="outlined"
            disableElevation
          >
            Cancel
          </Button>

          {isSuperAdmin(currentUser) && (
            <Button
              disabled={!!loading}
              style={{ marginRight: 12 }}
              onClick={handleSaveClick}
              variant="contained"
              color="primary"
              disableElevation
            >
              Save
              {loading && <CircularProgress size={14} style={{ marginLeft: 8, color: 'inherit' }} />}
            </Button>
          )}
        </Grid>
      </Grid>

      <SectionCard style={{ marginTop: 25 }}>
        <UserEditForm form={form} disabled={!isSuperAdmin(currentUser)} />
      </SectionCard>
    </>
  );
};

export default EditUserCell;
