import React, { FC } from 'react';
import { m, StyleProps, Styles } from 'styles';

type Props = StyleProps & StylesOpt;

interface StylesOpt {
  src?: string;
  position?: string;
  size?: 'contain' | 'cover' | string;
}

export const BackgroundImage: FC<Props> = ({ style, src, position, size, children }) => {
  const styles = getStyles({ src, position, size });
  return <div style={m(styles.container, style)}>{children}</div>;
};

const getStyles = ({ src, position, size }: StylesOpt): Styles => ({
  container: {
    backgroundImage: `url(${src})`,
    backgroundPosition: position,
    backgroundSize: size,
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
  },
});

export type BackgroundImageProps = Props;
export default BackgroundImage;
