import { CircularProgress } from '@material-ui/core';
import React, { FC } from 'react';
import { m, StyleProps, Styles } from 'styles';

type Props = StyleProps;

export const ButtonProgress: FC<Props> = ({ style }) => (
  <CircularProgress style={m(styles.container, style)} size="small" />
);

const styles: Styles = {
  container: {
    width: 20,
    height: 20,
  },
};

export type ButtonProgressProps = Props;
export default ButtonProgress;
