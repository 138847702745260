import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Style } from 'styles';

interface InjectedProps<T> {
  options: T[];
  keyExtractor: (option: T) => string;
  className?: string;
  loading?: boolean;
  label?: string;
  hasError?: boolean;
  onChange: (value: T | null) => void;
  defaultValue?: T;
  style?: Style;
}

const FormSelectAutocompleteField: <T>(p: InjectedProps<T>) => React.ReactElement<InjectedProps<T>> = ({
  style,
  loading,
  options,
  keyExtractor,
  defaultValue,
  onChange,
  className,
  label,
  hasError,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [value, setValue] = useState<any>(defaultValue || options[0]);
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    if (!defaultValue) return;

    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      {!!label && (
        <div>
          <span>{label}</span>
        </div>
      )}
      <Autocomplete
        style={style}
        onChange={(event, newValue) => {
          onChange(newValue);
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        value={value}
        loading={loading}
        options={options}
        getOptionLabel={keyExtractor}
        className={className}
        renderInput={params => <TextField {...params} error={hasError} variant="outlined" />}
      />
    </>
  );
};

export default FormSelectAutocompleteField;
