import { GraphQLError } from 'graphql';

import { SystemRole } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isUserHasAdminRole = (items: SystemRole[]): boolean => true;

export const graphQlErrorsToStr = (errors: readonly GraphQLError[]): string =>
  errors.reduce((memo, err) => (memo ? `${memo}\n${err.message}` : err.message), '');

export const isEmailValid = (email: string): boolean => {
  // eslint-disable-next-line max-len
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};
