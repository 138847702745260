import React, { FC } from 'react';
import { UserInput } from 'core/api';
import { CircularProgress, Button } from '@material-ui/core';
import { omit } from 'lodash';
import UserEditForm, { UserFormRecords } from 'components/Users/UserEditForm';
import { useForm } from 'react-hook-form';
import SectionTitle from 'components/SectionTitle';
import SectionCard from 'components/SectionCard';
import Grid from '@material-ui/core/Grid';
import { normalizeFormValues } from 'screens/Users/util';

interface InjectedProps {
  onSubmit: (payload: UserInput) => void;
  loading: boolean;
  onCancel: () => void;
  title?: string;
  okText?: string;
}

const CreateUserCell: FC<InjectedProps> = ({
  onSubmit: onSubmitCb,
  onCancel,
  loading,
  title = 'Create User',
  okText = 'Save',
}) => {
  const form = useForm<UserFormRecords>({
    defaultValues: {},
  });

  const onSubmit = async (data: UserFormRecords) => {
    const payload: UserInput = omit(
      normalizeFormValues({
        ...data,
      }),
      '__typename',
    );

    onSubmitCb(payload);
  };

  const handleSaveClick = form.handleSubmit(onSubmit);

  return (
    <>
      <Grid direction="row" justify="space-between" container>
        <Grid item>
          <SectionTitle title={title} />
        </Grid>
        <Grid item>
          <Button
            disabled={!!loading}
            style={{ marginRight: 12 }}
            onClick={onCancel}
            variant="outlined"
            disableElevation
          >
            Cancel
          </Button>

          <Button
            disabled={!!loading}
            style={{ marginRight: 12 }}
            onClick={handleSaveClick}
            variant="contained"
            color="primary"
            disableElevation
          >
            {okText}
            {loading && <CircularProgress size={14} style={{ marginLeft: 8, color: 'inherit' }} />}
          </Button>
        </Grid>
      </Grid>

      <SectionCard style={{ marginTop: 25 }}>
        <UserEditForm form={form} />
      </SectionCard>
    </>
  );
};

export default CreateUserCell;
