import { User, InvitationContactsInput } from 'core/api';
import { requiredMsg } from 'utils';

import { InviteFormData, InviteFormErrors } from './types';

export const getDefCompaniesEditFormData = (): InviteFormData => ({
  careTeamRoleId: 2,
  contactType: 'EMAIL',
  inviteeName: 'User',
});

export const polishInviteFormData = (value: InviteFormData): InviteFormData => {
  let modVal = value;
  // Message = 300 character
  if (modVal.message && modVal.message.length > 300) {
    modVal = { ...modVal, message: modVal.message.substring(0, 300) };
  }

  return modVal;
};

export const getInviteFormErrors = (data: InviteFormData): InviteFormErrors | undefined => {
  const { careTeamId } = data;
  if (!careTeamId) {
    return { careTeamId: requiredMsg('Team') };
  }
  return undefined;
};

export const polishContactData = (value: string, user: User | undefined): InvitationContactsInput[] => {
  const newContact: { email?: string | undefined; mobileNo?: string | undefined }[] = [];
  if (value === 'EMAIL') {
    newContact.push({ email: user?.email });
  }
  if (value === 'MOBILE') {
    newContact.push({ mobileNo: user?.mobileNo });
  }
  return newContact;
};
