import React, {useRef, useState} from 'react';
import {isEqual, isNil, omit} from 'lodash';
import {Dialog, DialogTitle, List, ListItem, ListItemText} from '@material-ui/core';
import {Event, EventUpdateDeleteOptions, RecurrentPeriod, UpdateEventInput} from 'core/api';

export interface InjectedRecurringOptionsProps {
  showUpdateRecurringDialog: (event: Event, input: UpdateEventInput, cb: (input: UpdateEventInput) => void) => void;
  showRemoveRecurringDialog: (event: Event, cb: (type: EventUpdateDeleteOptions) => void) => void;
}

export type RecurringOption = {
  title: string;
  onClick: () => void;
};

const withRecurringOptions = <P extends InjectedRecurringOptionsProps>(
  Component: React.ComponentType<P>,
): React.FC<Omit<P, 'showUpdateRecurringDialog' | 'showRemoveRecurringDialog'>> => props => {
  const [isOpen, setOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callbackValue = useRef<(input: any) => void>();
  const inputValue = useRef<UpdateEventInput>();
  const eventValue = useRef<Event>();

  const reset = () => {
    setOpen(false);
    callbackValue.current = undefined;
    inputValue.current = undefined;
    eventValue.current = undefined;
  };

  const showUpdateRecurringDialog = (event: Event, input: UpdateEventInput, cb: (input: UpdateEventInput) => void) => {
    if (event.recurrence && input.recurrence && input.recurrence.type !== RecurrentPeriod.NEVER) {
      callbackValue.current = cb;
      inputValue.current = input;
      eventValue.current = event;

      setOpen(true);

      return;
    }

    cb({
      ...input,
      type: EventUpdateDeleteOptions.THIS
    })
  };

  const showRemoveRecurringDialog = (event: Event, cb: (type: EventUpdateDeleteOptions) => void) => {
    if (isNil(event.recurrence)) {
      cb(EventUpdateDeleteOptions.THIS);

      return;
    }

    callbackValue.current = cb;
    eventValue.current = event;

    setOpen(true);
  };

  const onClose = () => {
    reset();
  };

  const onOptionClick = (type: EventUpdateDeleteOptions) => {
    if (isNil(callbackValue.current)) return;

    // Delete event.
    if (isNil(inputValue.current)) {
      callbackValue.current(type);
      reset();
      return;
    }

    callbackValue.current({
      ...inputValue.current as UpdateEventInput,
      type
    });
    reset();
  };

  const renderTitle = (isUpdate: boolean) => {
    return !isUpdate
      ? `This is a recurring event. Would you like to remove:`
      : `This is a recurring event. Would you like to update:`
  };

  const renderOptions = () => {
    const event = eventValue.current;
    const params = inputValue.current;

    if (isNil(event)) return;

    const isRecurrenceChanged = !isEqual(omit(event.recurrence, '__typename'), omit(params?.recurrence, '__typename'));

    const isRecurring = !isNil(eventValue.current);

    let options: RecurringOption[] = [];

    if (isRecurring) {
      options = [
        {
          title: 'This event',
          onClick: () => onOptionClick(EventUpdateDeleteOptions.THIS),
        },
        {
          title: 'This and following events',
          onClick: () => onOptionClick(EventUpdateDeleteOptions.THIS_AND_FOLLOWING),
        },
        {
          title: 'All events',
          onClick: () => onOptionClick(EventUpdateDeleteOptions.ALL),
        },
      ];

      if (!isNil(params) && isRecurrenceChanged) {
        options.splice(0, 1); // If recurrence has been changed then omit ability to change only THIS event
      }
    }

    return (
      <List>
        {options.map(({ onClick, title }) => (
          <ListItem button onClick={onClick} key={title}>
            <ListItemText primary={title} />
          </ListItem>
        ))}
      </List>
    )
  };

  return (
    <>
      <Component
        {...props as P}
        showRemoveRecurringDialog={showRemoveRecurringDialog}
        showUpdateRecurringDialog={showUpdateRecurringDialog}
      />

      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={isOpen}>
        <DialogTitle>{renderTitle(!isNil(inputValue.current))}</DialogTitle>
        {renderOptions()}
      </Dialog>
    </>
  );
};

export default withRecurringOptions;
