import React, { FC, ChangeEvent } from 'react';
import { StyleProps } from 'styles';
import Pagination from '@material-ui/lab/Pagination';

interface Props extends StyleProps {
  count?: number;
  page?: number;
  shape?: 'rounded' | 'round';
  onChange?: (event: ChangeEvent<HTMLInputElement>, newPage: number) => void;
}

export const GenericPagination: FC<Props> = ({ count, shape = 'rounded', page, style, onChange }) => {
  return <Pagination style={style} count={count} shape={shape} page={page} onChange={onChange} />;
};

export type TablePaginationProps = Props;
export default GenericPagination;
