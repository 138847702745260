import React, { useRef, useState } from 'react';
import { Header } from 'components/Common';
import { omit, isNil } from 'lodash';
import { ResourceStage } from 'core/apollo/consts';
import ResourceForm, { ResourceFormRecords } from 'components/Resources/ResourceForm';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import { Alert, Color } from '@material-ui/lab';
import { useHistory, useLocation, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import ResourceFormHeader from 'components/Resources/ResourceFormHeader';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { GET_RESOURCE } from 'core/apollo/queries/resources';
import { ResourceResponse, ResourceInput, ResourceVariables } from 'core/api';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { humanizeMerchantStatus, humanizeResourceStatus } from 'utils/str';
import { normalizeFormValues } from 'screens/Resources/util';
import { routes } from 'screens/consts';
import { UPDATE_RESOURCE } from 'core/apollo/mutation/resources';
import Modal, { ConfirmationModalRef } from 'components/Modal';
import { useAuth } from '../../../hooks/useAuth';
import { isContentPartner, isSuperAdmin } from 'utils/permissions';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    overflow: 'hidden',
  },
}));

const ResourceEditScreen = () => {
  const modalRef = useRef<ConfirmationModalRef>();
  const classes = useStyles();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [loading, setLoading] = useState<ResourceStage>();
  const [snackbar, setSnackbar] = useState<{ severity: Color; message: string }>();
  const form = useForm<ResourceFormRecords>({
    reValidateMode: 'onChange',
    defaultValues: {
      stage: ResourceStage.DRAFT,
      additionalNote: '',
      appDescription: '',
      appImage: null,
      appLink: '',
      appLinkText: 'Learn More',
      appMerchantName: '',
      appointmentTypes: [],
      company: null,
      conditions: [],
      isApp: true,
      isWeb: true,
      megaCategories: [],
      merchantHomePageUrl: '',
      merchantName: '',
      merchantPPCLink: '',
      merchantPageMetadata: '',
      merchantProgram: null,
      merchantStatus: null,
      merchantTrackingMetadata: '',
      merchantTrackingUrl: '',
      metaTags: [],
      subCategories: [],
      needTypes: [],
      topics: [],
      webAutofill: false,
      webDescription: '',
      webImage: null,
      webLink: '',
      webLinkText: 'Visit Website',
      webMerchantName: '',
    },
  });
  const { user } = useAuth();
  const client = useApolloClient();

  const { loading: initializing, data } = useQuery<ResourceResponse, ResourceVariables>(GET_RESOURCE, {
    fetchPolicy: 'network-only',
    variables: { id: parseInt(id, 10) },
    onCompleted: data => {
      const { resource } = data;

      if (isNil(resource)) return;

      form.reset({
        ...omit(resource, ['id', 'merchantStatus', 'status']),
        webAutofill: false,
        // Just in order to conform form select options
        merchantStatus: { name: humanizeMerchantStatus(resource.merchantStatus), id: resource.merchantStatus },
        status: { name: humanizeResourceStatus(resource.status), id: resource.status },
      });
    },
  });

  const [updateResource] = useMutation(UPDATE_RESOURCE);

  const onSubmit = async (data: ResourceFormRecords, stage: ResourceStage) => {
    const payload: ResourceInput = omit(
      normalizeFormValues({
        ...data,
        stage,
      }, client),
      '__typename',
    );

    try {
      setLoading(stage);
      await updateResource({
        variables: {
          input: payload,
          id: parseInt(id, 10),
        },
        optimisticResponse: {
          updateResource: {
            id: parseInt(id, 10),
            __typename: 'Resource',
            ...data,
          },
        },
      });
      setLoading(undefined);
      setSnackbar({ severity: 'success', message: 'Resource updated successfully!' });
      history.push(routes.resources.list);
    } catch (e) {
      setSnackbar({ severity: 'error', message: e.message });
      setLoading(undefined);
    }
  };

  const handleSubmitClick = (stage: ResourceStage) => {
    form.handleSubmit(
      d => onSubmit(d, stage),
      errors => {
        const inputName = Object.keys(errors)[0];

        if (inputName) {
          const el = document.getElementById(inputName);
          el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    )();
  };

  const handleEditCancelClick = () => {
    history.push(routes.resources.list);
  };

  const onSnackbarClose = () => {
    setSnackbar(undefined);
  };

  if (initializing)
    return (
      <div className={classes.container}>
        <CircularProgress color="inherit" />
      </div>
    );

  return (
    <div>
      <Header path={location.pathname} />

      <ResourceFormHeader
        isDirty={form.formState.isDirty}
        onCancel={handleEditCancelClick}
        loading={loading}
        onClick={handleSubmitClick}
        resource={data?.resource}
      />

      <ResourceForm form={form} disabled={!(isSuperAdmin(user) || isContentPartner(user))} />

      <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={onSnackbarClose}>
        <Alert onClose={onSnackbarClose} severity={snackbar?.severity}>
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <Modal modalRef={modalRef} />
    </div>
  );
};

export default ResourceEditScreen;
