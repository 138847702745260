import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { FC } from 'react';
import { StyleProps } from 'styles';

interface Props extends StyleProps {
  value?: string;
  placeholder?: string;
  label?: string;
  autoFocus?: boolean;
  onChange?: (v: string) => void;
}

export const FormSearchField: FC<Props> = ({ style, value, placeholder, label, autoFocus, onChange }) => {
  const classes = useStyles({});
  return (
    <TextField
      style={style}
      value={value}
      type="search"
      variant="outlined"
      autoFocus={autoFocus}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        classes: {
          input: classes.input,
          adornedStart: classes.adornedStart,
          notchedOutline: classes.notchedOutline,
        },
      }}
      placeholder={placeholder}
      label={label}
      onChange={e => onChange && onChange(e.target.value)}
    />
  );
};

const useStyles = makeStyles({
  notchedOutline: {
    border: `1px solid #F7F7F7 !important`,
  },
  input: {
    backgroundColor: '#F6F7F8',
    borderRadius: 4,
    padding: '11px 14px',
  },
  adornedStart: {
    backgroundColor: '#F6F7F8',
  },
});

export type FormSearchFieldProps = Props;
export default FormSearchField;
