import { PageTitle, Header } from 'components/Common';
import { useLocation, useHistory } from 'react-router-dom';
import React, { FC } from 'react';
import { StyleProps } from 'styles';
import { Log } from 'utils';
import { ImportEventsForm } from 'components/Companies';

const log = Log('screen.DashboardCompaniesEdit.scenes.CreateEvent');

type Props = StyleProps;

interface stateType {
  publicTeamId: number;
}

export const DashboardImportEventsScene: FC<Props> = () => {
  // Deps

  const location = useLocation();
  const history = useHistory();

  const { state } = useLocation<stateType>();
  const { publicTeamId } = state;

  // Handlers

  const handleGoBack = () => {
    log.debug('back button press');
    history.goBack();
  };

  return (
    <>
      <PageTitle title={'Import Events'} />
      <Header path={location.pathname} />
      <ImportEventsForm publicTeamId={publicTeamId} handleGoBack={handleGoBack} />
    </>
  );
};

export type DashboardImportEventsProps = Props;
export default DashboardImportEventsScene;
