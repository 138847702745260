import { gql } from '@apollo/client';
import { fields } from 'core/api';

export const GET_RESOURCES = gql`
    query resources(
            $categoryIds: [Int!], 
            $offset: Int, 
            $limit: Int, 
            $searchQuery: String, 
            $orderBy: [ResourceOrderBy!]
        ) {
        resourceFeed(
                categoryIds: $categoryIds,
                offset: $offset, 
                limit: $limit, 
                searchQuery: $searchQuery, 
                orderBy: $orderBy
            ) {
            total
            data {
                ${fields.resourceBasic}
                merchantProgram { ${fields.merchantProgram} }
                megaCategories {
                    ${fields.resourceCategory}
                }
            }
        }
    }
`;

export const GET_RESOURCE = gql`
  query resource($id: Int!) {
      resource(id: $id) {
          ${fields.resource}
      }
  }
`;
