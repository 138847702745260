import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import ResourceMerchantForm from 'components/Resources/ResourceForm/components/MerchantForm';
import AppSpecificResourceForm from 'components/Resources/ResourceForm/components/AppSpecificResourceForm';
import WebSpecificResourceForm from 'components/Resources/ResourceForm/components/WebSpecificResourceForm';
import SectionCard from 'components/SectionCard';
import SectionTitle from 'components/SectionTitle';
import ResourceInternalUseForm from 'components/Resources/ResourceForm/components/InternalUseForm';
import {
  AppointmentType,
  Attachment,
  CareCondition,
  Company,
  MerchantProgram,
  MetaTag,
  NeedType,
  ResourceCategory,
} from 'core/api';
import { MerchantStatus, ResourceStage, ResourceStatus } from 'core/apollo/consts';

export interface ResourceFormRecords {
  additionalNote: string;
  appDescription: string;
  appImage: Attachment | null;
  appLink: string;
  appLinkText: string;
  appMerchantName: string;
  appointmentTypes: AppointmentType[];
  company: Company | null;
  conditions: CareCondition[];
  isApp: boolean;
  isWeb: boolean;
  megaCategories: ResourceCategory[];
  merchantHomePageUrl: string | null;
  merchantName: string;
  merchantPPCLink: string | null;
  merchantPageMetadata: string | null;
  merchantProgram: MerchantProgram | null;
  merchantStatus: { name: string; id: MerchantStatus } | null;
  merchantTrackingMetadata: string | null;
  merchantTrackingUrl: string;
  metaTags: MetaTag[];
  subCategories: ResourceCategory[];
  needTypes: NeedType[];
  topics: ResourceCategory[];
  webAutofill: boolean;
  webDescription: string;
  webImage: Attachment | null;
  webLink: string;
  webLinkText: string;
  webMerchantName: string;
  stage: ResourceStage;
  status: { name: string; id: ResourceStatus } | null;
}

interface InjectedProps {
  form: UseFormReturn<ResourceFormRecords>;
  disabled?: boolean;
}

const ResourceForm: FC<InjectedProps> = ({ form, disabled = false }) => {
  return (
    <div>
      <form>
        <SectionCard>
          <ResourceMerchantForm disabled={disabled} form={form} />
        </SectionCard>

        <div style={{ marginTop: 46 }}>
          <SectionTitle title="Add Resource for App" />

          <SectionCard style={{ marginTop: 25 }}>
            <AppSpecificResourceForm disabled={disabled} form={form} />
          </SectionCard>
        </div>

        <div style={{ marginTop: 46 }}>
          <SectionTitle title="Add Resource for Website" />

          <SectionCard style={{ marginTop: 25 }}>
            <WebSpecificResourceForm disabled={disabled} form={form} />
          </SectionCard>
        </div>

        <div style={{ marginTop: 46 }}>
          <SectionTitle title="For Internal Use" />

          <SectionCard style={{ marginTop: 25 }}>
            <ResourceInternalUseForm disabled={disabled} form={form} />
          </SectionCard>
        </div>
      </form>
    </div>
  );
};

export default ResourceForm;
