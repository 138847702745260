import React, { useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { isNil } from 'lodash';
import SectionTitle from 'components/SectionTitle';
import { Header } from 'components/Common';
import { Button } from '@material-ui/core';
import { GridRowModel, GridRowParams, GridSortModelParams } from '@material-ui/data-grid';
import placeholder from '../../../assets/placeholder.png';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { makeStyles } from '@material-ui/core/styles';
import { routes } from 'screens/consts';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { GET_RESOURCES } from 'core/apollo/queries/resources';
import {
  Attachment,
  MerchantProgram,
  Resource,
  ResourceCategory,
  ResourceFeedResponse,
  ResourceFeedVariables
} from 'core/api';
import { MerchantStatus, ResourceStage, ResourceStatus } from 'core/apollo/consts';
import Tag from 'components/Tag';
import { humanizeMerchantStatus, humanizeResourceStage, humanizeResourceStatus } from 'utils/str';
import Chip from '@material-ui/core/Chip';
import { isContentPartner, isSuperAdmin } from 'utils/permissions';
import { useAuth } from '../../../hooks/useAuth';
import MenuButton, { MenuButtonOption } from 'components/MenuButton';
import Modal, { ConfirmationModalRef } from 'components/Modal';
import ScheduleDatePicker, { ScheduleDatePickerRef } from 'components/ScheduleDatePicker';
import DataTable from 'components/Common/DataTable';
import { DELETE_RESOURCE, UPDATE_RESOURCE_STAGE } from 'core/apollo/mutation/resources';
import { RESOURCE_ACTION_DYNAMIC_TEXT, RESOURCE_ACTION_TEXT } from 'core/constants';
import { accessTokenStorage } from 'core/storage';
import { format } from 'date-fns';
import { appConfigs } from 'core/configs';

const useStyles = makeStyles(() => ({
  rowImage: {
    width: 60,
    height: 60,
    objectFit: 'cover',
    borderRadius: 4,
  },
  cell: {
    padding: '0 4px !important',
  },
}));

export enum ResourceActionMenuType {
  EDIT,
  SEND_FOR_REVIEW,
  PUBLISH,
  SCHEDULED,
  UNPUBLISH,
  DELETE,
}

export enum SuperUserActionMenuType {
  EDIT,
  RESEND_INVITE,
  VIEW,
}

const ResourceListScreen = () => {
  const modalRef = useRef<ConfirmationModalRef>();
  const pickerRef = useRef<ScheduleDatePickerRef>();
  const { user } = useAuth();
  const container = useRef<HTMLDivElement>(null);
  const header = useRef<HTMLDivElement>(null);
  const sectionHeader = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const history = useHistory();
  const { height } = useWindowResize();
  const [headerHeight, setHeaderHeight] = useState(0);

  const [updateResource] = useMutation(UPDATE_RESOURCE_STAGE);
  const [deleteResource] = useMutation(DELETE_RESOURCE, { ignoreResults: true });
  const { data, loading, refetch, fetchMore, variables, error, networkStatus } = useQuery<
    ResourceFeedResponse,
    ResourceFeedVariables
  >(GET_RESOURCES, {
    variables: {
      offset: 0,
      limit: 25,
    },
    // fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useLayoutEffect(() => {
    if (container.current && header.current && sectionHeader.current) {
      setHeaderHeight(
        container.current.clientHeight - header.current.clientHeight - sectionHeader.current?.clientHeight - 24,
      );
    }
  }, [height]);

  const updateResourceStage = async (id: string | number, stage: ResourceStage, date?: Date) => {
    return updateResource({
      variables: {
        id: parseInt(id as string, 10),
        input: {
          stage,
          date,
        },
      },
      optimisticResponse: {
        updateResourceStage: {
          id: parseInt(id as string, 10),
          __typename: 'Resource',
          stage,
          scheduledAt: date,
        },
      },
    });
  };
  const handleDeletePress = (resource: Resource) => {
    modalRef.current?.show({
      title: RESOURCE_ACTION_DYNAMIC_TEXT.DELETE.getTitle(resource),
      content: RESOURCE_ACTION_DYNAMIC_TEXT.DELETE.getDescription(resource),
      cancelText: RESOURCE_ACTION_DYNAMIC_TEXT.DELETE.getCancelText,
      confirmText: RESOURCE_ACTION_DYNAMIC_TEXT.DELETE.getConfirmText,
      onCancel: () => null,
      onConfirm: () => deleteResource({
        variables: {
          id: resource.id,
        },
        update: cache => {
          cache.writeQuery({
            query: GET_RESOURCES,
            variables,
            data: {
              resourceFeed: {
                ...data?.resourceFeed,
                total: 0,
                data: data?.resourceFeed.data.filter(r => r.id !== resource.id) || []
              }
            }
          });
        }
      })
    });
    return;
  };

  const getRowMenuOptions = (row: GridRowModel) => {
    const options = [
      { type: ResourceActionMenuType.EDIT, label: 'Edit' },

      ...(row.stage === ResourceStage.DRAFT
        ? [{ type: ResourceActionMenuType.SEND_FOR_REVIEW, label: 'Ready For Review' }]
        : []),

      ...(row.stage !== ResourceStage.PUBLISHED
        ? [
          { type: ResourceActionMenuType.PUBLISH, label: 'Publish Now' },
          { type: ResourceActionMenuType.SCHEDULED, label: 'Schedule Publish Date' },
        ]
        : []),

      ...([ResourceStage.PUBLISHED, ResourceStage.SCHEDULED].includes(row.stage)
        ? [{ type: ResourceActionMenuType.UNPUBLISH, label: 'Un-Publish' }]
        : []),

      { type: ResourceActionMenuType.DELETE, label: 'Delete', danger: true }
    ];

    if (isSuperAdmin(user)) return options;

    if (isContentPartner(user))
      return options.filter(o =>
        [ResourceActionMenuType.EDIT, ResourceActionMenuType.SEND_FOR_REVIEW].includes(o.type),
      );

    return [];
  };

  const handleSearchQueryChange = (searchQuery: string) => {
    refetch({
      searchQuery,
    });
  };
  const handlePageChange = (offset: number) => {
    return fetchMore({
      variables: {
        ...variables,
        offset,
      },
    });
  };
  const handlePageSizeChange = (limit: number) => {
    refetch({
      offset: 0,
      limit,
    });
  };
  const handleRowClick = (row: GridRowParams) => {
    history.push(`${routes.resources.edit}/${row?.id}`);
  };
  const handleSchedulePublishClick = (date: Date, resource: Resource) => {
    updateResourceStage(resource.id, ResourceStage.SCHEDULED, date);
  };
  const handleRowActionClick = (option: MenuButtonOption, row: GridRowModel) => {
    switch (option.type as ResourceActionMenuType) {
      case ResourceActionMenuType.DELETE: {
        return handleDeletePress(row as Resource);
      }
      case ResourceActionMenuType.EDIT:
        return history.push(`${routes.resources.edit}/${row?.id}`);
      case ResourceActionMenuType.SEND_FOR_REVIEW:
        updateResourceStage(row.id, ResourceStage.READY_FOR_REVIEW);
        return;
      case ResourceActionMenuType.PUBLISH: {
        return modalRef.current?.show({
          title: RESOURCE_ACTION_TEXT.PUBLISH.TITLE,
          content: RESOURCE_ACTION_TEXT.PUBLISH.DESCRIPTION,
          cancelText: RESOURCE_ACTION_TEXT.PUBLISH.CANCEL_TEXT,
          confirmText: RESOURCE_ACTION_TEXT.PUBLISH.CONFIRM_TEXT,
          onCancel: () => null,
          onConfirm: () => updateResourceStage(row.id, ResourceStage.PUBLISHED),
        });
      }
      case ResourceActionMenuType.SCHEDULED: {
        pickerRef.current?.show(new Date(), row as Resource);
        return;
      }
      case ResourceActionMenuType.UNPUBLISH: {
        return modalRef.current?.show({
          title: RESOURCE_ACTION_TEXT.UNPUBLISH.TITLE,
          content: RESOURCE_ACTION_TEXT.UNPUBLISH.DESCRIPTION,
          cancelText: RESOURCE_ACTION_TEXT.UNPUBLISH.CANCEL_TEXT,
          confirmText: RESOURCE_ACTION_TEXT.UNPUBLISH.CONFIRM_TEXT,
          onCancel: () => null,
          onConfirm: () => updateResourceStage(row.id, ResourceStage.UNPUBLISHED),
        });
      }
    }
  };

  const handleCSVExportClick = async () => {
    try {
      const response = await fetch(`${appConfigs.api.baseUrl}/resources/export/csv`, {
        mode: 'cors',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${accessTokenStorage.get()}`
        }
      });

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${format(new Date(), 'yyyy-MM-dd')} Merchants`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (e) {
      return;
    }


    // const tempLink = document.createElement('a');
    // tempLink.href = 'http://localhost:4000/resources/export/csv';
    // tempLink.click();
    // return
  };

  const onSortChange = ({ sortModel }: GridSortModelParams) => {
    const orderBy = sortModel.map(({ field, sort }) => ({ [field]: sort?.toUpperCase() || 'DESC' }));

    refetch({
      ...variables,
      orderBy
    })
  }

  const classes = useStyles();

  return (
    <div style={{ height: '100%' }} ref={container}>
      <div ref={header}>
        <Header path={location.pathname} />
        <SectionTitle title="Marketplace" />
      </div>

      <DataTable
        columns={[
          { field: 'id', headerName: 'ID', width: 35, align: 'center', cellClassName: classes.cell },
          {
            field: 'appImage',
            headerName: 'Image',
            // eslint-disable-next-line react/display-name
            renderCell: ({ value }) => {

              const attachment = value as Attachment;

              if (isNil(attachment))
                return <img className={classes.rowImage} src={placeholder} />;

              return <img className={classes.rowImage} src={attachment.url} />;
            },
          },
          {
            flex: 1,
            field: 'merchantName',
            headerName: 'Title/Merchant Name',
          },
          {
            width: 120,
            field: 'merchantProgram',
            headerName: 'Program',
            // eslint-disable-next-line react/display-name
            renderCell: ({ value: program }) => {
              const programV = (program as MerchantProgram)?.name;

              if (!programV) return <span />;

              return <Tag textColor="#333" title={programV} />
            }
          },
          {
            field: 'status',
            headerName: 'Status',
            // eslint-disable-next-line react/display-name
            renderCell: ({ value: status }: { value: any }) => {
              return <Tag
                backgroundColor={status === ResourceStatus.APPROVED ? '#008A45' : '#f2f2f2'}
                textColor={status === ResourceStatus.APPROVED ? '#fff' : '#333'}
                title={humanizeResourceStatus((status as ResourceStatus) || null)}
              />
            }
          },
          {
            field: 'merchantStatus',
            headerName: 'Merchant Status',
            // eslint-disable-next-line react/display-name
            renderCell: ({ value: status }: { value: any }) => {
              return <Tag
                backgroundColor={'#f2f2f2'}
                textColor={'#333'}
                title={humanizeMerchantStatus((status as MerchantStatus) || null)}
              />
            }
          },
          {
            width: 130,
            field: 'megaCategories',
            headerName: 'Mega Category',
            // eslint-disable-next-line react/display-name
            renderCell: ({ value }) => {
              if (isNil(value)) return <div />;

              return (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {(value as ResourceCategory[]).map(c => (
                    <Chip
                      style={{ marginRight: 4, marginBottom: 4 }}
                      key={c?.id}
                      variant="outlined"
                      size="small"
                      label={c?.name}
                    />
                  ))}
                </div>
              );
            },
          },
          {
            width: 130,
            field: 'stage',
            headerName: 'Stage',
            // eslint-disable-next-line react/display-name
            renderCell: ({ value, row }) => {
              const getColor = () => {
                switch (value as ResourceStage) {
                  case ResourceStage.UNPUBLISHED:
                    return '#1F51E5';
                  case ResourceStage.DRAFT:
                    return '#FA9200';
                  case ResourceStage.PUBLISHED:
                    return '#008A45';
                  case ResourceStage.READY_FOR_REVIEW:
                    return '#8724A8';
                  case ResourceStage.SCHEDULED:
                    return '#FC7473';
                  default:
                    return 'gray';
                }
              };
              return (
                <Tag
                  style={{ padding: '4px 8px' }}
                  title={humanizeResourceStage(value as ResourceStage, row as Resource)}
                  textColor="#fff"
                  backgroundColor={getColor()}
                />
              );
            },
          },
          ...(isSuperAdmin(user) || isContentPartner(user)
            ? [
              {
                field: 'actions',
                headerName: 'Actions',
                // eslint-disable-next-line react/display-name
                renderCell: ({ row }) => {
                  return <MenuButton onClick={o => handleRowActionClick(o, row)} options={getRowMenuOptions(row)} />;
                },
              },
            ]
            : []),
        ]}
        rows={data?.resourceFeed?.data || []}
        rowCount={data?.resourceFeed?.total || 0}
        onItemPress={handleRowClick}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSearchQueryChange={handleSearchQueryChange}
        error={!!error}
        loading={loading || networkStatus === NetworkStatus.setVariables}
        height={headerHeight}
        sectionHeaderRef={sectionHeader}
        dataGridProps={{
          rowHeight: 70,
          onSortModelChange: onSortChange
        }}
      >
        {(isSuperAdmin(user) || isContentPartner(user)) && (
          <div>
            <Button
              onClick={() => history.push(routes.resources.create)}
              variant="contained"
              color="primary"
              disableElevation
            >
              Create Resource
            </Button>

            <Button
              style={{ marginLeft: 15 }}
              onClick={handleCSVExportClick}
              variant="contained"
              color="primary"
              disableElevation
            >
              Export to CSV
            </Button>
          </div>
        )}
      </DataTable>

      <Modal modalRef={modalRef} />
      <ScheduleDatePicker pickerRef={pickerRef} onPublishClick={handleSchedulePublishClick} />
    </div>
  );
};

export default ResourceListScreen;
